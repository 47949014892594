import { useRef, useState, useEffect } from 'react';
import { useNavigate, Link as RouterLink } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import * as Yup from 'yup';
import { Formik } from 'formik';
import {
  Box,
  Button,
  Container,
  TextField,
  Typography,
  Link,
  Paper
} from '@material-ui/core';
import Cookies from 'universal-cookie';
import axios from 'axios';
import useAuth from '../hooks/useAuth';

const LOGIN_URL = `${process.env.REACT_APP_BASE_URL_LOGIN}/signin/initiate`;

const Login = () => {
  const navigate = useNavigate();
  const { auth, setAuth } = useAuth();
  const from = '/dashboard';

  const emailRef = useRef();
  const errRef = useRef();

  const [email, setEmail] = useState('');
  const [pwd, setPwd] = useState('');
  const [errMsg, setErrMsg] = useState('');

  useEffect(() => {
    emailRef.current.focus();
    if (auth?.accessToken) {
      navigate(from, { replace: true });
    }
  }, []);

  useEffect(() => {
    setErrMsg('');
  }, [email, pwd]);

  return (
    <>
      <Helmet>
        <title>מערכת חשבוניות - התחברות</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          justifyContent: 'center'
        }}
      >
        <Container
          sx={{
            // margin: '10%',
          }}
          maxWidth="sm"
          direction="row"
          justify="center"
          spacing={2}
        >
          <Paper variant="elevation" elevation={6} className="login-background">
            <Formik
              sx={{
                margin: '10%'
              }}
              initialValues={{
                email: '',
                password: ''
              }}
              validationSchema={Yup.object().shape({
                email: Yup.string()
                  .email('Must be a valid email')
                  .max(255)
                  .required('Email is required'),
                password: Yup.string().max(255).required('Password is required')
              })}
              onSubmit={async (values) => {
                setPwd(values.password);
                setEmail(values.email);
                try {
                  const response = await axios.post(
                    LOGIN_URL,
                    JSON.stringify({ email, pwd }),
                    {
                      headers: {
                        'Content-Type': 'application/json',
                        'Access-Control-Allow-Origin': '*'
                      }
                    }
                  );
                  if (response?.data?.status_code === 0) {
                    setErrMsg(response?.data?.result);
                    errRef.current.focus();
                  } else {
                    const accessToken = response?.data?.authorization_token;
                    const role = response?.data?.role;
                    const cookies = new Cookies();
                    cookies.set('User', {}, { path: '/' });
                    cookies.set(
                      'Auth',
                      {
                        email,
                        role,
                        accessToken
                      },
                      { path: '/' }
                    );
                    setAuth({
                      email,
                      role,
                      accessToken
                    });
                    const responseInit = await axios.get(
                      `${process.env.REACT_APP_BASE_URL}/init`,
                      {
                        headers: {
                          'Content-Type': 'application/json',
                          'Access-Control-Allow-Origin': '*',
                          Authorization: `Bearer ${accessToken}`
                        }
                      }
                    );
                    if (responseInit?.data?.result && responseInit.data?.status_code === 1) {
                      cookies.set('Init', responseInit?.data?.result, {
                        path: '/'
                      });
                    }
                    setEmail('');
                    setPwd('');
                    navigate(from, { replace: true });
                  }
                } catch (err) {
                  if (!err?.response) {
                    setErrMsg('No Server Response');
                  } else if (err.response?.status === 400) {
                    setErrMsg('Missing Username or Password');
                  } else if (err.response?.status === 401) {
                    setErrMsg('Unauthorized');
                  } else {
                    setErrMsg('Login Failed');
                  }
                  errRef.current.focus();
                }
              }}
            >
              {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                isSubmitting,
                touched,
                values
              }) => (
                <form onSubmit={handleSubmit} style={{ padding: '5%' }}>
                  <Box sx={{ mb: 3 }}>
                    <Typography
                      color="textPrimary"
                      variant="h2"
                      sx={{ textAlign: 'center', margin: '5%' }}
                    >
                      התחברות
                    </Typography>
                    <Typography
                      color="textSecondary"
                      gutterBottom
                      variant="body2"
                      ref={errRef}
                      style={{ color: 'red' }}
                      className={errMsg ? 'errmsg' : 'offscreen'}
                      aria-live="assertive"
                    >
                      {errMsg}
                    </Typography>
                  </Box>
                  <TextField
                    error={Boolean(touched.email && errors.email)}
                    fullWidth
                    helperText={touched.email && errors.email}
                    label="כתובת אימייל"
                    margin="normal"
                    name="email"
                    ref={emailRef}
                    onBlur={handleBlur}
                    onChange={(e) => {
                      // call the built-in handleBur
                      handleChange(e);
                      // and do something about e
                      setEmail(e.currentTarget.value);
                    }}
                    type="email"
                    value={values.email}
                    variant="outlined"
                  />
                  <TextField
                    error={Boolean(touched.password && errors.password)}
                    fullWidth
                    helperText={touched.password && errors.password}
                    label="סיסמא"
                    margin="normal"
                    name="password"
                    onChange={(e) => {
                      // call the built-in handleBur
                      handleChange(e);
                      // and do something about e
                      setPwd(e.currentTarget.value);
                    }}
                    onBlur={handleBlur}
                    type="password"
                    value={values.password}
                    variant="outlined"
                  />
                  <Box sx={{ py: 2 }}>
                    <Button
                      color="primary"
                      disabled={isSubmitting}
                      fullWidth
                      size="large"
                      type="submit"
                      variant="contained"
                    >
                      התחבר
                    </Button>
                  </Box>
                  <Typography color="textSecondary" variant="body1">
                    שכחת סיסמא?
                    <Link
                      component={RouterLink}
                      to="/forgotPass"
                      variant="h6"
                      underline="hover"
                    >
                      לחץ כאן
                    </Link>
                  </Typography>
                </form>
              )}
            </Formik>
          </Paper>
        </Container>
      </Box>
    </>
  );
};

export default Login;
