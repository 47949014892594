// import moment from 'moment';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Button,
  Card,
  CardHeader,
  // Grid,
  // Chip,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
  // TableSortLabel,
  // Tooltip
} from '@material-ui/core';
import AspectRatio from '@material-ui/icons/AspectRatio';

const LatestOrders = ({
  title,
  columns,
  rows,
  linkTo,
  getDataForStation,
  ...props
}) => {
  const navigate = useNavigate();

  return (
    <Card {...props}>
      <CardHeader
        title={(
          <div>
            {linkTo !== '' && (
              <Button
                color="primary"
                endIcon={<AspectRatio />}
                size="small"
                variant="text"
                onClick={() => {
                  navigate(linkTo);
                }}
                style={{ float: 'left' }}
              />
            )}
            <div style={{ textAlign: 'center' }}>
              {title}
            </div>
          </div>
        )}
        sx={{ textAlign: 'center' }}
      />
      <Divider />
      <PerfectScrollbar>
        <Box
          sx={{
            minWidth: 200,
            maxHeight: 350,
            minHeight: 350,
            direction: 'rtl'
          }}
        >
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow align="right">
                {columns.map((column) => (
                  <TableCell align="right" key={column}>
                    {column}
                  </TableCell>
                ))}
                {/* <TableCell sortDirection="desc">
                <Tooltip enterDelay={300} title="Sort">
                  <TableSortLabel active direction="desc">
                    Date
                  </TableSortLabel>
                </Tooltip>
              </TableCell> */}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows?.map((row) => (
                <TableRow
                  align="right"
                  hover
                  style={title === 'דוח סכומי חשבוניות לפי תחנות  - מיון מהסכום הגבוה לנמוך' || title === 'דוח שינויים לעומת תקופה זהה בשנה קודמת' ? { cursor: 'pointer' } : null}
                  onClick={title
                    === 'דוח סכומי חשבוניות לפי תחנות  - מיון מהסכום הגבוה לנמוך'
                    || title === 'דוח שינויים לעומת תקופה זהה בשנה קודמת'
                    ? () => getDataForStation(row[0], row[1])
                    : null}
                >
                  {row?.map((value, index) => {
                    if (
                      (index === 5 || index === 6)
                      && title === 'דוח שינויים לעומת תקופה זהה בשנה קודמת'
                    ) {
                      let colorName;
                      if (index === 5) {
                        colorName = Number(value?.split('%')[0]) > 0
                          ? 'green'
                          : Number(value?.split('%')[0]) < 0
                            ? 'red'
                            : 'black';
                      } else {
                        colorName = Number(value) > 0
                          ? 'green'
                          : Number(value) < 0
                            ? 'red'
                            : 'black';
                      }
                      return (
                        <TableCell sx={{ color: colorName }} align="right">
                          {typeof value === 'number'
                            ? value.toLocaleString()
                            : value}
                        </TableCell>
                      );
                    }
                    return (
                      <>
                        <TableCell align="right">
                          {typeof value === 'number'
                            ? value.toLocaleString()
                            : value}
                        </TableCell>
                        {/* <TableCell>{value}</TableCell> */}
                      </>
                    );
                  })}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>
      {/* {linkTo !== '' && (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'flex-end',
        p: 2
      }}
    >
      <Button
        color="primary"
        endIcon={<AspectRatio />}
        size="small"
        variant="text"
      >
        <Link
          component={RouterLink}
          to={linkTo}
          variant="h6"
          underline="hover"
        >
        </Link>
      </Button>
    </Box>
    )} */}
    </Card>
  );
};

export default LatestOrders;
