import { Outlet } from 'react-router-dom';
import { useState, useEffect } from 'react';
import Cookies from 'universal-cookie';
import CircularLoading from 'src/components/helpers/Loading';
import useAuth from '../hooks/useAuth';

const PersistLogin = () => {
  const [isLoading, setIsLoading] = useState(true);
  const { auth, setAuth } = useAuth();

  useEffect(() => {
    let isMounted = true;

    const getJwtTokenCookices = async () => {
      try {
        const cookies = new Cookies();
        setAuth(cookies.get('Auth'));
      } catch (err) {
        console.error(err);
      } finally {
        isMounted && setIsLoading(false);
      }
    };

    // persist added here AFTER tutorial video
    // Avoids unwanted call to verifyRefreshToken
    !auth?.accessToken ? getJwtTokenCookices() : setIsLoading(false);

    return () => {
      isMounted = false;
    };
  }, []);

  useEffect(() => {
  }, [isLoading]);

  return <>{isLoading ? <CircularLoading /> : <Outlet />}</>;
};

export default PersistLogin;
