import { Helmet } from 'react-helmet';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Container } from '@material-ui/core';
import { GridRowsProp, GridColDef } from '@mui/x-data-grid';
// import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import moment from 'moment';
import Chip from '@mui/material/Chip';
import axios from 'axios';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import Cookies from 'universal-cookie';
import CircularLoading from 'src/components/helpers/Loading';
import BasicTable from '../components/helpers/BasicTable';
import useAuth from '../hooks/useAuth';
import AddOperator from '../components/operators/AddOperator';
// import ViewOperator from '../components/operators/ViewOperator';
import EditOperator from '../components/operators/EditOperator';
import AlertDialog from '../components/helpers/AlertPopup';

const Operator = () => {
  const navigate = useNavigate();
  const { auth, setAuth } = useAuth();
  const [snackbarMss, setSnackbarMss] = useState('');
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [query, setQuery] = useState([]);

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };
  const handelReturnLogin = () => {
    const cookies = new Cookies();
    cookies.set('Auth', {}, { path: '/' });
    setAuth(cookies.get('Auth'));
    navigate('/login', { replace: true });
  };

  const columns: GridColDef[] = [
    // {
    //   field: 'view',
    //   headerName: '',
    //   flex: 0.2,
    //   sortable: false,
    //   renderCell: (params) => {
    //     const [open, setOpen] = useState(false);
    //     const handleClickOpen = () => {
    //       setOpen(true);
    //     };
    //     const handleClose = () => {
    //       setOpen(false);
    //     };
    //     return (
    //       <>
    //         <OpenInNewIcon onClick={handleClickOpen} />
    //         <ViewOperator
    //           rowData={params.row}
    //           handleClose={handleClose}
    //           open={open}
    //         />
    //       </>
    //     );
    //   }
    // },
    {
      field: 'id',
      headerName: 'מספר מזהה',
      sortable: false,
      hide: true
    },
    { field: 'operator_name', flex: 1, headerName: 'שם חברה' },
    { field: 'operator_id', flex: 1, headerName: 'מספר חברה' },
    {
      field: 'create_time',
      flex: 1,
      headerName: 'תאריך',
      valueFormatter: ({ value }) => moment(value).format('DD-MM-YYYY')
    },
    {
      field: 'active',
      headerName: 'סטטוס',
      flex: 1,
      renderCell: (params) => {
        if (params?.row?.active === '1') {
          return <Chip label="פעיל" color="success" />;
        }
        return <Chip label="לא פעיל" color="error" />;
      }
    },
    {
      field: 'edit',
      headerName: '',
      flex: 0.2,
      sortable: false,
      renderCell: (params) => {
        const [openEdit, setOpenEdit] = useState(false);

        const handleClickOpenEdit = () => {
          setOpenEdit(true);
        };
        const handleCloseEdit = () => {
          setOpenEdit(false);
        };
        return (
          <>
            <EditIcon onClick={handleClickOpenEdit} />
            <EditOperator
              rowData={params.row}
              handleClose={handleCloseEdit}
              open={openEdit}
              handelReturnLogin={handelReturnLogin}
              accessToken={auth.accessToken}
              setOpenSnackbar={setOpenSnackbar}
              setSnackbarMss={setSnackbarMss}
              setQuery={setQuery}
            />
          </>
        );
      }
    },
    {
      field: 'delete',
      headerName: '',
      flex: 0.2,
      sortable: false,
      renderCell: (params) => {
        const [open, setOpen] = useState(false);
        const [errorMsg, setErrMsg] = useState(false);
        const [isLoadingDelete, setLoadingDelete] = useState(false);

        const handleClickOpen = () => {
          setOpen(true);
        };
        const handleClose = () => {
          setOpen(false);
        };

        const deleteOperator = async () => {
          const getUsersUrl = `${process.env.REACT_APP_BASE_URL}/operator?operator_id=${params.row.operator_id}`;
          setLoadingDelete(true);
          await axios
            .delete(getUsersUrl, {
              headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
                Authorization: `Bearer ${auth.accessToken}`
              }
            })
            .then((response) => {
              if (response?.status === 200 && response.data?.status_code === 1) {
                setSnackbarMss('החברה נמחקה בהצלחה');
                setQuery([]);
                setOpenSnackbar(true);
                handleClose();
                return true;
              }
              if (response?.data?.result && response.data?.status_code === 0) {
                setErrMsg(response?.data?.result);
                return false;
              }
              setErrMsg(response?.data?.result);
              return false;
            })
            .catch((err) => {
              if (err?.response?.status === 403) {
                handelReturnLogin();
              }
              setErrMsg(err?.message);
              return false;
            })
            .finally(() => {
              setLoadingDelete(false);
              return false;
            });
        };

        return (
          <>
            <DeleteIcon onClick={handleClickOpen} />
            <AlertDialog
              open={open}
              handleClose={handleClose}
              dialogTitle={`האם אתה בטוח שברצונך למחוק את החברה ${params.row.operator_name}?`}
              handleSubmit={deleteOperator}
              errorMsg={errorMsg}
              isLoading={isLoadingDelete}
            />
          </>
        );
      }
    }
  ];
  const [isLoading, setLoading] = useState(true);
  const [rows, setRows] = useState([]);

  const getOperator = async (accessToken) => {
    const getOperatorUrl = `${process.env.REACT_APP_BASE_URL}/operator?fetch_all=true`;

    await axios
      .get(getOperatorUrl, {
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
          Authorization: `Bearer ${accessToken}`
        }
      })
      .then((response) => {
        if (response?.data?.result && response.data?.status_code === 1) {
          const tmpRows: GridRowsProp = response?.data?.result;
          setRows(tmpRows);
          return tmpRows;
        }
        const tmpRows: GridRowsProp = [];
        setRows(tmpRows);
        return tmpRows;
      })
      .catch((err) => {
        if (err?.response?.status === 403) {
          handelReturnLogin();
        }
        return false;
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getOperator(auth.accessToken);
  }, [query]);

  if (isLoading) {
    return (
      <div>
        <CircularLoading />
      </div>
    );
  }

  return (
    <>
      <Helmet>
        <title>חברות</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 3
        }}
      >
        <Container maxWidth={false}>
          <AddOperator
            buttonText="הוספת חברה"
            accessToken={auth.accessToken}
            handelReturnLogin={handelReturnLogin}
            setQuery={setQuery}
          />
          <Snackbar
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            open={openSnackbar}
            onClose={handleCloseSnackbar}
            message={snackbarMss}
            key={{ vertical: 'top', horizontal: 'center' }}
          >
            <Stack sx={{ width: '100%' }} spacing={2}>
              <Alert variant="filled" severity="success">
                {snackbarMss}
              </Alert>
            </Stack>
          </Snackbar>
          <Box sx={{ pt: 3 }}>
            <BasicTable rows={rows} columns={columns} title="חברות" />
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default Operator;
