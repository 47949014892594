import TextField from '@mui/material/TextField';
import DialogContent from '@mui/material/DialogContent';
import { useState } from 'react';
import { Box, Button } from '@material-ui/core';
import Autocomplete from '@mui/material/Autocomplete';
import { makeStyles } from '@material-ui/styles';
import { Plus as PlusIcon } from 'react-feather';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import axios from 'axios';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import CircularLoading from 'src/components/helpers/Loading';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles(() => ({
  shrink: {
    transformOrigin: 'top right',
    right: 28
  }
}));

const AddUser = ({
  buttonText,
  accessToken,
  handelReturnLogin,
  setQuery,
  ...props
}) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [isLoading, setLoading] = useState(false);

  const [snackbarMss, setSnackbarMss] = useState('');
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [errMsg, setErrMsg] = useState('');

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [phone, setPhone] = useState('');
  const [password, setPassword] = useState('');
  const [role, setRole] = useState('');
  const [email, setEmail] = useState('');

  const [firstNameError, setFirstNameError] = useState('');
  const [lastNameError, setLastNameError] = useState('');
  const [phoneError, setPhoneError] = useState('');
  const [roleError, setRoleError] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [emailError, setEmailError] = useState('');

  const handleClickOpen = () => {
    setOpen(true);
    setOpenSnackbar(false);
  };

  const handleClose = () => {
    setOpen(false);
    setTimeout(() => {
      // After 5 seconds set the show value to false
      setSnackbarMss('');
      setOpenSnackbar(false);
    }, 5000);
  };

  const handleSubmit = async () => {
    if (!email) {
      setEmailError('שדה חובה');
    }
    if (!firstName) {
      setFirstNameError('שדה חובה');
    }
    if (!lastName) {
      setLastNameError('שדה חובה');
    }
    if (!phone) {
      setPhoneError('שדה חובה');
    }
    if (!role) {
      setRoleError('שדה חובה');
    }
    if (!password) {
      setRoleError('שדה חובה');
    }
    if (!firstName || !lastName || !phone || !role || !password || !email) {
      return;
    }

    setLoading(true);
    const getUserUrl = `${process.env.REACT_APP_BASE_URL}/user`;

    await axios
      .post(
        getUserUrl,
        JSON.stringify({
          email,
          first_name: firstName,
          last_name: lastName,
          phone,
          role,
          password
        }),
        {
          headers: {
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
            Authorization: `Bearer ${accessToken}`
          }
        }
      )
      .then((response) => {
        if (response?.status === 201) {
          setSnackbarMss('המשתמש נוסף בהצלחה');
          setOpenSnackbar(true);
          setQuery([]);
          handleClose();
        }

        if (response?.data?.result && response.data?.status_code === 0) {
          setErrMsg(response?.data?.result);
        }
      })
      .catch((err) => {
        setErrMsg(err?.message);
        if (err?.response?.status === 403) {
          handelReturnLogin();
        }
        return false;
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnackbar(false);
  };

  return (
    <Box {...props}>
      <Box
        component="form"
        sx={{
          display: 'flex',
          justifyContent: 'flex-start'
        }}
      >
        <Button variant="contained" color="primary" onClick={handleClickOpen}>
          <PlusIcon />
          {buttonText}
        </Button>
        <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          open={openSnackbar}
          onClose={handleCloseSnackbar}
          message={snackbarMss}
          key={{ vertical: 'top', horizontal: 'center' }}
        >
          <Stack sx={{ width: '100%' }} spacing={2}>
            <Alert variant="filled" severity="success">
              {snackbarMss}
            </Alert>
          </Stack>
        </Snackbar>
        <Dialog open={open} onClose={handleClose}>
          {isLoading ? (
            <Box
              sx={{
                display: 'grid',
                paddingBottom: 6,
                paddingTop: 2,
                gridTemplateColumns: { sm: '2fr 1fr' },
                gap: 3
              }}
            >
              <CircularLoading />
            </Box>
          ) : (
            <>
              <DialogTitle
                sx={{
                  p: 2,
                  textAlign: 'center',
                  fontSize: 30
                }}
              >
                <Box display="flex" alignItems="center">
                  <Box>
                    <IconButton onClick={handleClose}>
                      <CloseIcon />
                    </IconButton>
                  </Box>
                  <Box flexGrow={1}> הוספת משתמש</Box>
                </Box>
              </DialogTitle>
              <DialogContent>
                <Box
                  sx={{
                    display: 'grid',
                    paddingBottom: 2,
                    paddingTop: 2,
                    gridTemplateColumns: { sm: '1fr 1fr' },
                    gap: 3
                  }}
                >
                  <TextField
                    id="firstName"
                    required
                    label="שם פרטי"
                    variant="outlined"
                    value={firstName}
                    onChange={(event) => {
                      setFirstNameError('');
                      setFirstName(event.target.value);
                    }}
                    helperText={firstNameError}
                    error={!!firstNameError}
                    InputLabelProps={{
                      shrink: true,
                      classes: {
                        root: classes.labelRoot,
                        shrink: classes.shrink
                      }
                    }}
                  />
                  <TextField
                    id="lastName"
                    required
                    label="שם משפחה"
                    variant="outlined"
                    value={lastName}
                    onChange={(event) => {
                      setLastNameError('');
                      setLastName(event.target.value);
                    }}
                    helperText={lastNameError}
                    error={!!lastNameError}
                    InputLabelProps={{
                      shrink: true,
                      classes: {
                        root: classes.labelRoot,
                        shrink: classes.shrink
                      }
                    }}
                  />
                </Box>
                <Box
                  sx={{
                    display: 'grid',
                    paddingBottom: 2,
                    paddingTop: 2,
                    gridTemplateColumns: { sm: '1fr 1fr' },
                    gap: 3
                  }}
                >
                  <TextField
                    id="email"
                    required
                    label="אימייל"
                    variant="outlined"
                    value={email}
                    onChange={(event) => {
                      setEmailError('');
                      setEmail(event.target.value);
                    }}
                    onBlur={(event) => {
                      if (
                        /^[a-zA-Z0-9.]+@(?:[a-zA-Z0-9]+\.)+[A-Za-z]+$/.test(
                          event.target.value
                        )
                      ) {
                        setEmail(event.target.value);
                      } else {
                        setEmailError('פורמט תקין: aaa@aa.com');
                      }
                    }}
                    helperText={emailError}
                    error={!!emailError}
                    InputLabelProps={{
                      shrink: true,
                      classes: {
                        root: classes.labelRoot,
                        shrink: classes.shrink
                      }
                    }}
                  />
                  <TextField
                    id="password"
                    required
                    label="סיסמא"
                    type="password"
                    variant="outlined"
                    value={password}
                    onChange={(event) => {
                      setPasswordError('');
                      setPassword(event.target.value);
                    }}
                    helperText={passwordError}
                    error={!!passwordError}
                    InputLabelProps={{
                      shrink: true,
                      classes: {
                        root: classes.labelRoot,
                        shrink: classes.shrink
                      }
                    }}
                  />
                </Box>
                <Box
                  sx={{
                    display: 'grid',
                    paddingBottom: 2,
                    paddingTop: 2,
                    gridTemplateColumns: { sm: '1fr 1fr' },
                    gap: 3
                  }}
                >
                  <TextField
                    id="phone"
                    required
                    label="נייד"
                    variant="outlined"
                    value={phone}
                    onChange={(event) => {
                      setPhoneError('');
                      setPhone(event.target.value);
                    }}
                    helperText={phoneError}
                    error={!!phoneError}
                    InputLabelProps={{
                      shrink: true,
                      classes: {
                        root: classes.labelRoot,
                        shrink: classes.shrink
                      }
                    }}
                  />
                  <Autocomplete
                    disablePortal
                    id="role"
                    options={['מנהל', 'עובד']}
                    onSelect={(event) => {
                      setRoleError('');
                      setRole(event.target.value === 'מנהל' ? 'a' : 'u');
                    }}
                    renderInput={(params) => (
                      <TextField
                        helperText={roleError}
                        error={!!roleError}
                        {...params}
                        label="תפקיד"
                      />
                    )}
                  />
                </Box>
              </DialogContent>
              <p className="errorMsg">{errMsg}</p>
              <DialogActions
                sx={{
                  justifyContent: 'center'
                }}
              >
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleSubmit}
                >
                  הוסף
                </Button>
              </DialogActions>
            </>
          )}
        </Dialog>
      </Box>
    </Box>
  );
};

export default AddUser;
