import TextField from '@mui/material/TextField';
import DialogContent from '@mui/material/DialogContent';
import { useEffect, useState } from 'react';
import { Box, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import axios from 'axios';
import CircularLoading from 'src/components/helpers/Loading';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles(() => ({
  shrink: {
    transformOrigin: 'top right',
    right: 28
  }
}));

const EditProvider = ({
  rowData,
  accessToken,
  handelReturnLogin,
  open,
  handleClose,
  setSnackbarMss,
  setOpenSnackbar,
  setQuery,
  ...props
}) => {
  const classes = useStyles();

  const [isLoading, setLoading] = useState(false);
  const [errMsg, setErrMsg] = useState('');

  const [providerId, setProviderId] = useState(rowData?.provider_id);
  const [providerName, setProviderName] = useState(rowData?.provider_name);
  const [hp, setHp] = useState(rowData?.hp);
  const [phone, setPhone] = useState(rowData?.phone);
  const [email, setEmail] = useState(rowData?.email);
  const [remarks, setRemarks] = useState(rowData?.remarks);

  const [providerIdError, setProviderIdError] = useState('');
  const [providerNameError, setProviderNameError] = useState('');
  const [hpError, setHpError] = useState('');
  const [phoneError, setPhoneError] = useState('');
  const [emailError, setEmailError] = useState('');
  const [remarksError, setRemarksError] = useState('');

  const handleSubmit = async () => {
    if (!providerId) {
      setProviderIdError('שדה חובה');
    }
    if (!providerName) {
      setProviderNameError('שדה חובה');
    }
    if (!hp) {
      setHpError('שדה חובה');
    }
    if (!providerName
      || !hp
      || !providerId
    ) {
      return;
    }
    setLoading(true);

    const getProviderUrl = `${process.env.REACT_APP_BASE_URL}/provider`;

    let jsonlet = {
      provider_id: rowData.provider_id,
      provider_name: providerName,
      hp: rowData.hp,
      remarks,
    };
    if (rowData.hp !== hp) {
      jsonlet = {
        ...jsonlet,
        hp_n: hp
      };
    }
    if (rowData.provider_id !== providerId) {
      jsonlet = {
        ...jsonlet,
        provider_id_n: providerId
      };
    }
    if (rowData.phone !== '') {
      jsonlet = {
        ...jsonlet,
        phone
      };
    }
    if (rowData.email !== '') {
      jsonlet = {
        ...jsonlet,
        email
      };
    }

    await axios
      .put(
        getProviderUrl,
        JSON.stringify(jsonlet),
        {
          headers: {
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
            Authorization: `Bearer ${accessToken}`
          }
        }
      )
      .then((response) => {
        if (response?.status === 200 && response.data?.status_code === 1) {
          setSnackbarMss('התאגיד נערך בהצלחה');
          setErrMsg('');
          setOpenSnackbar(true);
          setQuery([]);
          handleClose();
        }

        if (response?.data?.result && response.data?.status_code === 0) {
          setErrMsg(response?.data?.result);
        }
      })
      .catch((err) => {
        setErrMsg(err?.message);
        if (err?.response?.status === 403) {
          handelReturnLogin();
        }
        return false;
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    setLoading(false);
    setErrMsg('');
    setProviderId(rowData?.provider_id);
    setProviderName(rowData?.provider_name);
    setHp(rowData?.hp);
    setPhone(rowData?.phone);
    setEmail(rowData?.email);
    setRemarks(rowData?.remarks);
  }, [rowData]);

  return (
    <Box {...props}>
      <Box
        component="form"
        sx={{
          display: 'flex',
          justifyContent: 'flex-start'
        }}
      >
        <Dialog open={open} onClose={handleClose}>
          {isLoading || !rowData ? (
            <Box
              sx={{
                display: 'grid',
                paddingBottom: 6,
                paddingTop: 2,
                gridTemplateColumns: { sm: '2fr 1fr' },
                gap: 3
                // '& > :not(style)': { m: 1, width: '25ch' }
              }}
            >
              <CircularLoading />
            </Box>
          ) : (
            <>
              <DialogTitle
                sx={{
                  p: 2,
                  textAlign: 'center',
                  fontSize: 30
                }}
              >
                <Box display="flex" alignItems="center">
                  <Box>
                    <IconButton onClick={handleClose}>
                      <CloseIcon />
                    </IconButton>
                  </Box>
                  <Box flexGrow={1}> עריכת תאגיד</Box>
                </Box>
              </DialogTitle>
              <DialogContent>
                <Box
                  sx={{
                    display: 'grid',
                    paddingBottom: 6,
                    paddingTop: 2,
                    gridTemplateColumns: { sm: '2fr 1fr' },
                    gap: 3
                  }}
                >
                  <TextField
                    id="providerName"
                    required
                    label="שם תאגיד"
                    variant="outlined"
                    value={providerName}
                    onChange={(event) => {
                      setProviderNameError('');
                      setProviderName(event.target.value);
                    }}
                    helperText={providerNameError}
                    error={!!providerNameError}
                    InputLabelProps={{
                      shrink: true,
                      classes: {
                        root: classes.labelRoot,
                        shrink: classes.shrink
                      }
                    }}
                  />
                  <TextField
                    id="providerId"
                    required
                    label="מספר תאגיד"
                    type="number"
                    variant="outlined"
                    value={providerId}
                    onChange={(event) => {
                      setProviderIdError('');
                      setProviderId(event.target.value);
                    }}
                    helperText={providerIdError}
                    error={!!providerIdError}
                    InputLabelProps={{
                      shrink: true,
                      classes: {
                        root: classes.labelRoot,
                        shrink: classes.shrink
                      }
                    }}
                  />
                </Box>
                <Box
                  sx={{
                    display: 'grid',
                    gridTemplateColumns: { sm: '1fr 1fr 1fr' },
                    gap: 3,
                    paddingBottom: 6
                  }}
                >
                  <TextField
                    id="hp"
                    label="ח.פ"
                    variant="outlined"
                    value={hp}
                    onChange={(event) => {
                      setHpError('');
                      setHp(event.target.value);
                    }}
                    helperText={hpError}
                    error={!!hpError}
                    InputLabelProps={{
                      shrink: true,
                      classes: {
                        root: classes.labelRoot,
                        shrink: classes.shrink
                      }
                    }}
                  />
                  <TextField
                    id="phone"
                    label="נייד"
                    variant="outlined"
                    value={phone}
                    onChange={(event) => {
                      setPhoneError('');
                      setPhone(event.target.value);
                    }}
                    helperText={phoneError}
                    error={!!phoneError}
                    InputLabelProps={{
                      shrink: true,
                      classes: {
                        root: classes.labelRoot,
                        shrink: classes.shrink
                      }
                    }}
                  />
                  <TextField
                    id="email"
                    label="email"
                    type="email"
                    variant="outlined"
                    value={email}
                    onChange={(event) => {
                      setEmailError('');
                      setEmail(event.target.value);
                    }}
                    helperText={emailError}
                    error={!!emailError}
                    InputLabelProps={{
                      shrink: true,
                      classes: {
                        root: classes.labelRoot,
                        shrink: classes.shrink
                      }
                    }}
                  />
                </Box>
                <Box
                  sx={{
                    display: 'grid',
                    gridTemplateColumns: { sm: '1fr' },
                    gap: 3
                  }}
                >
                  <TextField
                    id="remarks"
                    label="הערות"
                    variant="outlined"
                    value={remarks}
                    onChange={(event) => {
                      setRemarksError('');
                      setRemarks(event.target.value);
                    }}
                    multiline
                    rows={2}
                    helperText={remarksError}
                    error={!!remarksError}
                    InputLabelProps={{
                      shrink: true,
                      classes: {
                        root: classes.labelRoot,
                        shrink: classes.shrink
                      }
                    }}
                  />
                </Box>
              </DialogContent>
              <p className="errorMsg">{errMsg}</p>
              <DialogActions
                sx={{
                  justifyContent: 'center'
                }}
              >
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleSubmit}
                >
                  עדכן
                </Button>
              </DialogActions>
            </>
          )}
        </Dialog>
      </Box>
    </Box>
  );
};

export default EditProvider;
