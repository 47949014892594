import { Helmet } from 'react-helmet';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Container } from '@material-ui/core';
import { GridRowsProp, GridColDef } from '@mui/x-data-grid';
import Button from '@material-ui/core/Button';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import EditIcon from '@mui/icons-material/Edit';
import axios from 'axios';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import Cookies from 'universal-cookie';
import CircularLoading from 'src/components/helpers/Loading';
import moment from 'moment';
import Chip from '@mui/material/Chip';
import Tooltip from '@mui/material/Tooltip';
import BasicTable from '../components/helpers/BasicTable';
import useAuth from '../hooks/useAuth';
import AddInvoice from '../components/invoices/AddInvoice';
import UploadPDF from '../components/invoices/UploadPDF';
import ViewInvoive from '../components/invoices/ViewInvoive';
import EditInvoice from '../components/invoices/EditInvoice';
import FilterByDate from '../components/invoices/FilterByDate';
import ChangeStatusDialog from '../components/invoices/ChangeStatusDialog';

// import renderProgress from '../../components/helpers/ProgressBar';

const Invoices = () => {
  const navigate = useNavigate();
  const { auth, setAuth } = useAuth();
  const [query, setQuery] = useState([]);

  const [snackbarMss, setSnackbarMss] = useState('');
  const [openSnackbar, setOpenSnackbar] = useState(false);

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  const handelReturnLogin = () => {
    const cookies = new Cookies();
    cookies.set('Auth', {}, { path: '/' });
    setAuth(cookies.get('Auth'));
    navigate('/login', { replace: true });
  };

  const [isLoading, setLoading] = useState(true);
  const [isLoading2, setLoading2] = useState(true);
  const [rows, setRows] = useState([]);
  const [rowsFull, setRowsFull] = useState([]);
  const [corporations, setCorporations] = useState([]);

  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);

  const [rowData, setRowData] = useState(false);

  const [openEdit, setOpenEdit] = useState(false);
  const handleCloseEdit = () => {
    setOpenEdit(false);
  };

  const [openSatus, setOpenStatus] = useState(false);
  const handleCloseStatus = () => {
    setOpenStatus(false);
  };
  const [invoicingId, setInvoicingId] = useState(false);
  const [invoicingIdOriginal, setInvoicingIdOriginal] = useState(false);
  const [active, setActive] = useState(false);
  const [approveName, setApproveName] = useState(false);
  const [updateDate, setUpdateDate] = useState(false);

  const [openView, setOpenView] = useState(false);
  const handleCloseView = () => {
    setOpenView(false);
  };

  const columns: GridColDef[] = [
    {
      field: 'id',
      headerName: 'מספר מזהה',
      sortable: false,
      hide: true,
      filterable: false
    },
    {
      field: 'hp',
      headerName: 'ח.פ',
      renderCell: (params) => (
        <Tooltip title={params.row.hp}>
          <span className="table-cell-trucate">{params.row.hp}</span>
        </Tooltip>
      ),
      hide: true
    },
    {
      field: 'vat',
      headerName: 'מע״מ',
      filterable: false,
      hide: true
    },
    {
      field: 'amount_paid_with_vat',
      headerName: 'כולל מע״מ',
      filterable: false,
      hide: true,
      valueFormatter: ({ value }) => `₪${value}`
    },
    {
      field: 'operator_name',
      headerName: 'שם חברה',
      sortable: false,
      filterable: false,
      hide: true
    },
    {
      field: 'other_charges',
      headerName: 'חיובים נוספים',
      sortable: false,
      filterable: false,
      hide: true
    },
    {
      field: 'price_for_one_unity',
      headerName: 'מחיר ליחידה',
      filterable: false,
      hide: true
    },
    {
      field: 'refund',
      headerName: 'זיכויים',
      filterable: false,
      sortable: false,
      hide: true
    },
    {
      field: 'time_stamp',
      headerName: 'תאריך עדכון',
      filterable: false,
      hide: true,
      sortable: false,
      valueFormatter: ({ value }) => moment(value).format('DD-MM-YYYY HH:MM')
    },
    {
      field: 'edit',
      headerName: '',
      width: 50,
      filterable: false,
      sortable: false,
      disableExport: true,
      renderCell: (params) => {
        const handleClickOpenEdit = () => {
          setRowData(params.row);
          setOpenEdit(true);
        };

        return (
          <>
            <EditIcon onClick={handleClickOpenEdit} />
          </>
        );
      }
    },
    {
      field: 'active_status',
      headerName: 'סטטוס',
      filterable: true,
      hide: true
    },
    {
      field: 'active',
      headerName: 'סטטוס',
      valueFormatter: ({ value }) => {
        if (value === 1) {
          return 'ממתין לאישור';
        }
        if (value === 2) {
          return 'אושר';
        }
        if (value === 3) {
          return 'מבוטל';
        }
        return 'הועבר';
      },
      filterable: false,
      width: 115,
      renderCell: (params) => {
        const handleClickOpen = () => {
          if (auth.role !== 'u') {
            setInvoicingId(params.row.invoicing_id);
            setInvoicingIdOriginal(params.row.original_inv);
            setUpdateDate(
              `עודכן בתאריך: ${moment(params.row.time_stamp).format(
                'DD-MM-YYYY HH:MM'
              )}`
            );
            if (params?.row?.active === 2 || params?.row?.active === 4) {
              setApproveName(
                ` מאושר ע״י : ${params.row.first_name || ''} ${
                  params.row.last_name || ''
                }`
              );
            }
            setActive(params?.row?.active);
            setOpenStatus(true);
          }
        };

        let chipVar;
        if (params?.row?.active === 2) {
          chipVar = (
            <Chip onClick={handleClickOpen} label="מאושר" color="success" />
          );
        } else if (params?.row?.active === 1) {
          chipVar = (
            <Chip onClick={handleClickOpen} label="ממתין לאישור" color="info" />
          );
        } else if (params?.row?.active === 3) {
          chipVar = (
            <Chip onClick={handleClickOpen} label="מבוטל" color="error" />
          );
        } else {
          chipVar = (
            <Chip onClick={handleClickOpen} label="הועבר" color="warning" />
          );
        }
        return <>{chipVar}</>;
      }
    },
    {
      field: 'updateDateTime',
      headerName: 'זמן עדכון',
      sortable: false,
      filterable: false,
      valueFormatter: ({ value }) => moment(value).format('HH:MM'),
      renderCell: (params) => {
        let updateDatetmp = `${moment(params.row.time_stamp).format('HH:MM')}`;
        return (
          <Tooltip title={updateDatetmp}>
            <span className="table-cell-trucate">{updateDatetmp}</span>
          </Tooltip>
        );
      }
    },
    {
      field: 'updateDate',
      sortable: false,
      filterable: false,
      headerName: 'תאריך עדכון',
      valueFormatter: ({ value }) => moment(value).format('DD-MM-YYYY'),
      renderCell: (params) => {
        let updateDatetmp = `${moment(params.row.time_stamp).format(
          'YYYY-MM-DD'
        )}`;
        return (
          <Tooltip title={updateDatetmp}>
            <span className="table-cell-trucate">{updateDatetmp}</span>
          </Tooltip>
        );
      }
    },
    {
      field: 'approveName',
      headerName: 'שם מאשר',
      filterable: false,
      renderCell: (params) => (
        <Tooltip title={params.row.approveName}>
          <span className="table-cell-trucate">{params.row.approveName}</span>
        </Tooltip>
      )
    },
    {
      field: 'side_c',
      headerName: 'חיוב צד ג׳',
      filterable: false,
      sortable: false
    },
    {
      field: 'remarks',
      headerName: 'הערות',
      filterable: false,
      sortable: false
    },
    {
      field: 'average_charge',
      headerName: 'ממוצע חיוב',
      width: 115,
      filterable: false,
      valueFormatter: ({ value }) => `₪${value.toLocaleString()}`
    },
    {
      field: 'average_consumption',
      headerName: 'ממוצע צריכה',
      width: 115,
      filterable: false,
      valueFormatter: ({ value }) => `${value.toLocaleString()}`
    },
    {
      field: 'amount_paid_without_vat',
      headerName: 'חיוב כספי ללא מעמ',
      width: 160,
      filterable: false,
      valueFormatter: ({ value }) => `₪${value.toLocaleString()}`
    },
    {
      field: 'consumption_amount',
      headerName: 'צריכה',
      filterable: false
    },
    {
      field: 'station_name',
      headerName: 'תחנה',
      filterable: false,
      renderCell: (params) => (
        <Tooltip title={params.row.station_name}>
          <span className="table-cell-trucate">{params.row.station_name}</span>
        </Tooltip>
      )
    },
    {
      field: 'station_number',
      headerName: 'מ.תחנה',
      filterable: true,
      renderCell: (params) => (
        <Tooltip title={params.row.station_number}>
          <span className="table-cell-trucate">
            {params.row.station_number}
          </span>
        </Tooltip>
      )
    },
    {
      field: 'print_date',
      headerName: 'תאריך הדפסה',
      filterable: false,
      sortable: false,
      hide: true,
      valueFormatter: ({ value }) => moment(value).format('YYYY-MM')
    },
    {
      field: 'to_period',
      headerName: 'עד תאריך',
      type: 'date',
      filterable: false,
      valueFormatter: ({ value }) => moment(value).format('YYYY-MM')
    },
    {
      field: 'from_period',
      headerName: 'מתאריך',
      filterable: false,
      type: 'date',
      valueFormatter: ({ value }) => moment(value).format('YYYY-MM')
    },
    {
      field: 'water_meter',
      headerName: 'מד מים',
      renderCell: (params) => (
        <Tooltip title={params.row.water_meter}>
          <span className="table-cell-trucate">{params.row.water_meter}</span>
        </Tooltip>
      )
    },
    {
      field: 'invoicing_id',
      headerName: 'מ.חשבונית',
      renderCell: (params) => (
        <Tooltip title={params.row.invoicing_id}>
          <span className="table-cell-trucate">{params.row.invoicing_id}</span>
        </Tooltip>
      )
    },
    {
      field: 'corporation_name',
      headerName: 'שם תאגיד',
      filterable: false,
      renderCell: (params) => (
        <Tooltip title={params.row.corporation_name}>
          <span className="table-cell-trucate">
            {params.row.corporation_name}
          </span>
        </Tooltip>
      )
    },
    {
      field: 'view',
      headerName: '',
      width: 50,
      disableExport: true,
      filterable: false,
      sortable: false,
      renderCell: (params) => {
        const handleClickOpen = () => {
          setRowData(params.row);
          setOpenView(true);
        };

        return (
          <>
            <OpenInNewIcon onClick={handleClickOpen} />
          </>
        );
      }
    }
  ];

  const getInvoices = async (accessToken) => {
    const getInvoicesUrl = `${process.env.REACT_APP_BASE_URL}/invoicing?fetch_all=true`;

    await axios
      .get(getInvoicesUrl, {
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
          Authorization: `Bearer ${accessToken}`
        }
      })
      .then((response) => {
        if (response?.data?.result && response.data?.status_code === 1) {
          const tmpRows: GridRowsProp = response?.data?.result
            .filter((item) => item.active === 1)
            .map((datarow) => {
              let activestatus = datarow.active === 1
                ? 'ממתין לאישור'
                : datarow.active === 2
                  ? 'מאושר'
                  : datarow.active === 3
                    ? 'מבוטל'
                    : 'הועבר';
              let spe = ' ';
              let approveNameTmp = datarow?.first_name + spe + datarow?.last_name;
              return { ...datarow, active_status: activestatus, approveName: approveNameTmp };
            });
          const tmpRowsFull: GridRowsProp = response?.data?.result.map(
            (datarow) => {
              let activestatus = datarow.active === 1
                ? 'ממתין לאישור'
                : datarow.active === 2
                  ? 'מאושר'
                  : datarow.active === 3
                    ? 'מבוטל'
                    : 'הועבר';
              let spe = ' ';
              let approveNameTmp = datarow?.first_name + spe + datarow?.last_name;
              return { ...datarow, active_status: activestatus, approveName: approveNameTmp };
            }
          );
          setRows(tmpRows);
          setRowsFull(tmpRowsFull);

          if (fromDate !== null) {
            setRows(
              tmpRowsFull.filter(
                (item) => moment(item.print_date, 'YYYY-MM-DD').isSameOrAfter(fromDate.format('YYYY-MM-DD'))
                      && moment(item.print_date, 'YYYY-MM-DD').isSameOrBefore(toDate.format('YYYY-MM-DD'))
              )
            );
          }
          return tmpRows;
        }
        const tmpRows: GridRowsProp = [];
        setRows(tmpRows);
        return tmpRows;
      })
      .catch((err) => {
        if (err?.response?.status === 403) {
          handelReturnLogin();
        }
        return false;
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getCorporations = async (accessToken) => {
    const getInvoicesUrl = `${process.env.REACT_APP_BASE_URL}/provider?fetch_all=true`;

    await axios
      .get(getInvoicesUrl, {
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
          Authorization: `Bearer ${accessToken}`
        }
      })
      .then((response) => {
        if (response?.data?.result && response.data?.status_code === 1) {
          setCorporations(response?.data?.result);
          return response?.data?.result;
        }
        return [];
      })
      .catch((err) => {
        if (err?.response?.status === 403) {
          handelReturnLogin();
        }
        return false;
      })
      .finally(() => {
        setLoading2(false);
      });
  };

  const runCron = async () => {
    const getInvoicesUrl = `${process.env.REACT_APP_BASE_URL}/push-data`;

    await axios
      .get(getInvoicesUrl, {
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
          Authorization: `Bearer ${auth.accessToken}`
        }
      })
      .then((response) => {
        if (response?.data?.result && response.data?.status_code === 1) {
          setSnackbarMss(response?.data?.result);
          setOpenSnackbar(true);
          setQuery([]);
          setTimeout(() => {
            // After 5 seconds set the show value to false
            setSnackbarMss('');
            setOpenSnackbar(false);
          }, 5000);
          return response?.data?.result;
        }
        return [];
      })
      .catch((err) => {
        if (err?.response?.status === 403) {
          handelReturnLogin();
        }
        return false;
      })
      .finally(() => {
        setLoading2(false);
      });
  };

  useEffect(() => {
    getInvoices(auth.accessToken);
    getCorporations(auth.accessToken);
  }, [query]);

  if (isLoading || isLoading2) {
    return (
      <div>
        <CircularLoading />
      </div>
    );
  }

  return (
    <>
      <Helmet>
        <title>חשבוניות</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 3
        }}
      >
        <Container maxWidth={false}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center'
            }}
          >
            <Box
              sx={{
                display: 'flex',
                // justifyContent: 'space-between',
                alignItems: 'flex-start'
              }}
            >
              <AddInvoice
                buttonText="הוסף חשבונית חדשה"
                accessToken={auth.accessToken}
                handelReturnLogin={handelReturnLogin}
                setQuery={setQuery}
                corporations={corporations}
              />
              <Button
                variant="contained"
                style={{ marginRight: '3px' }}
                color="primary"
                onClick={runCron}
              >
                <PlayArrowIcon />
                העברה
              </Button>
              <UploadPDF accessToken={auth.accessToken} />
            </Box>
            <FilterByDate
              fromDate={fromDate}
              setFromDate={setFromDate}
              toDate={toDate}
              setToDate={setToDate}
              setRows={setRows}
              rowsFull={rowsFull}
            />
          </Box>
          <Snackbar
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            open={openSnackbar}
            onClose={handleCloseSnackbar}
            message={snackbarMss}
            key={{ vertical: 'top', horizontal: 'center' }}
          >
            <Stack sx={{ width: '100%' }} spacing={2}>
              <Alert variant="filled" severity="success">
                {snackbarMss}
              </Alert>
            </Stack>
          </Snackbar>
          <EditInvoice
            rowData={rowData}
            handleClose={handleCloseEdit}
            open={openEdit}
            handelReturnLogin={handelReturnLogin}
            accessToken={auth.accessToken}
            setOpenSnackbar={setOpenSnackbar}
            setSnackbarMss={setSnackbarMss}
            setQuery={setQuery}
            corporations={corporations}
          />
          <ChangeStatusDialog
            open={openSatus}
            handleClose={handleCloseStatus}
            dialogTitle={` חשבונית  ${invoicingId}`}
            curStatus={active}
            invoicingIdOriginal={invoicingIdOriginal}
            auth={auth}
            setQuery={setQuery}
            handelReturnLogin={handelReturnLogin}
            setOpenSnackbar={setOpenSnackbar}
            setSnackbarMss={setSnackbarMss}
            approveName={approveName}
            updateDate={updateDate}
          />
          <ViewInvoive
            rowData={rowData}
            handleClose={handleCloseView}
            open={openView}
            accessToken={auth.accessToken}
            handelReturnLogin={handelReturnLogin}
          />
          <Box sx={{ pt: 3 }} className="invoive">
            <BasicTable rows={rows} columns={columns} title="חשבוניות" />
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default Invoices;
