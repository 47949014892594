import DialogContent from '@mui/material/DialogContent';
import { Box } from '@material-ui/core';
import Dialog from '@mui/material/Dialog';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

const ViewProvider = ({ rowData, handleClose, open, ...props }) => {
  if (!rowData) {
    return <></>;
  }
  return (
    <Box {...props}>
      <Dialog open={open} onClose={handleClose} fullWidth>
        <DialogTitle
          sx={{
            p: 2,
            textAlign: 'center',
            fontSize: 30
          }}
        >
          <Box display="flex" alignItems="center">
            <Box>
              <IconButton onClick={handleClose}>
                <CloseIcon />
              </IconButton>
            </Box>
            <Box flexGrow={1}>{`תאגיד ${rowData.provider_name}`}</Box>
          </Box>
        </DialogTitle>
        <DialogContent>
          <Grid container margin="auto">
            <Grid item xs={4}>
              <DialogContentText>מספר תאגיד:</DialogContentText>
              <DialogContentText>שם תאגיד :</DialogContentText>
              <DialogContentText> מספר ח.פ :</DialogContentText>
              <DialogContentText>מספר נייד:</DialogContentText>
              <DialogContentText>email:</DialogContentText>
              <DialogContentText>הערות:</DialogContentText>

            </Grid>
            <Grid item xs={8}>
              <DialogContentText>
                {`${rowData.provider_id ? rowData.provider_id : '-'}`}
              </DialogContentText>
              <DialogContentText>
                {`${rowData.provider_name ? rowData.provider_name : '-'}`}
              </DialogContentText>
              <DialogContentText>
                {`${rowData.hp ? rowData.hp : '-'}`}
              </DialogContentText>
              <DialogContentText>
                {`${rowData.phone ? rowData.phone : '-'}`}
              </DialogContentText>
              <DialogContentText>
                {`${rowData.email ? rowData.email : '-'}`}
              </DialogContentText>
              <DialogContentText>
                {`${rowData.remarks ? rowData.remarks : '-'}`}
              </DialogContentText>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </Box>
  );
};

export default ViewProvider;
