import { Helmet } from 'react-helmet';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Container } from '@material-ui/core';
import { GridRowsProp, GridColDef } from '@mui/x-data-grid';
import Button from '@material-ui/core/Button';
import DownloadIcon from '@mui/icons-material/Download';
import axios from 'axios';

import Cookies from 'universal-cookie';
import CircularLoading from 'src/components/helpers/Loading';
import moment from 'moment';
import 'moment-timezone';
import Tooltip from '@mui/material/Tooltip';
import BasicTable from '../components/helpers/BasicTable';
import useAuth from '../hooks/useAuth';
import FilterByDateSftp from '../components/invoices/FilterByDateSftp';

const InvoicesSftp = () => {
  const navigate = useNavigate();
  const { auth, setAuth } = useAuth();

  const handelReturnLogin = () => {
    const cookies = new Cookies();
    cookies.set('Auth', {}, { path: '/' });
    setAuth(cookies.get('Auth'));
    navigate('/login', { replace: true });
  };

  const [isLoading, setLoading] = useState(true);

  const [rows, setRows] = useState([]);
  const [rowsFull, setRowsFull] = useState([]);

  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);

  const columns: GridColDef[] = [
    {
      field: 'id',
      headerName: 'מספר מזהה',
      sortable: false,
      hide: true,
      filterable: false
    },
    {
      field: 'transfer_date',
      headerName: 'תאריך העברה',
      filterable: false,
      sortable: false,
      flex: 0.2,
      valueFormatter: ({ value }) => moment(value).format('YYYY-MM-DD')
    },
    {
      field: 'print_date',
      headerName: 'תאריך הדפסה',
      filterable: false,
      sortable: false,
      flex: 0.2,
      valueFormatter: ({ value }) => moment(value).format('YYYY-MM')
    },
    {
      field: 'station_name',
      headerName: 'תחנה',
      filterable: false,
      flex: 0.2,
      renderCell: (params) => (
        <Tooltip title={params.row.station_name}>
          <span className="table-cell-trucate">{params.row.station_name}</span>
        </Tooltip>
      )
    },
    {
      field: 'station_number',
      headerName: 'מ.תחנה',
      filterable: true,
      flex: 0.2,
      renderCell: (params) => (
        <Tooltip title={params.row.station_number}>
          <span className="table-cell-trucate">
            {params.row.station_number}
          </span>
        </Tooltip>
      )
    },
    {
      field: 'water_meter',
      headerName: 'מד מים',
      flex: 0.2,
      renderCell: (params) => (
        <Tooltip title={params.row.water_meter}>
          <span className="table-cell-trucate">{params.row.water_meter}</span>
        </Tooltip>
      )
    },
    {
      field: 'invoicing_id',
      headerName: 'מ.חשבונית',
      flex: 0.2,
      renderCell: (params) => (
        <Tooltip title={params.row.invoicing_id}>
          <span className="table-cell-trucate">{params.row.invoicing_id}</span>
        </Tooltip>
      )
    }
  ];

  const getInvoices = async (accessToken) => {
    const getInvoicesUrl = `${process.env.REACT_APP_BASE_URL}/sftp`;

    await axios
      .get(getInvoicesUrl, {
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
          Authorization: `Bearer ${accessToken}`
        }
      })
      .then((response) => {
        if (response?.data?.result && response.data?.status_code === 1) {
          const tmpRows: GridRowsProp = response?.data?.result;
          const tmpRowsFull: GridRowsProp = response?.data?.result;
          setRows(tmpRows);
          setRowsFull(tmpRowsFull);

          if (fromDate !== null) {
            setRows(
              tmpRowsFull.filter(
                (item) => moment(item.transfer_date, 'YYYY-MM-DD').isSameOrAfter(
                  fromDate.format('YYYY-MM-DD')
                ) && moment(item.transfer_date, 'YYYY-MM-DD').isSameOrBefore(
                  toDate.format('YYYY-MM-DD')
                )
              )
            );
          }
          return tmpRows;
        }
        const tmpRows: GridRowsProp = [];
        setRows(tmpRows);
        return tmpRows;
      })
      .catch((err) => {
        if (err?.response?.status === 403) {
          handelReturnLogin();
        }
        return false;
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const sftpDownload = async () => {
    const getInvoicesUrl = `${process.env.REACT_APP_BASE_URL}/sftp`;
    let data = JSON.stringify({ from_date: '', to_date: '' });
    if (fromDate && toDate) {
      data = JSON.stringify({
        from_date: fromDate.format('YYYY-MM-DD HH:mm:ss'),
        to_date: toDate.format('YYYY-MM-DD HH:mm:ss')
      });
    }

    await axios
      .post(getInvoicesUrl, data, {
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
          Authorization: `Bearer ${auth.accessToken}`
        }
      })
      .then((response) => {
        console.log(JSON.stringify(response.data));
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const a = document.createElement('a');
        const today = new Date();
        a.href = url;
        a.download = `waterinv-${today.getHours()}-${today.getMinutes()}-${today.getSeconds()}.csv`;
        a.click();
        window.URL.revokeObjectURL(url);
      })
      .catch((err) => {
        console.log(JSON.stringify(err));
      });
  };

  useEffect(() => {
    getInvoices(auth.accessToken);
  }, []);

  if (isLoading) {
    return (
      <div>
        <CircularLoading />
      </div>
    );
  }

  return (
    <>
      <Helmet>
        <title> SFTP </title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 3
        }}
      >
        <Container maxWidth={false}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center'
            }}
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'flex-start'
              }}
            >
              <Button
                variant="contained"
                style={{ marginRight: '3px' }}
                color="primary"
                onClick={sftpDownload}
              >
                <DownloadIcon />
                הורדה
              </Button>
            </Box>
            <FilterByDateSftp
              fromDate={fromDate}
              setFromDate={setFromDate}
              toDate={toDate}
              setToDate={setToDate}
              setRows={setRows}
              rowsFull={rowsFull}
            />
          </Box>
          <Box sx={{ pt: 3 }} className="invoive">
            <BasicTable rows={rows} columns={columns} title="SFTP" />
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default InvoicesSftp;
