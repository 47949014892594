// import React, { useState } from 'react';
// import {
//   Button,
//   Dialog,
//   DialogTitle,
//   DialogContent,
//   DialogActions
// } from '@mui/material';
// import { PictureAsPdf } from '@material-ui/icons';
// import axios from 'axios';
// import { PDFViewer, Document, Page } from '@react-pdf/renderer';

// const PdfPopup = ({ pdfUrl, pdfName, onClose }) => {
//   //   const [numPages, setNumPages] = useState(null);
//   //   const [pageNumber, setPageNumber] = useState(1);

//   //   const onDocumentLoadSuccess = ({ numPages }) => {
//   //     setNumPages(numPages);
//   //   };
//   console.log('ff', pdfUrl);
//   return (
//     <Dialog
//       open
//       onClose={onClose}
//       fullWidth
//       maxWidth="lg" // set maximum width to medium
//     >
//       <DialogTitle>{pdfName}</DialogTitle>
//       <DialogContent>
//         {/* <embed
//           src={pdfUrl}
//           type="application/pdf; charset=utf-8' "
//           style={{ width: '100%', height: '500px' }}
//         />
//         <iframe src={pdfUrl} title={pdfName} width="100%" height="500" /> */}
//         <PDFViewer url={pdfUrl} />
//         <PDFViewer width="100%" height="600px">
//           <Document file={pdfUrl}>
//             <Page pageNumber={1} />
//           </Document>
//         </PDFViewer>
//       </DialogContent>
//       <DialogActions>
//         <Button onClick={onClose}>Close</Button>
//       </DialogActions>
//     </Dialog>
//   );
// };

// const PdfIcon = ({
//   pdfData,
//   pdfName,
//   invoicingId,
//   accessToken,
//   handelReturnLogin
// }) => {
//   const [isPopupVisible, setIsPopupVisible] = useState(false);
//   const [pdfUrl, setPdfUrl] = useState('');

//   const handleOpenPopup = () => {
//     setIsPopupVisible(true);
//   };

//   const handleClosePopup = () => {
//     setIsPopupVisible(false);
//   };

//   const createBlobUrl = (data) => {
//     const blob = new Blob([data], { type: 'application/pdf; charset=utf-8' });
//     const url = URL.createObjectURL(blob);
//     setPdfUrl(url);
//   };

//   const fetchPdfData = async () => {
//     console.log(invoicingId);
//     //   const getInvoicingUrl = `${process.env.REACT_APP_BASE_URL}/invoicing?is_pdf=true&invoicing_id=${invoicingId}`;
//     const getInvoicingUrl = `${process.env.REACT_APP_BASE_URL}/invoicing?invoicing_id=50009&is_pdf=true`;
//     await axios
//       .get(getInvoicingUrl, {
//         headers: {
//           //   'Content-Type': 'application/json',
//           'Access-Control-Allow-Origin': '*',
//           Authorization: `Bearer ${accessToken}`,
//           responseType: 'arraybuffer'
//         }
//       })
//       .then((response) => {
//         if (response?.data?.status_code === 0) {
//           return false;
//         }
//         const file = new Blob([response.data], {
//           type: 'application/pdf; charset=utf-8'
//         });
//         const fileUrl = URL.createObjectURL(file);
//         // console.log(fileUrl);
//         // console.log(response.data);
//         setPdfUrl(fileUrl);
//         setIsPopupVisible(true);
//         return true;
//       })
//       .catch((err) => {
//         if (err?.response?.status === 403) {
//           handelReturnLogin();
//         }
//         return false;
//       });
//   };

//   if (pdfData) {
//     createBlobUrl(pdfData);
//   }

//   return (
//     <>
//       <div
//         role="button"
//         tabIndex={0}
//         className="pdf-icon"
//         onClick={pdfData ? handleOpenPopup : fetchPdfData}
//         style={{ cursor: 'pointer' }}
//         onKeyDown={(event) => {
//           if (event.keyCode === 13) {
//             pdfData ? handleOpenPopup() : fetchPdfData();
//           }
//         }}
//       >
//         <PictureAsPdf />
//       </div>
//       {isPopupVisible && (
//         <PdfPopup
//           pdfUrl={pdfUrl}
//           pdfName={pdfName}
//           onClose={handleClosePopup}
//         />
//       )}
//     </>
//   );
// };

// export default PdfIcon;

import React, { useState } from 'react';
import { PictureAsPdf } from '@material-ui/icons';
import axios from 'axios';
import CircularLoading from 'src/components/helpers/Loading';

const PdfIcon = ({ pdfName, invoicingId, accessToken, handelReturnLogin }) => {
  const [isLoading, setIsLoading] = useState(false);

  const handleOpenPopup = async () => {
    console.log(invoicingId, pdfName);
    setIsLoading(true);
    //   const getInvoicingUrl = `${process.env.REACT_APP_BASE_URL}/invoicing?is_pdf=true&invoicing_id=${invoicingId}`;
    const getInvoicingUrl = `${process.env.REACT_APP_BASE_URL}/invoicing?invoicing_id=${invoicingId}&is_pdf=true`;
    await axios
      .get(getInvoicingUrl, {
        headers: {
          'Access-Control-Allow-Origin': '*',
          Authorization: `Bearer ${accessToken}`,
          responseType: 'arraybuffer'
        },
        responseType: 'blob'
      })
      .then((response) => {
        if (response?.data?.status_code === 0) {
          setIsLoading(false);
          return false;
        }

        const fileUrl = URL.createObjectURL(response.data);
        window.open(fileUrl);
        setIsLoading(false);
        return true;
      })
      .catch((err) => {
        if (err?.response?.status === 403) {
          handelReturnLogin();
        }
        setIsLoading(false);
        return false;
      });
  };

  if (isLoading) {
    return <CircularLoading />;
  }
  return (
    <div>
      <div
        role="button"
        tabIndex={0}
        className="pdf-icon"
        onClick={handleOpenPopup}
        style={{ cursor: 'pointer' }}
        onKeyDown={(event) => {
          if (event.keyCode === 13) {
            handleOpenPopup();
          }
        }}
      >
        <PictureAsPdf />
      </div>
    </div>
  );
};

export default PdfIcon;
