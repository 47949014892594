import { Navigate } from 'react-router-dom';
import DashboardLayout from './components/DashboardLayout';
import MainLayout from './components/MainLayout';
import CustomerList from './pages/CustomerList';
import Dashboard from './pages/Dashboard';
import Login from './pages/Login';
import NotFound from './pages/NotFound';
import PersistLogin from './components/PersistLogin';
import RequireAuth from './components/RequireAuth';
import Invoices from './pages/Invoicess';
import Stations from './pages/Stations';
import Providers from './pages/Providers';
import Operators from './pages/Operator';
import ForgotPass from './pages/ForgotPass';
import InvoicessSftp from './pages/InvoicessSftp';
import MissingInvoicing from './pages/missingnvoicing';

const ROLES = {
  User: 'u',
  SupperAdmin: 'sa',
  Admin: 'a'
};

const routes = () => [
  {
    path: '/',
    element: <MainLayout />,
    children: [
      {
        path: '/',
        element: <PersistLogin />,
        children: [
          {
            element: (
              <RequireAuth
                allowedRoles={[ROLES.Admin, ROLES.User, ROLES.SupperAdmin]}
              />
            ),
            children: [
              {
                element: <DashboardLayout />,
                children: [
                  {
                    path: '',
                    element: <Navigate to="/dashboard" />
                  },
                  {
                    path: 'customers',
                    element: (
                      <RequireAuth
                        allowedRoles={[ROLES.Admin, ROLES.SupperAdmin]}
                      />
                    ),
                    children: [{ path: '', element: <CustomerList /> }]
                  },
                  {
                    path: 'dashboard',
                    element: (
                      <RequireAuth
                        allowedRoles={[ROLES.Admin, ROLES.SupperAdmin]}
                      />
                    ),
                    children: [{ path: '', element: <Dashboard /> }]
                  },
                  {
                    path: 'Invoices',
                    element: <Invoices />
                  },
                  {
                    path: 'InvoicesSftp',
                    element: <InvoicessSftp />
                  },
                  {
                    path: 'MisiingInvoicing',
                    element: <MissingInvoicing />
                  },
                  {
                    path: 'Stations',
                    element: <Stations />
                  },
                  {
                    path: 'Providers',
                    element: <Providers />
                  },
                  {
                    path: 'Operators',
                    element: <RequireAuth allowedRoles={[ROLES.SupperAdmin]} />,
                    children: [{ path: '', element: <Operators /> }]
                  },
                  { path: '*', element: <Navigate to="/404" /> }
                ]
              }
            ]
          },
          { path: 'login', element: <Login /> }
        ]
      },
      {
        path: 'forgotPass',
        element: <ForgotPass />
      },
      { path: '404', element: <NotFound /> }
    ]
  }
];

export default routes;
