import { Bar } from 'react-chartjs-2';
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  useTheme,
  Typography,
  colors
} from '@material-ui/core';

const Sales = (props) => {
  const theme = useTheme();
  const {
    thisyear,
    lastyear,
    datasetslable1,
    datasetslable2,
    titlecard,
    title
  } = props;

  const thisYearSum = Object.values(thisyear)
    .reduce((accumulator, currentValue) => accumulator + currentValue, 0)
    .toFixed(3);
  const lastYearSum = Object.values(lastyear)
    .reduce((accumulator, currentValue) => accumulator + currentValue, 0)
    .toFixed(3);

  const data = {
    datasets: [
      {
        backgroundColor: colors.indigo[500],
        barPercentage: 0.5,
        barThickness: 12,
        borderRadius: 4,
        categoryPercentage: 0.5,
        data: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11]?.map(
          (key) => thisyear[key]
        ),
        label: datasetslable1,
        maxBarThickness: 10
      },
      {
        backgroundColor: colors.grey[400],
        barPercentage: 0.5,
        barThickness: 12,
        borderRadius: 4,
        categoryPercentage: 0.5,
        data: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11]?.map(
          (key) => lastyear[key]
        ),
        label: datasetslable2,
        maxBarThickness: 10
      }
    ],
    labels: [
      'ינואר',
      'פבואר',
      'מרץ',
      'אפריל',
      'מאי',
      'יוני',
      'יולי',
      'אוגוסט',
      'ספטמבר',
      'אוקטובר',
      'נובמבר',
      'דצמבר'
    ]
  };

  const options = {
    animation: false,
    cornerRadius: 20,
    layout: { padding: 0 },
    legend: { display: false },
    maintainAspectRatio: false,
    responsive: true,
    scales: {
      // xAxes: [
      //   {
      //     // ticks: {
      //     //   fontColor: theme.palette.text.secondary
      //     // },
      //     // gridLines: {
      //     //   display: false,
      //     //   drawBorder: false
      //     // }
      //   }
      // ],
      // yAxes: [
      //   {
      //     ticks: {
      //       fontColor: theme.palette.text.secondary,
      //       beginAtZero: true,
      //       min: 0
      //     },
      //     gridLines: {
      //       borderDash: [2],
      //       borderDashOffset: [2],
      //       color: theme.palette.divider,
      //       drawBorder: false,
      //       zeroLineBorderDash: [2],
      //       zeroLineBorderDashOffset: [2],
      //       zeroLineColor: theme.palette.divider
      //     }
      //   }
      // ]
    },
    tooltips: {
      backgroundColor: theme.palette.background.paper,
      bodyFontColor: theme.palette.text.secondary,
      borderColor: theme.palette.divider,
      borderWidth: 1,
      enabled: true,
      footerFontColor: theme.palette.text.secondary,
      intersect: false,
      mode: 'index',
      titleFontColor: theme.palette.text.primary
    }
  };

  return (
    <Card {...props}>
      {/* <CardHeader
        action={
          <Button endIcon={<ArrowDropDownIcon />} size="small" variant="text">
            Last  days
          </Button>
        }
        title="Latest Sales"
      /> */}
      <CardHeader
        sx={{ textAlign: 'center', height: 74 }}
        title={(
          <Box>
            <Typography variant="h6">
              {title ?? `עלות מים לתחילת השנה:  ₪${(+thisYearSum)?.toLocaleString()}  `}
            </Typography>
            <Typography variant="h6">
              {title ? '' : `עלות מים שנה קודמת לאותה תקופה : ₪${(+lastYearSum)?.toLocaleString()} `}
            </Typography>
            <Typography variant="h6">{titlecard}</Typography>
          </Box>
        )}
      />
      <Divider />
      <CardContent>
        <Box
          sx={{
            height: 296,
            position: 'relative'
          }}
        >
          <Bar data={data} options={options} />
        </Box>
      </CardContent>
      <Divider />
    </Card>
  );
};

export default Sales;
