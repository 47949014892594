import { AppBar, Toolbar, Typography } from '@material-ui/core';

const MainNavbar = (props) => (
  <AppBar elevation={0} {...props}>
    <Toolbar sx={{ height: 64, justifyContent: 'center' }}>
      <Typography>מערכת חשבוניות</Typography>
    </Toolbar>
  </AppBar>
);

export default MainNavbar;
