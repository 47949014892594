import TextField from '@mui/material/TextField';
import DialogContent from '@mui/material/DialogContent';
import { useEffect, useState } from 'react';
import { Box, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import Autocomplete from '@mui/material/Autocomplete';
import axios from 'axios';
import CircularLoading from 'src/components/helpers/Loading';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles(() => ({
  shrink: {
    transformOrigin: 'top right',
    right: 28
  }
}));

const EditStation = ({
  rowData,
  accessToken,
  handelReturnLogin,
  open,
  handleClose,
  setSnackbarMss,
  setOpenSnackbar,
  setQuery,
  ...props
}) => {
  const classes = useStyles();

  const [isLoading, setLoading] = useState(false);
  const [errMsg, setErrMsg] = useState('');
  const [stationNumber, setStationNumber] = useState(rowData?.station_number);
  const [stationName, setStationName] = useState(rowData?.station_name);
  const [propertyId, setPropertyId] = useState(rowData?.property_id);
  const [waterMeter, setWaterMeter] = useState(rowData?.water_meter);
  const [waterCorporation, setWaterCorporation] = useState(
    rowData?.water_corporation
  );

  const [area, setArea] = useState(rowData?.area);
  const [address, setAddress] = useState(rowData?.address);
  const [remarks, setRemarks] = useState(rowData?.remarks);

  const [stationNumberError, setStationNumberError] = useState('');
  const [stationNameError, setStationNameError] = useState('');
  const [propertyIdError, setPropertyIdError] = useState('');
  const [waterMeterError, setWaterMeterError] = useState('');
  const [waterCorporationError, setWaterCorporationError] = useState('');
  const [areaError, setAreaError] = useState('');
  const [addressError, setAddressError] = useState('');
  const [remarksError, setRemarksError] = useState('');

  const handleSubmit = async () => {
    if (!stationNumber) {
      setStationNumberError('שדה חובה');
    }
    if (!waterMeter) {
      setWaterMeterError('שדה חובה');
    }
    if (!stationName) {
      setStationNameError('שדה חובה');
    }
    if (!address) {
      setAddressError('שדה חובה');
    }
    if (!waterCorporation) {
      setWaterCorporationError('שדה חובה');
    }
    if (!stationNumber
      || !waterMeter
      || !stationName
      || !address
      || !waterCorporation
    ) {
      return;
    }
    setLoading(true);

    const getStationsUrl = `${process.env.REACT_APP_BASE_URL}/station`;

    let jsonlet = {
      address,
      area,
      property_id: propertyId,
      remarks,
      station_name: stationName,
      station_number: rowData.station_number,
      water_corporation: waterCorporation,
      water_meter: waterMeter
    };
    if (rowData.station_number !== stationNumber) {
      jsonlet = {
        ...jsonlet,
        station_number_n: stationNumber
      };
    }
    await axios
      .put(
        getStationsUrl,
        JSON.stringify(jsonlet),
        {
          headers: {
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
            Authorization: `Bearer ${accessToken}`
          }
        }
      )
      .then((response) => {
        if (response?.status === 200 && response.data?.status_code === 1) {
          setSnackbarMss('התחנה נערכה בהצלחה');
          setErrMsg('');
          setOpenSnackbar(true);
          setQuery([]);
          handleClose();
        }

        if (response?.data?.result && response.data?.status_code === 0) {
          setErrMsg(response?.data?.result);
        }
      })
      .catch((err) => {
        setErrMsg(err?.message);
        if (err?.response?.status === 403) {
          handelReturnLogin();
        }
        return false;
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    setStationNumber(rowData?.station_number);
    setLoading(false);
    setErrMsg('');
    setStationNumber(rowData?.station_number);
    setStationName(rowData?.station_name);
    setPropertyId(rowData?.property_id);
    setWaterMeter(rowData?.water_meter);
    setWaterCorporation(rowData?.water_corporation);

    setArea(rowData?.area);
    setAddress(rowData?.address);
    setRemarks(rowData?.remarks);

    setStationNumberError('');
    setStationNameError('');
    setPropertyIdError('');
    setWaterMeterError('');
    setWaterCorporationError('');
    setAreaError('');
    setAddressError('');
    setRemarksError('');
  }, [rowData]);

  const areaNames = ['מרכז', 'צפון', 'דרום', 'ירושלים והסביבה'];

  return (
    <Box {...props}>
      <Box
        component="form"
        sx={{
          display: 'flex',
          justifyContent: 'flex-start'
        }}
      >
        <Dialog open={open} onClose={handleClose}>
          {isLoading || !rowData ? (
            <Box
              sx={{
                display: 'grid',
                paddingBottom: 6,
                paddingTop: 2,
                gridTemplateColumns: { sm: '2fr 1fr' },
                gap: 3
                // '& > :not(style)': { m: 1, width: '25ch' }
              }}
            >
              <CircularLoading />
            </Box>
          ) : (
            <>
              <DialogTitle
                sx={{
                  p: 2,
                  textAlign: 'center',
                  fontSize: 30
                }}
              >
                <Box display="flex" alignItems="center">
                  <Box>
                    <IconButton onClick={handleClose}>
                      <CloseIcon />
                    </IconButton>
                  </Box>
                  <Box flexGrow={1}> עריכת תחנה</Box>
                </Box>
              </DialogTitle>
              <DialogContent>
                {/* <DialogContentText>נא מלא את הפרטים הבאים</DialogContentText> */}
                <Box
                  sx={{
                    display: 'grid',
                    paddingBottom: 6,
                    paddingTop: 2,
                    gridTemplateColumns: { sm: '2fr 1fr' },
                    gap: 3
                    // '& > :not(style)': { m: 1, width: '25ch' }
                  }}
                >
                  <TextField
                    id="stationName"
                    required
                    label="שם תחנה"
                    variant="outlined"
                    value={stationName}
                    onChange={(event) => {
                      setStationNameError('');
                      setStationName(event.target.value);
                    }}
                    helperText={stationNameError}
                    error={!!stationNameError}
                    InputLabelProps={{
                      shrink: true,
                      classes: {
                        root: classes.labelRoot,
                        shrink: classes.shrink
                      }
                    }}
                  />
                  <TextField
                    id="stationNumber"
                    required
                    label="מספר תחנה"
                    type="number"
                    variant="outlined"
                    value={stationNumber}
                    onChange={(event) => {
                      setStationNumberError('');
                      setStationNumber(event.target.value);
                    }}
                    helperText={stationNumberError}
                    error={!!stationNameError}
                    InputLabelProps={{
                      shrink: true,
                      classes: {
                        root: classes.labelRoot,
                        shrink: classes.shrink
                      }
                    }}
                  />
                </Box>
                <Box
                  sx={{
                    display: 'grid',
                    gridTemplateColumns: { sm: '1fr 1fr 1fr' },
                    gap: 3,
                    paddingBottom: 6
                  }}
                >
                  <TextField
                    id="propertyId"
                    label="מספר נכס"
                    type="number"
                    variant="outlined"
                    value={propertyId}
                    onChange={(event) => {
                      setPropertyIdError('');
                      setPropertyId(event.target.value);
                    }}
                    helperText={propertyIdError}
                    error={!!propertyIdError}
                    InputLabelProps={{
                      shrink: true,
                      classes: {
                        root: classes.labelRoot,
                        shrink: classes.shrink
                      }
                    }}
                  />
                  <TextField
                    id="waterMeter"
                    required
                    label="מד מים"
                    variant="outlined"
                    value={waterMeter}
                    onChange={(event) => {
                      setWaterMeterError('');
                      setWaterMeter(event.target.value);
                    }}
                    helperText={waterMeterError}
                    error={!!waterMeterError}
                    InputLabelProps={{
                      shrink: true,
                      classes: {
                        root: classes.labelRoot,
                        shrink: classes.shrink
                      }
                    }}
                  />
                  <TextField
                    id="waterCorporation"
                    required
                    label="תאגיד מים"
                    variant="outlined"
                    value={waterCorporation}
                    onChange={(event) => {
                      setWaterCorporationError('');
                      setWaterCorporation(event.target.value);
                    }}
                    helperText={waterCorporationError}
                    error={!!waterCorporationError}
                    InputLabelProps={{
                      shrink: true,
                      classes: {
                        root: classes.labelRoot,
                        shrink: classes.shrink
                      }
                    }}
                  />
                </Box>
                <Box
                  sx={{
                    display: 'grid',
                    gridTemplateColumns: { sm: '1fr 1fr' },
                    gap: 3,
                    paddingBottom: 6
                  }}
                >
                  <TextField
                    id="address"
                    label="רחוב"
                    variant="outlined"
                    value={address}
                    onChange={(event) => {
                      setAddressError('');
                      setAddress(event.target.value);
                    }}
                    helperText={addressError}
                    error={!!addressError}
                    InputLabelProps={{
                      shrink: true,
                      classes: {
                        root: classes.labelRoot,
                        shrink: classes.shrink
                      }
                    }}
                  />
                  <Autocomplete
                    disablePortal
                    id="area"
                    options={areaNames}
                    value={area}
                    onSelect={(event) => {
                      setAreaError('');
                      setArea(event.target.value);
                    }}
                    renderInput={(params) => (
                      <TextField
                        helperText={areaError}
                        error={!!areaError}
                        {...params}
                        label="אזור"
                      />
                    )}
                  />
                </Box>
                <Box
                  sx={{
                    display: 'grid',
                    gridTemplateColumns: { sm: '1fr' },
                    gap: 3
                  }}
                >
                  <TextField
                    id="remarks"
                    label="הערות"
                    variant="outlined"
                    value={remarks}
                    onChange={(event) => {
                      setRemarksError('');
                      setRemarks(event.target.value);
                    }}
                    multiline
                    rows={2}
                    helperText={remarksError}
                    error={!!remarksError}
                    InputLabelProps={{
                      shrink: true,
                      classes: {
                        root: classes.labelRoot,
                        shrink: classes.shrink
                      }
                    }}
                  />
                </Box>
              </DialogContent>
              <p className="errorMsg">{errMsg}</p>
              <DialogActions
                sx={{
                  justifyContent: 'center'
                }}
              >
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleSubmit}
                >
                  עדכן
                </Button>
              </DialogActions>
            </>
          )}
        </Dialog>
      </Box>
    </Box>
  );
};

export default EditStation;
