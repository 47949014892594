import { Helmet } from 'react-helmet';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Container } from '@material-ui/core';
import { GridRowsProp, GridColDef } from '@mui/x-data-grid';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import EditIcon from '@mui/icons-material/Edit';
// import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import axios from 'axios';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import Cookies from 'universal-cookie';
import Chip from '@mui/material/Chip';
import CircularLoading from 'src/components/helpers/Loading';
import BasicTable from '../components/helpers/BasicTable';
import useAuth from '../hooks/useAuth';
import AddProvider from '../components/providers/AddProvider';
import ViewProvider from '../components/providers/ViewProvider';
import EditProvider from '../components/providers/EditProvider';
import AlertDialog from '../components/helpers/AlertPopup';

const Providers = () => {
  const navigate = useNavigate();
  const { auth, setAuth } = useAuth();
  const [snackbarMss, setSnackbarMss] = useState('');
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [query, setQuery] = useState([]);
  const [rows, setRows] = useState([]);

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };
  const handelReturnLogin = () => {
    const cookies = new Cookies();
    cookies.set('Auth', {}, { path: '/' });
    setAuth(cookies.get('Auth'));
    navigate('/login', { replace: true });
  };

  const [openView, setOpenView] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [open, setOpen] = useState(false);
  const [errorMsg, setErrMsg] = useState(false);
  const [isLoadingDelete, setLoadingDelete] = useState(false);
  const [providerId, setProviderId] = useState('');
  const [hp, setHP] = useState('');
  const [providerName, setProviderName] = useState('');
  const [active, setActive] = useState('');

  const [rowData, setRowData] = useState(false);
  const handleCloseEdit = () => {
    setOpenEdit(false);
    setRowData();
  };
  const handleCloseView = () => {
    setOpenView(false);
  };

  const handleCloseDelete = () => {
    setOpen(false);
  };

  const deleteProvider = async () => {
    let getProvidersUrl = `${process.env.REACT_APP_BASE_URL}/provider?provider_id=${providerId}&hp=${hp}&active=${active}`;
    // if (active) {
    //   getProvidersUrl = `${process.env.REACT_APP_BASE_URL}/provider?provider_id=${provider}&hp=${curhp}&active=${active === '0'}`;
    // }
    setLoadingDelete(true);
    await axios
      .delete(getProvidersUrl, {
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
          Authorization: `Bearer ${auth.accessToken}`
        }
      })
      .then((response) => {
        if (response?.status === 200 && response.data?.status_code === 1) {
          setSnackbarMss('שונה בהצלחה');
          setQuery([]);
          setOpenSnackbar(true);
          handleCloseDelete();
          return true;
        }
        if (response?.data?.result && response.data?.status_code === 0) {
          setErrMsg('הפעולה בוצעה בהצלחה');
          return false;
        }
        setErrMsg(response?.data?.result);
        return false;
      })
      .catch((err) => {
        if (err?.response?.status === 403) {
          handelReturnLogin();
        }
        setErrMsg(err?.message);
        return false;
      })
      .finally(() => {
        setLoadingDelete(false);
        return false;
      });
  };

  const columns: GridColDef[] = [
    // {
    //   field: 'delete',
    //   headerName: '',
    //   flex: 0.2,
    //   sortable: false,
    //   renderCell: (params) => {
    //     const handleClickOpen = () => {
    //       setProviderId(params.row.provider_id);
    //       setHP(params.row.hp);
    //       setProviderName(params.row.provider_name);
    //       setOpen(true);
    //     };

    //     return (
    //       <>
    //         <DeleteIcon onClick={handleClickOpen} />
    //       </>
    //     );
    //   }
    // },
    {
      field: 'edit',
      headerName: '',
      flex: 0.2,
      sortable: false,
      renderCell: (params) => {
        const handleClickOpenEdit = () => {
          setRowData(params.row);
          setOpenEdit(true);
        };
        return (
          <>
            <EditIcon onClick={handleClickOpenEdit} />
          </>
        );
      }
    },
    {
      field: 'id',
      headerName: 'מספר מזהה',
      sortable: false,
      hide: true
    },
    {
      field: 'active',
      headerName: 'סטטוס',
      valueFormatter: ({ value }) => {
        if (value === '1') {
          return 'פעיל';
        }
        return 'לא פעיל';
      },
      filterable: false,
      width: 115,
      renderCell: (params) => {
        const handleClickOpen = () => {
          setProviderId(params.row.provider_id);
          setHP(params.row.hp);
          setProviderName(params.row.provider_name);
          setActive(params?.row?.active === '0');
          setOpen(true);
        };

        let chipVar;
        if (params?.row?.active === '1') {
          chipVar = (
            <Chip onClick={handleClickOpen} label="פעיל" color="success" />
          );
        } else {
          chipVar = (
            <Chip onClick={handleClickOpen} label="לא פעיל" color="error" />
          );
        }
        return <>{chipVar}</>;
      }
    },
    { field: 'remarks', flex: 1, headerName: 'הערות' },
    { field: 'email', flex: 1, headerName: 'אימייל' },
    { field: 'phone', flex: 1, headerName: 'מספר נייד' },
    { field: 'hp', flex: 1, headerName: 'ח.פ' },
    { field: 'provider_name', flex: 1, headerName: 'שם תאגיד' },
    { field: 'provider_id', flex: 1, headerName: 'מספר תאגיד' },
    {
      field: 'view',
      headerName: '',
      flex: 0.2,
      sortable: false,
      renderCell: (params) => {
        const handleClickOpen = () => {
          setRowData(params.row);
          setOpenView(true);
        };
        return (
          <>
            <OpenInNewIcon onClick={handleClickOpen} />
          </>
        );
      }
    }
  ];
  const [isLoading, setLoading] = useState(true);

  const getProviders = async (accessToken) => {
    const getProvidersUrl = `${process.env.REACT_APP_BASE_URL}/provider?fetch_all=true&display_all=true`;

    await axios
      .get(getProvidersUrl, {
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
          Authorization: `Bearer ${accessToken}`
        }
      })
      .then((response) => {
        if (response?.data?.result && response.data?.status_code === 1) {
          const tmpRows: GridRowsProp = response?.data?.result;
          setRows(tmpRows);
          return tmpRows;
        }
        const tmpRows: GridRowsProp = [];
        setRows(tmpRows);
        return tmpRows;
      })
      .catch((err) => {
        if (err?.response?.status === 403) {
          handelReturnLogin();
        }
        return false;
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getProviders(auth.accessToken);
  }, [query]);

  if (isLoading) {
    return (
      <div>
        <CircularLoading />
      </div>
    );
  }

  return (
    <>
      <Helmet>
        <title>תאגידים</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 3
        }}
      >
        <Container maxWidth={false}>
          <AddProvider
            buttonText="הוסף תאגיד חדש"
            accessToken={auth.accessToken}
            handelReturnLogin={handelReturnLogin}
            setQuery={setQuery}
          />
          <Snackbar
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            open={openSnackbar}
            onClose={handleCloseSnackbar}
            message={snackbarMss}
            key={{ vertical: 'top', horizontal: 'center' }}
          >
            <Stack sx={{ width: '100%' }} spacing={2}>
              <Alert variant="filled" severity="success">
                {snackbarMss}
              </Alert>
            </Stack>
          </Snackbar>
          <EditProvider
            rowData={rowData}
            handleClose={handleCloseEdit}
            open={openEdit}
            handelReturnLogin={handelReturnLogin}
            accessToken={auth.accessToken}
            setOpenSnackbar={setOpenSnackbar}
            setSnackbarMss={setSnackbarMss}
            setQuery={setQuery}
          />
          <ViewProvider
            rowData={rowData}
            handleClose={handleCloseView}
            open={openView}
          />
          <AlertDialog
            open={open}
            handleClose={handleCloseDelete}
            dialogTitle={`האם אתה בטוח שברצונך לשנות את התאגיד - ${providerName} ?`}
            handleSubmit={deleteProvider}
            errorMsg={errorMsg}
            isLoading={isLoadingDelete}
          />
          <Box sx={{ pt: 3 }}>
            <BasicTable rows={rows} columns={columns} title="תאגידים" />
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default Providers;
