import React, { useState } from 'react';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import { makeStyles } from '@material-ui/styles';
import Button from '@material-ui/core/Button';
// import Tooltip from '@mui/material/Tooltip';
import DeleteIcon from '@material-ui/icons/Delete';
import axios from 'axios';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';

const useStyles = makeStyles(() => ({
  input: {
    display: 'none'
  },
  button: {
    marginRight: '3px'
  },
  icon: {
    fontSize: '25px',
    marginLeft: '2px'
  }
}));

const UploadPDF = ({ accessToken }) => {
  const [files, setFiles] = useState([]);
  const [inputPlaceholder, setInputPlaceholder] = useState('בחירת קבצי pdf');
  const [fileInputRef, setFileInputRef] = useState(null);
  const [snackbarMss, setSnackbarMss] = useState('');
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [severity, setSeverity] = useState('info');

  const classes = useStyles();

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnackbar(false);
  };

  const handleChange = (event) => {
    const selectedFiles = event.target.files;
    const acceptedFiles = [];
    for (let i = 0; i < selectedFiles.length; i++) {
      if (selectedFiles[i].type === 'application/pdf') {
        acceptedFiles.push(selectedFiles[i]);
      }
    }
    setFiles((prevFiles) => [...prevFiles, ...acceptedFiles]);
    setInputPlaceholder(
      files.length + acceptedFiles.length
        ? `${files.length + acceptedFiles.length} הקבצים שנבחרו`
        : 'בחירת קבצי pdf'
    );
  };

  const handleClick = () => {
    fileInputRef.click();
  };

  const handleRemoveFile = (file) => {
    setFiles((prevFiles) => prevFiles.filter((f) => f !== file));
    setInputPlaceholder(
      files.length - 1 > 0
        ? `${files.length - 1} הקבצים שנבחרו`
        : 'בחירת קבצי pdf'
    );
  };

  const uploadToServer = () => {
    const url = `${process.env.REACT_APP_BASE_URL}/push-data`;
    const formData = new FormData();
    for (let i = 0; i < files.length; i++) {
      formData.append('file_upload', files[i]);
    }

    axios
      .post(url, formData, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'multipart/form-data'
        }
      })
      .then((response) => {
        if (response?.status === 200 && response.data?.status_code === 1) {
          setFiles([]);
          setInputPlaceholder('בחירת קבצי pdf');
          setSeverity('success');
          setSnackbarMss('עלה בהצלחה');
          setOpenSnackbar(true);
          setTimeout(() => {
            setSnackbarMss('');
            setOpenSnackbar(false);
          }, 4000);
        } else {
          setSeverity('error');
          setSnackbarMss('נכשל');
          setOpenSnackbar(true);
          setTimeout(() => {
            setSnackbarMss('');
            setOpenSnackbar(false);
          }, 4000);
        }
      })
      .catch(() => {
        setSeverity('error');
        setSnackbarMss('נכשל');
        setOpenSnackbar(true);
        setTimeout(() => {
          setSnackbarMss('');
          setOpenSnackbar(false);
        }, 4000);

        // TODO handle error response
      });
  };

  return (
    <div>
      <div style={{ display: 'flex' }}>
        <input
          type="file"
          multiple
          accept="application/pdf"
          onChange={handleChange}
          ref={(input) => setFileInputRef(input)}
          className={classes.input}
        />
        <div style={{ display: 'flex', alignItems: 'flex-start' }}>
          <Button
            variant="contained"
            color="primary"
            className={classes.button}
            onClick={handleClick}
          >
            <CloudUploadIcon onClick={handleClick} className={classes.icon} />
            {inputPlaceholder}
          </Button>
        </div>
        <div style={{ display: 'flex' }}>
          <div>
            {files.length > 0 && (
              <p style={{ marginRight: '6px' }}>קבצים שנבחרו:</p>
            )}
            <ul
              style={{
                maxHeight: '5em',
                marginRight: '6px',
                overflow: 'auto',
                border: '1px solid #ccc'
              }}
            >
              {files.map((file) => (
                <li key={file.name}>
                  {file.name}
                  <DeleteIcon onClick={() => handleRemoveFile(file)} />
                </li>
              ))}
            </ul>
          </div>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            {files.length > 0 && (
              <Button
                variant="contained"
                color="primary"
                className={classes.button}
                onClick={uploadToServer}
              >
                העלאה
              </Button>
            )}
          </div>
        </div>
      </div>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={openSnackbar}
        onClose={handleCloseSnackbar}
        message={snackbarMss}
        key={{ vertical: 'top', horizontal: 'center' }}
      >
        <Stack sx={{ width: '100%' }} spacing={2}>
          <Alert variant="filled" severity={severity}>
            {snackbarMss}
          </Alert>
        </Stack>
      </Snackbar>
    </div>
  );
};

export default UploadPDF;
