import DialogContent from '@mui/material/DialogContent';
import { Box } from '@material-ui/core';
import Dialog from '@mui/material/Dialog';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

const ViewStation = ({ rowData, handleClose, open, ...props }) => {
  if (!rowData) {
    return <></>;
  }
  return (
    <Box {...props}>
      <Dialog open={open} onClose={handleClose} fullWidth>
        <DialogTitle
          sx={{
            p: 2,
            textAlign: 'center',
            fontSize: 30
          }}
        >
          <Box display="flex" alignItems="center">
            <Box>
              <IconButton onClick={handleClose}>
                <CloseIcon />
              </IconButton>
            </Box>
            <Box flexGrow={1}>{`תחנת ${rowData.station_name}`}</Box>
          </Box>
        </DialogTitle>
        <DialogContent>
          <Grid container margin="auto">
            <Grid item xs={4}>
              <DialogContentText>מספר תחנה:</DialogContentText>
              <DialogContentText>מספר נכס:</DialogContentText>
              <DialogContentText>מד מים:</DialogContentText>
              <DialogContentText>תאגיד מים:</DialogContentText>
              <DialogContentText>כביש או צומת:</DialogContentText>
              <DialogContentText>אזור:</DialogContentText>
              <DialogContentText>עיר:</DialogContentText>
              <DialogContentText>רחוב:</DialogContentText>
              <DialogContentText>מספר:</DialogContentText>
              <DialogContentText>הערות:</DialogContentText>
            </Grid>
            <Grid item xs={8}>
              <DialogContentText>
                {`${rowData.station_number ? rowData.station_number : '-'}`}
              </DialogContentText>
              <DialogContentText>
                {`${rowData.property_id ? rowData.property_id : '-'}`}
              </DialogContentText>
              <DialogContentText>
                {`${rowData.water_meter ? rowData.water_meter : '-'}`}
              </DialogContentText>
              <DialogContentText>
                {`${
                  rowData.water_corporation ? rowData.water_corporation : '-'
                }`}
              </DialogContentText>
              <DialogContentText>
                {`${
                  rowData.road_or_intersection
                    ? rowData.road_or_intersection
                    : '-'
                }`}
              </DialogContentText>
              <DialogContentText>
                {`${rowData.area ? rowData.area : '-'}`}
              </DialogContentText>
              <DialogContentText>
                {`${rowData.city ? rowData.city : '-'}`}
              </DialogContentText>
              <DialogContentText>
                {`${rowData.address ? rowData.address : '-'}`}
              </DialogContentText>
              <DialogContentText>
                {`${rowData.address_number ? rowData.address_number : '-'}`}
              </DialogContentText>
              <DialogContentText>
                {`${rowData.remarks ? rowData.remarks : '-'}`}
              </DialogContentText>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </Box>
  );
};

export default ViewStation;
