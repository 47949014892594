import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import axios from 'axios';
import {
  // Avatar,
  Box,
  Divider,
  Drawer,
  Hidden,
  List,
  Typography
} from '@material-ui/core';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import DashboardIcon from '@mui/icons-material/Dashboard';
import LocalGasStationIcon from '@mui/icons-material/LocalGasStation';
import PeopleIcon from '@mui/icons-material/People';
import DescriptionIcon from '@mui/icons-material/Description';
import SourceIcon from '@mui/icons-material/Source';
import BusinessIcon from '@mui/icons-material/Business';
import Cookies from 'universal-cookie';
import NavItem from './NavItem';
import useAuth from '../hooks/useAuth';

const ROLES = {
  User: 'u',
  SupperAdmin: 'sa',
  Admin: 'a'
};

const items = [
  {
    id: 1,
    href: '/dashboard',
    icon: DashboardIcon,
    title: 'מסך ראשי',
    allowRull: [ROLES.Admin, ROLES.SupperAdmin]
  },
  {
    id: 2,
    href: '/stations',
    icon: LocalGasStationIcon,
    title: 'תחנות'
  },
  {
    id: 3,
    href: '/invoices',
    icon: DescriptionIcon,
    title: 'חשבוניות'
  },
  {
    id: 4,
    href: '/InvoicesSftp',
    icon: SourceIcon,
    title: 'SFTP'
  },
  {
    id: 5,
    href: '/providers',
    icon: BusinessIcon,
    title: 'תאגידים'
  },
  {
    id: 6,
    href: '/customers',
    icon: PeopleIcon,
    title: 'משתמשים',
    allowRull: [ROLES.Admin, ROLES.SupperAdmin]
  },
  {
    id: 7,
    href: '/operators',
    icon: AccountTreeIcon,
    title: 'חברות',
    allowRull: [ROLES.SupperAdmin]
  }
  // {
  //   href: '/account',
  //   icon: UserIcon,
  //   title: 'אזור אישי'
  // },
  // {
  //   href: '/settings',
  //   icon: SettingsIcon,
  //   title: 'Settings'
  // },
  // {
  //   href: '/login',
  //   icon: LockIcon,
  //   title: 'Login'
  // },
  // {
  //   href: '/register',
  //   icon: UserPlusIcon,
  //   title: 'Register'
  // },
  // {
  //   href: '/404',
  //   icon: AlertCircleIcon,
  //   title: 'Error'
  // }
];

const DashboardSidebar = ({ onMobileClose, openMobile }) => {
  const location = useLocation();
  const cookies = new Cookies();
  const { auth, setAuth } = useAuth();
  const [user, setUser] = useState(cookies.get('User'));

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
  }, [location.pathname]);

  const handelReturnLogin = () => {
    cookies.set('Auth', {}, { path: '/' });
    setAuth(cookies.get('Auth'));
    const navigate = useNavigate();
    navigate('/login', { replace: true });
  };

  const getUser = async (accessToken) => {
    const getUserUrl = `${process.env.REACT_APP_BASE_URL}/user`;

    await axios
      .get(getUserUrl, {
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
          Authorization: `Bearer ${accessToken}`
        }
      })
      .then((response) => {
        if (response?.data?.result && response.data?.status_code === 1) {
          setUser(response?.data?.result);
          cookies.set('User', response?.data?.result, { path: '/' });
        } else {
          handelReturnLogin();
        }
      })
      .catch((err) => {
        if (err?.response?.status === 403) {
          handelReturnLogin();
        }
      });
  };

  useEffect(() => {
    if (!user || Object.keys(user).length === 0) {
      getUser(auth.accessToken);
    }
  }, [user]);

  const content = (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%'
      }}
    >
      <Box
        sx={{
          alignItems: 'center',
          display: 'flex',
          flexDirection: 'column',
          p: 2
        }}
      >
        {/* <Avatar
          component={RouterLink}
          src={user.avatar}
          sx={{
            cursor: 'pointer',
            width: 64,
            height: 64
          }}
          to="/app/account"
        /> */}
        <Typography color="textPrimary" variant="h4">
          {`${user?.first_name} ${user?.last_name}`}
        </Typography>
        {/* <Typography color="textPrimary" variant="h7">
          {user?.email}
        </Typography>
        <Typography color="textSecondary" variant="body2">
          {user?.role}
        </Typography> */}
      </Box>
      <Divider />
      <Box sx={{ p: 2 }}>
        <List>
          {items.map((item) => {
            if (
              !item.allowRull
              || (item.allowRull && item.allowRull?.includes(user?.role))
            ) {
              return (
                <NavItem
                  sx={{ paddingTop: '15px' }}
                  href={item.href}
                  key={item.id}
                  title={item.title}
                  icon={item.icon}
                />
              );
            }
            return <div key={item.id}> </div>;
          })}
        </List>
      </Box>
      <Box sx={{ flexGrow: 1 }} />
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="right"
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
          PaperProps={{
            sx: {
              width: 256
            }
          }}
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden lgDown>
        <Drawer
          anchor="right"
          open
          variant="persistent"
          PaperProps={{
            sx: {
              width: 150,
              top: 64,
              height: 'calc(100% - 64px)'
            }
          }}
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  );
};

DashboardSidebar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool
};

DashboardSidebar.defaultProps = {
  onMobileClose: () => {},
  openMobile: false
};

export default DashboardSidebar;
