import React from 'react';
import { Toolbar, Typography } from '@material-ui/core';

const Footer = () => (
  <Toolbar
    style={{
      justifyContent: 'center',
      backgroundColor: '#0064a7',
      height: '30px',
      minHeight: 'auto',
      fontFamily: ['sans-serif']
    }}
  >
    <Typography color="white" variant="caption">
      ©2023 Copyright: MatanSolutions
    </Typography>
  </Toolbar>
);

export default Footer;
