import { Helmet } from 'react-helmet';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Container, Grid } from '@material-ui/core';
import axios from 'axios';
import Cookies from 'universal-cookie';
import CircularLoading from 'src/components/helpers/Loading';
import LatestOrders from '../components/dashboard/LatestOrders';
// import Budget from '../components/dashboard/Budget';
// import LatestProducts from '../components/dashboard/LatestProducts';
import Sales from '../components/dashboard/Sales';
// import TasksProgress from '../components/dashboard/TasksProgress';
// import TotalCustomers from '../components/dashboard/TotalCustomers';
// import TotalProfit from '../components/dashboard/TotalProfit';
import TrafficByDevice from '../components/dashboard/TrafficByDevice';
import useAuth from '../hooks/useAuth';

const Dashboard = () => {
  const [isLoading, setLoading] = useState(true);
  const [isLoading2, setLoading2] = useState(true);
  const [isLoading3, setLoading3] = useState(true);
  // const [invoicing, setInvoicing] = useState([]);
  const [thisYear, setThisYear] = useState([]);
  const [thisYearForStation, setThisYearForStation] = useState([]);
  const [lastYear, setLastYear] = useState([]);
  const [lastYearForStation, setLastYearForStation] = useState([]);
  const [stations, setStations] = useState([]);
  const [stationName, setStationName] = useState('');
  const [stationName2, setStationName2] = useState('');
  const [thisYearSamePeriodForStation, setThisYearSamePeriodForStation] = useState([]);
  const [thisLastSamePeriodForStation, setLastYearSamePeriodForStation] = useState([]);
  const [missingInvocing, setMissingInvocing] = useState([]);

  const [samePeriodPrevYear, setSamePeriodPrevYear] = useState([]);

  const [areaChar, setAreachar] = useState([]);
  const today = new Date();
  const { auth, setAuth } = useAuth();
  const navigate = useNavigate();

  const handelReturnLogin = () => {
    const cookies = new Cookies();
    cookies.set('Auth', {}, { path: '/' });
    setAuth(cookies.get('Auth'));
    navigate('/login', { replace: true });
  };

  const getInvoices = async () => {
    const getInvoicesUrl = `${process.env.REACT_APP_BASE_URL}/invoicing?fetch_all=true`;
    await axios
      .get(getInvoicesUrl, {
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
          Authorization: `Bearer ${auth.accessToken}`
        }
      })
      .then((response) => {
        if (response?.data?.result && response.data?.status_code === 1) {
          let thisYeartmp = {
            0: 0,
            1: 0,
            2: 0,
            3: 0,
            4: 0,
            5: 0,
            6: 0,
            7: 0,
            8: 0,
            9: 0,
            10: 0,
            11: 0
          };
          let lastYeartmp = {
            0: 0,
            1: 0,
            2: 0,
            3: 0,
            4: 0,
            5: 0,
            6: 0,
            7: 0,
            8: 0,
            9: 0,
            10: 0,
            11: 0
          };

          response?.data?.result.forEach((item) => {
            let thisDate = new Date(item.to_period);

            if (thisDate.getFullYear() === today.getFullYear()) {
              thisYeartmp[thisDate.getMonth()] += item?.amount_paid_without_vat;
            }
            if (thisDate.getFullYear() === today.getFullYear() - 1) {
              lastYeartmp[thisDate.getMonth()] += item?.amount_paid_without_vat;
            }
          });
          setLastYear(lastYeartmp);
          setThisYear(thisYeartmp);
          return response?.data?.result;
        }
        return [];
      })
      .catch((err) => {
        if (err?.response?.status === 403) {
          handelReturnLogin();
        }
        return false;
      })
      .finally(() => {
        setLoading2(false);
      });
  };

  const getInvoicesForStation = async (sta, stationNumber) => {
    setStationName(sta);
    const getInvoicesUrl = `${process.env.REACT_APP_BASE_URL}/invoicing?station_number=${stationNumber}`;

    await axios
      .get(getInvoicesUrl, {
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
          Authorization: `Bearer ${auth.accessToken}`
        }
      })
      .then((response) => {
        if (response?.data?.result && response.data?.status_code === 1) {
          let thisYeartmp = {
            0: 0,
            1: 0,
            2: 0,
            3: 0,
            4: 0,
            5: 0,
            6: 0,
            7: 0,
            8: 0,
            9: 0,
            10: 0,
            11: 0
          };
          let lastYeartmp = {
            0: 0,
            1: 0,
            2: 0,
            3: 0,
            4: 0,
            5: 0,
            6: 0,
            7: 0,
            8: 0,
            9: 0,
            10: 0,
            11: 0
          };
          response?.data?.result.forEach((item) => {
            let thisDate = new Date(item.to_period);

            if (thisDate.getFullYear() === today.getFullYear()) {
              thisYeartmp[thisDate.getMonth()] += item?.amount_paid_without_vat;
            }
            if (thisDate.getFullYear() === today.getFullYear() - 1) {
              lastYeartmp[thisDate.getMonth()] += item?.amount_paid_without_vat;
            }
          });
          setLastYearForStation(lastYeartmp);
          setThisYearForStation(thisYeartmp);
          return response?.data?.result;
        }
        return [];
      })
      .catch((err) => {
        if (err?.response?.status === 403) {
          handelReturnLogin();
        }
        return false;
      })
      .finally(() => {});
  };
  const getSamePeriodPrevYearForStation = async (sta, stationNumber) => {
    setStationName2(sta);
    const getInvoicesUrl = `${process.env.REACT_APP_BASE_URL}/station?is_view=true&station_number=${stationNumber}`;

    await axios
      .get(getInvoicesUrl, {
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
          Authorization: `Bearer ${auth.accessToken}`
        }
      })
      .then((response) => {
        if (response?.data?.result && response.data?.status_code === 1) {
          let thisYeartmp = {
            0: 0,
            1: 0,
            2: 0,
            3: 0,
            4: 0,
            5: 0,
            6: 0,
            7: 0,
            8: 0,
            9: 0,
            10: 0,
            11: 0
          };
          let lastYeartmp = {
            0: 0,
            1: 0,
            2: 0,
            3: 0,
            4: 0,
            5: 0,
            6: 0,
            7: 0,
            8: 0,
            9: 0,
            10: 0,
            11: 0
          };

          response?.data?.result.forEach((item) => {
            thisYeartmp[Number(item.c_period.split('/')[0]) - 1] = item?.d_amount_current_year;
            lastYeartmp[Number(item.c_period.split('/')[0]) - 1] = item?.e_amount_prev_year;
          });
          setLastYearSamePeriodForStation(lastYeartmp);
          setThisYearSamePeriodForStation(thisYeartmp);
          return response?.data?.result;
        }
        return [];
      })
      .catch((err) => {
        if (err?.response?.status === 403) {
          handelReturnLogin();
        }
        return false;
      })
      .finally(() => {});
  };

  const getDashboard = async () => {
    const getUrl = `${process.env.REACT_APP_BASE_URL}/dashboard`;

    await axios
      .get(getUrl, {
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
          Authorization: `Bearer ${auth.accessToken}`
        }
      })
      .then((response) => {
        if (response?.data?.result && response.data?.status_code === 1) {
          if (response?.data?.result?.table?.missing_inv) {
            setMissingInvocing(
              response?.data?.result?.table?.missing_inv.map((invoice) => [
                invoice?.station_name,
                invoice.water_meter,
                invoice.missing_inv,
                invoice.count,
              ])
            );
          }
          setSamePeriodPrevYear(
            response?.data?.result?.table?.same_period_in_previous_year.map((station) => [
              station.a_station_name,
              station.b_station_number,
              station.c_period,
              station.d_amount_current_year,
              station.e_amount_prev_year,
              station.f_percentage_diff,
              station.g_sum_diff
            ])
          );
          const stationRes = response?.data?.result?.table?.station;
          setStations(
            stationRes.map((station) => [
              station.station_name,
              station.station_number,
              station.max_last_period,
              station.sum_of_amount,
              station.sum_this_year
            ])
          );
          return response?.data?.result;
        }
        return [];
      })
      .catch((err) => {
        if (err?.response?.status === 403) {
          handelReturnLogin();
        }
        return false;
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getStations = async () => {
    const getStationUrl = `${process.env.REACT_APP_BASE_URL}/station?fetch_all=true`;

    await axios
      .get(getStationUrl, {
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
          Authorization: `Bearer ${auth.accessToken}`
        }
      })
      .then((response) => {
        if (response?.data?.result && response.data?.status_code === 1) {
          let area = {
            מרכז: 0,
            צפון: 0,
            דרום: 0,
            'ירושלים והסביבה': 0,
            '': 0
          };
          response?.data?.result.forEach((item) => {
            area[item?.area] += 1;
          });
          setAreachar(area);
          return response?.data?.result;
        }
        return [];
      })
      .catch((err) => {
        if (err?.response?.status === 403) {
          handelReturnLogin();
        }
        return false;
      })
      .finally(() => {
        setLoading3(false);
      });
  };

  useEffect(() => {
    getDashboard();
    getInvoices();
    getStations();
  }, []);

  if (isLoading || isLoading2 || isLoading3) {
    // if (isLoading || isLoading2 || isLoading3) {
    return (
      <div>
        <CircularLoading />
      </div>
    );
  }

  return (
    <>
      <Helmet>
        <title>מערכת חשבוניות</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 3
        }}
      >
        <Container maxWidth={false}>
          <Grid container spacing={3}>
            {/* <Grid
            item
            lg={3}
            sm={6}
            xl={3}
            xs={12}
          >
            <Budget />
          </Grid>
          <Grid
            item
            lg={3}
            sm={6}
            xl={3}
            xs={12}
          >
            <TotalCustomers />
          </Grid>
          <Grid
            item
            lg={3}
            sm={6}
            xl={3}
            xs={12}
          >
            <TasksProgress />
          </Grid>
          <Grid
            item
            lg={3}
            sm={6}
            xl={3}
            xs={12}
          >
            <TotalProfit sx={{ height: '100%' }} />
          </Grid>
            */}
            <Grid item lg={4} md={6} xl={4} xs={12}>
              <TrafficByDevice sx={{ height: '100%' }} dataValue={areaChar} />
            </Grid>
            <Grid item lg={4} md={6} xl={4} xs={12}>
              <Sales
                thisyear={thisYear}
                lastyear={lastYear}
                datasetslable1={today.getFullYear()}
                datasetslable2={today.getFullYear() - 1}
                titlecard=""
              />
            </Grid>
            <Grid item lg={4} md={6} xl={4} xs={12}>
              <LatestOrders
                title="דוח חשבוניות חסרות"
                columns={[
                  'תחנה',
                  'מונה',
                  'תקופה חסרה',
                  'כמות חשבוניות חסרות'
                ]}
                rows={missingInvocing}
                linkTo="../MisiingInvoicing"
              />
            </Grid>
            <Grid item lg={8} md={6} xl={8} xs={12}>
              <LatestOrders
                title="דוח סכומי חשבוניות לפי תחנות  - מיון מהסכום הגבוה לנמוך"
                columns={[
                  'שם תחנה',
                  'מספר תחנה',
                  'תקופה אחרונה',
                  'נוכחי',
                  'מצטבר מתחילת שנה',
                ]}
                rows={stations}
                linkTo=""
                getDataForStation={(sta, stationNumber) => getInvoicesForStation(sta, stationNumber)}
              />
            </Grid>
            <Grid item lg={4} md={6} xl={4} xs={12}>
              {stationName !== '' ? (
                <Sales
                  thisyear={thisYearForStation}
                  lastyear={lastYearForStation}
                  datasetslable1={today.getFullYear()}
                  datasetslable2={today.getFullYear() - 1}
                  titlecard={`עבור תחנה: ${stationName}`}
                />
              ) : (
                <></>
              )}
            </Grid>
            <Grid item lg={8} md={6} xl={8} xs={12}>
              <LatestOrders
                title="דוח שינויים לעומת תקופה זהה בשנה קודמת"
                linkTo=""
                columns={[
                  'שם תחנה',
                  'מספר תחנה',
                  'תקופה אחרונה',
                  'סכום שנה נוכחית',
                  'סכום שנה קודמת',
                  'הפרש ב- %',
                  'הפרש בסכום'
                ]}
                rows={samePeriodPrevYear}
                getDataForStation={(sta, stationNumber) => getSamePeriodPrevYearForStation(sta, stationNumber)}
              />
            </Grid>
            <Grid item lg={4} md={6} xl={4} xs={12}>
              {stationName2 !== '' ? (
                <Sales
                  thisyear={thisYearSamePeriodForStation}
                  lastyear={thisLastSamePeriodForStation}
                  datasetslable1={today.getFullYear()}
                  datasetslable2={today.getFullYear() - 1}
                  title={`דוח שינויים לעומת תקופה זהה בשנה קודמת עבור תחנה: ${stationName2}`}
                />
              ) : (
                <></>
              )}
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  );
};

export default Dashboard;
