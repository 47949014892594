import {
  DataGrid,
  GridToolbar,
  heIL as heILCore,
  gridPaginatedVisibleSortedGridRowIdsSelector,
  // gridSortedRowIdsSelector,
  GridToolbarContainer,
  gridVisibleSortedRowIdsSelector,
  useGridApiContext,
  GridCsvGetRowsToExportParams,
  GridCsvExportOptions,
  // CsvExport,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarQuickFilter
} from '@mui/x-data-grid';
import { Card, Box, Typography } from '@material-ui/core';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { heIL } from '@mui/material/locale';
// import { heIL as pickersheIL } from '@mui/x-date-pickers';
import Button, { ButtonProps } from '@mui/material/Button';
import { createSvgIcon } from '@mui/material/utils';

const getRowsFromCurrentPage = ({ apiRef }: GridCsvGetRowsToExportParams) => gridPaginatedVisibleSortedGridRowIdsSelector(apiRef);

// const getUnfilteredRows = ({ apiRef }: GridCsvGetRowsToExportParams) =>
//   gridSortedRowIdsSelector(apiRef);

const getFilteredRows = ({ apiRef }: GridCsvGetRowsToExportParams) => gridVisibleSortedRowIdsSelector(apiRef);

const ExportIcon = createSvgIcon(
  <path d="M19 12v7H5v-7H3v7c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2v-7h-2zm-6 .67l2.59-2.58L17 11.5l-5 5-5-5 1.41-1.41L11 12.67V3h2z" />,
  'SaveAlt'
);

function CustomToolbar() {
  const apiRef = useGridApiContext();

  const handleExport = (options: GridCsvExportOptions) => apiRef.current.exportDataAsCsv(options);

  const buttonBaseProps: ButtonProps = {
    color: 'primary',
    size: 'small',
    startIcon: <ExportIcon />
  };

  return (
    <GridToolbarContainer>
      {/* <div> */}
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />
      <Button
        {...buttonBaseProps}
        onClick={() => handleExport({ getRowsToExport: getRowsFromCurrentPage })}
      >
        עמוד נוכחי
      </Button>
      <Button
        {...buttonBaseProps}
        onClick={() => handleExport({ getRowsToExport: getFilteredRows })}
      >
        הורדה
      </Button>
      {/* <Button
        {...buttonBaseProps}
        onClick={() => handleExport({ getRowsToExport: getUnfilteredRows })}
      >
        הכל
      </Button> */}
      <div
        style={{ display: 'flex', alignItems: 'center', marginLeft: 'auto' }}
      >
        <GridToolbarQuickFilter />
      </div>
    </GridToolbarContainer>
  );
}

const BasicTable = ({ rows, columns, title, ...rest }) => {
  const localizedTextsMap = {
    toolbarFilters: 'סינון',
    toolbarExport: 'הורדה',
    noRowsLabel: 'אין תוצאות',
    // Quick filter toolbar field
    toolbarQuickFilterPlaceholder: 'חיפוש...',
    toolbarQuickFilterLabel: 'חיפוש',
    toolbarQuickFilterDeleteIconLabel: 'נקה',
    // Filter panel text
    filterPanelAddFilter: 'Add filter',
    filterPanelDeleteIconLabel: 'Delete',
    filterPanelLinkOperator: 'Logic operator',
    filterPanelOperator: 'מפעיל',
    filterPanelOperatorAnd: 'And',
    filterPanelOperatorOr: 'Or',
    filterPanelColumns: 'שדה',
    filterPanelInputLabel: 'ערך',
    filterPanelInputPlaceholder: 'ערך לסינון',
    // Filter operators text
    filterOperatorContains: 'מכיל',
    filterOperatorEquals: 'שווה',
    filterOperatorStartsWith: 'מתחיל ב',
    filterOperatorEndsWith: 'נגמר ב',
    filterOperatorIs: 'is',
    filterOperatorNot: 'is not',
    filterOperatorAfter: 'is after',
    filterOperatorOnOrAfter: 'is on or after',
    filterOperatorBefore: 'is before',
    filterOperatorOnOrBefore: 'is on or before',
    filterOperatorIsEmpty: 'ריק',
    filterOperatorIsNotEmpty: 'לא ריק',
    filterOperatorIsAnyOf: 'חלק מ'
  };
  const themeB = createTheme(heIL, heILCore);

  return (
    <ThemeProvider theme={themeB}>
      <Card {...rest}>
        <Box
          sx={{ height: window.innerHeight - 200, width: 1, direction: 'ltr' }}
        >
          <Typography
            color="textPrimary"
            gutterBottom
            variant="h5"
            align="center"
            sx={{ fontFamily: ['sans-serif'], marginTop: '10px' }}
          >
            {title}
          </Typography>
          <DataGrid
            rows={rows}
            columns={columns}
            components={{ k: GridToolbar, Toolbar: CustomToolbar }}
            // components={{ Toolbar: CustomToolbar }}
            density="compact"
            localeText={localizedTextsMap}
            sx={{
              border: 0,
              fontFamily: ['sans-serif'],
              height: window.innerHeight - 239
            }}
            // labelRowsPerPage="Your text"
            componentsProps={{
              columnMenu: {
                sx: {
                  /* style allied to the column menu */
                }
              },
              toolbar: {
                showQuickFilter: true
              },
              filterPanel: {
                columnsSort: 'asc',
                filterFormProps: {
                  // Customize inputs by passing props
                  linkOperatorInputProps: {
                    variant: 'outlined',
                    size: 'small'
                  },
                  columnInputProps: {
                    variant: 'outlined',
                    size: 'small',
                    sx: { mt: 'auto' }
                  },
                  operatorInputProps: {
                    variant: 'outlined',
                    size: 'small',
                    sx: { mt: 'auto' }
                  },
                  valueInputProps: {
                    InputComponentProps: {
                      variant: 'outlined',
                      size: 'small'
                    }
                  },
                  deleteIconProps: {
                    sx: {
                      '& .MuiSvgIcon-root': { color: '#d32f2f' }
                    }
                  }
                },
                sx: {
                  // Customize inputs using css selectors
                  '& .MuiDataGrid-filterForm': { p: 2 },
                  '& .MuiDataGrid-filterForm:nth-child(even)': {
                    backgroundColor: (theme) => {
                      if (theme.palette.mode === 'dark') return '#444';
                      return '#f5f5f5';
                    }
                  },
                  '& .MuiDataGrid-filterFormLinkOperatorInput': { mr: 2 },
                  '& .MuiDataGrid-filterFormColumnInput': { mr: 2, width: 150 },
                  '& .MuiDataGrid-filterFormOperatorInput': { mr: 2 },
                  '& .MuiDataGrid-filterFormValueInput': { width: 200 }
                }
              }
            }}
            // disableColumnFilter
            // disableColumnSelector
            // disableExtendRowFullWidth
            //   initialState={{
            //     filter: {
            //       filterModel: {
            //         items: [{ columnField: 'name', operatorValue: 'contains', value: 'matan' }],
            //       },
            //     },
            //   }}
          />

          {/* </div> */}
        </Box>
      </Card>
    </ThemeProvider>
  );
};

export default BasicTable;
