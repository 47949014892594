import TextField from '@mui/material/TextField';
import DialogContent from '@mui/material/DialogContent';
import { useState } from 'react';
import { Box, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { Plus as PlusIcon } from 'react-feather';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import axios from 'axios';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import CircularLoading from 'src/components/helpers/Loading';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles(() => ({
  shrink: {
    transformOrigin: 'top right',
    right: 28
  }
}));

const AddOperator = ({
  buttonText,
  accessToken,
  handelReturnLogin,
  setQuery,
  ...props
}) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [isLoading, setLoading] = useState(false);

  const [snackbarMss, setSnackbarMss] = useState('');
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [errMsg, setErrMsg] = useState('');

  const [operatorName, setOperatorName] = useState('');
  const [operatorId, setOperatorId] = useState(0);
  const [operatorNameError, setOperatorNameError] = useState('');
  const [operatorIdError, setOperatorIdError] = useState('');

  const handleClickOpen = () => {
    setOpen(true);
    setOpenSnackbar(false);
  };

  const handleClose = () => {
    setOpen(false);
    setTimeout(() => {
      // After 5 seconds set the show value to false
      setSnackbarMss('');
      setOpenSnackbar(false);
    }, 5000);
  };

  const handleSubmit = async () => {
    if (!operatorName) {
      setOperatorNameError('שדה חובה');
    }
    if (!operatorId) {
      setOperatorIdError('שדה חובה');
    }
    if (!operatorName || !operatorId) {
      return;
    }

    setLoading(true);
    const getOperatorUrl = `${process.env.REACT_APP_BASE_URL}/operator`;

    await axios
      .post(
        getOperatorUrl,
        JSON.stringify({
          operator_name: operatorName,
          operator_id: Number(operatorId)
        }),
        {
          headers: {
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
            Authorization: `Bearer ${accessToken}`
          }
        }
      )
      .then((response) => {
        if (response?.status === 201) {
          setSnackbarMss('החברה נוספה בהצלחה');
          setOpenSnackbar(true);
          setQuery([]);
          handleClose();
          setOperatorName('');
          setOperatorId(0);
        }

        if (response?.data?.result && response.data?.status_code === 0) {
          setErrMsg(response?.data?.result);
        }
      })
      .catch((err) => {
        setErrMsg(err?.message);
        if (err?.response?.status === 403) {
          handelReturnLogin();
        }
        return false;
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnackbar(false);
  };

  return (
    <Box {...props}>
      <Box
        component="form"
        sx={{
          display: 'flex',
          justifyContent: 'flex-start'
        }}
      >
        <Button variant="contained" color="primary" onClick={handleClickOpen}>
          <PlusIcon />
          {buttonText}
        </Button>
        <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          open={openSnackbar}
          onClose={handleCloseSnackbar}
          message={snackbarMss}
          key={{ vertical: 'top', horizontal: 'center' }}
        >
          <Stack sx={{ width: '100%' }} spacing={2}>
            <Alert variant="filled" severity="success">
              {snackbarMss}
            </Alert>
          </Stack>
        </Snackbar>
        <Dialog open={open} onClose={handleClose}>
          {isLoading ? (
            <Box
              sx={{
                display: 'grid',
                paddingBottom: 6,
                paddingTop: 2,
                gridTemplateColumns: { sm: '2fr 1fr' },
                gap: 3
              }}
            >
              <CircularLoading />
            </Box>
          ) : (
            <>
              <DialogTitle
                sx={{
                  p: 2,
                  textAlign: 'center',
                  fontSize: 30
                }}
              >
                <Box display="flex" alignItems="center">
                  <Box>
                    <IconButton onClick={handleClose}>
                      <CloseIcon />
                    </IconButton>
                  </Box>
                  <Box flexGrow={1}>הוספת חברה</Box>
                </Box>
              </DialogTitle>
              <DialogContent>
                <Box
                  sx={{
                    display: 'grid',
                    paddingBottom: 2,
                    paddingTop: 2,
                    gridTemplateColumns: { sm: '2fr 1fr' },
                    gap: 3
                  }}
                >
                  <TextField
                    id="operatorName"
                    required
                    label="שם החברה"
                    variant="outlined"
                    value={operatorName}
                    onChange={(event) => {
                      setOperatorNameError('');
                      setOperatorName(event.target.value);
                    }}
                    helperText={operatorNameError}
                    error={!!operatorNameError}
                    InputLabelProps={{
                      shrink: true,
                      classes: {
                        root: classes.labelRoot,
                        shrink: classes.shrink
                      }
                    }}
                  />
                  <TextField
                    id="operatorId"
                    required
                    label="מספר חברה"
                    type="number"
                    variant="outlined"
                    value={operatorId}
                    onChange={(event) => {
                      setOperatorIdError('');
                      setOperatorId(event.target.value);
                    }}
                    helperText={operatorIdError}
                    error={!!operatorIdError}
                    InputLabelProps={{
                      shrink: true,
                      classes: {
                        root: classes.labelRoot,
                        shrink: classes.shrink
                      }
                    }}
                  />
                </Box>
              </DialogContent>
              <p className="errorMsg">{errMsg}</p>
              <DialogActions
                sx={{
                  justifyContent: 'center'
                }}
              >
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleSubmit}
                >
                  הוסף
                </Button>
              </DialogActions>
            </>
          )}
        </Dialog>
      </Box>
    </Box>
  );
};

export default AddOperator;
