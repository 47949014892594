import { Helmet } from 'react-helmet';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Container } from '@material-ui/core';
import { GridRowsProp, GridColDef } from '@mui/x-data-grid';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import axios from 'axios';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import Cookies from 'universal-cookie';
import CircularLoading from 'src/components/helpers/Loading';
import BasicTable from '../components/helpers/BasicTable';
import useAuth from '../hooks/useAuth';
import AddStation from '../components/stations/AddStation';
import ViewStation from '../components/stations/ViewStation';
import EditStation from '../components/stations/EditStation';
import AlertDialog from '../components/helpers/AlertPopup';

const Stations = () => {
  const navigate = useNavigate();
  const { auth, setAuth } = useAuth();
  // const thisRow = params.row;
  const [snackbarMss, setSnackbarMss] = useState('');
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [query, setQuery] = useState([]);
  const [corporations, setCorporations] = useState([]);

  const [isLoading2, setLoading2] = useState(true);

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };
  const handelReturnLogin = () => {
    const cookies = new Cookies();
    cookies.set('Auth', {}, { path: '/' });
    setAuth(cookies.get('Auth'));
    navigate('/login', { replace: true });
  };

  const [openView, setOpenView] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [open, setOpen] = useState(false);
  const [errorMsg, setErrMsg] = useState(false);
  const [isLoadingDelete, setLoadingDelete] = useState(false);
  const [stationNumber, setStationNumber] = useState('');

  const [rowData, setRowData] = useState(false);
  const handleCloseEdit = () => {
    setOpenEdit(false);
    setRowData();
  };
  const handleCloseView = () => {
    setOpenView(false);
  };

  const handleCloseDelete = () => {
    setOpen(false);
  };

  const deleteStation = async () => {
    const getStationsUrl = `${process.env.REACT_APP_BASE_URL}/station?station_number=${stationNumber}`;
    setLoadingDelete(true);
    await axios
      .delete(getStationsUrl, {
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
          Authorization: `Bearer ${auth.accessToken}`
        }
      })
      .then((response) => {
        if (response?.status === 200 && response.data?.status_code === 1) {
          setSnackbarMss('התחנה נמחקה בהצלחה');
          setQuery([]);
          setOpenSnackbar(true);
          handleCloseDelete();
          return true;
        }
        if (response?.data?.result && response.data?.status_code === 0) {
          setErrMsg(response?.data?.result);
          return false;
        }
        setErrMsg(response?.data?.result);
        return false;
      })
      .catch((err) => {
        if (err?.response?.status === 403) {
          handelReturnLogin();
        }
        setErrMsg(err?.message);
        return false;
      })
      .finally(() => {
        setLoadingDelete(false);
        return false;
      });
  };

  const columns: GridColDef[] = [
    {
      field: 'delete',
      headerName: '',
      flex: 0.2,
      sortable: false,
      renderCell: (params) => {
        const handleClickOpen = () => {
          setStationNumber(params.row.station_number);
          setOpen(true);
        };

        return (
          <>
            <DeleteIcon onClick={handleClickOpen} />
          </>
        );
      }
    },
    {
      field: 'edit',
      headerName: '',
      flex: 0.2,
      sortable: false,
      renderCell: (params) => {
        const handleClickOpenEdit = () => {
          setRowData(params.row);
          setOpenEdit(true);
        };
        return (
          <>
            <EditIcon onClick={handleClickOpenEdit} />
          </>
        );
      }
    },
    {
      field: 'id',
      headerName: 'מספר מזהה',
      sortable: false,
      hide: true
    },
    {
      field: 'remarks',
      headerName: 'הערות',
      sortable: false,
      hide: true
    },
    {
      field: 'address',
      headerName: 'מיקום',
      flex: 1.5
    },
    { field: 'area', flex: 1, headerName: 'אזור' },

    { field: 'water_corporation', flex: 1, headerName: 'תאגיד מים' },
    { field: 'water_meter', flex: 1, headerName: 'מד מים' },
    { field: 'property_id', flex: 1, headerName: 'מספר נכס' },
    { field: 'station_name', flex: 1, headerName: 'שם תחנה' },
    { field: 'station_number', flex: 1, headerName: 'מספר תחנה' },
    {
      field: 'view',
      headerName: '',
      flex: 0.2,
      sortable: false,
      renderCell: (params) => {
        // const thisRow = params.row;
        const handleClickOpen = () => {
          setRowData(params.row);
          setOpenView(true);
        };
        return (
          <>
            <OpenInNewIcon onClick={handleClickOpen} />
          </>
        );
      }
    }
  ];
  const [isLoading, setLoading] = useState(true);
  const [rows, setRows] = useState([]);

  const getStations = async (accessToken) => {
    const getStationsUrl = `${process.env.REACT_APP_BASE_URL}/station?fetch_all=true`;

    await axios
      .get(getStationsUrl, {
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
          Authorization: `Bearer ${accessToken}`
        }
      })
      .then((response) => {
        if (response?.data?.result && response.data?.status_code === 1) {
          const tmpRows: GridRowsProp = response?.data?.result;
          setRows(tmpRows);
          return tmpRows;
        }
        const tmpRows: GridRowsProp = [];
        setRows(tmpRows);
        return tmpRows;
      })
      .catch((err) => {
        if (err?.response?.status === 403) {
          handelReturnLogin();
        }
        return false;
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const getCorporations = async (accessToken) => {
    const getInvoicesUrl = `${process.env.REACT_APP_BASE_URL}/provider?fetch_all=true`;

    await axios
      .get(getInvoicesUrl, {
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
          Authorization: `Bearer ${accessToken}`
        }
      })
      .then((response) => {
        if (response?.data?.result && response.data?.status_code === 1) {
          setCorporations(response?.data?.result);
          return response?.data?.result;
        }
        return [];
      })
      .catch((err) => {
        if (err?.response?.status === 403) {
          handelReturnLogin();
        }
        return false;
      })
      .finally(() => {
        setLoading2(false);
      });
  };

  useEffect(() => {
    getStations(auth.accessToken);
    getCorporations(auth.accessToken);
  }, [query]);

  if (isLoading && isLoading2) {
    return (
      <div>
        <CircularLoading />
      </div>
    );
  }

  return (
    <>
      <Helmet>
        <title>תחנות</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 3
        }}
      >
        <Container maxWidth={false}>
          <AddStation
            buttonText="הוסף תחנה חדשה"
            accessToken={auth.accessToken}
            handelReturnLogin={handelReturnLogin}
            setQuery={setQuery}
            corporations={corporations}
          />
          <Snackbar
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            open={openSnackbar}
            onClose={handleCloseSnackbar}
            message={snackbarMss}
            key={{ vertical: 'top', horizontal: 'center' }}
          >
            <Stack sx={{ width: '100%' }} spacing={2}>
              <Alert variant="filled" severity="success">
                {snackbarMss}
              </Alert>
            </Stack>
          </Snackbar>
          <EditStation
            rowData={rowData}
            handleClose={handleCloseEdit}
            open={openEdit}
            handelReturnLogin={handelReturnLogin}
            accessToken={auth.accessToken}
            setOpenSnackbar={setOpenSnackbar}
            setSnackbarMss={setSnackbarMss}
            setQuery={setQuery}
          />
          <ViewStation
            rowData={rowData}
            handleClose={handleCloseView}
            open={openView}
          />
          <AlertDialog
            open={open}
            handleClose={handleCloseDelete}
            dialogTitle={`האם אתה בטוח שברצונך למחוק את תחנת ${stationNumber}?`}
            handleSubmit={deleteStation}
            errorMsg={errorMsg}
            isLoading={isLoadingDelete}
          />
          <Box sx={{ pt: 3 }}>
            <BasicTable rows={rows} columns={columns} title="תחנות" />
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default Stations;
