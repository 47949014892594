import DialogContent from '@mui/material/DialogContent';
import { Box } from '@material-ui/core';
import Dialog from '@mui/material/Dialog';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@material-ui/core/Grid';
import moment from 'moment';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import PdfIcon from './ShowPdf';

const ViewInvoice = ({ rowData, handleClose, open, accessToken, handelReturnLogin, ...props }) => (
  <Box {...props}>
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth="md">
      <DialogTitle
        sx={{
          p: 2,
          textAlign: 'center',
          fontSize: 30
        }}
      >
        <Box display="flex" alignItems="center">
          <Box>
            <IconButton onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </Box>
          <Box flexGrow={1}>{`חשבונית ${rowData.invoicing_id}`}</Box>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Grid>
          <PdfIcon pdfName={`חשבונית ${rowData.invoicing_id}`} invoicingId={rowData.invoicing_id} accessToken={accessToken} handelReturnLogin={handelReturnLogin} />
        </Grid>
        <Grid margin="auto">
          <Grid display="flex" alignItems="flex-end">
            <Grid item xs={4}>
              <DialogContentText>מספר חשבונית:</DialogContentText>
            </Grid>
            <Grid item xs={8}>
              <DialogContentText>
                {`${rowData.invoicing_id ? rowData.invoicing_id : '-'}`}
              </DialogContentText>
            </Grid>
          </Grid>
          <Grid display="flex" alignItems="flex-end">
            <Grid item xs={4}>
              <DialogContentText>שם מאשר:</DialogContentText>
            </Grid>
            <Grid item xs={8}>
              <DialogContentText>
                {`${rowData.first_name ? rowData.first_name : ''} `}
                {`${
                  rowData.last_name || rowData.first_name
                    ? rowData.last_name
                    : '-'
                }`}
              </DialogContentText>
            </Grid>
          </Grid>
          <Grid display="flex" alignItems="flex-end">
            <Grid item xs={4}>
              <DialogContentText>שם חברה:</DialogContentText>
            </Grid>
            <Grid item xs={8}>
              <DialogContentText>
                {`${rowData.operator_name ? rowData.operator_name : '-'}`}
              </DialogContentText>
            </Grid>
          </Grid>
          <Grid display="flex" alignItems="flex-end">
            <Grid item xs={4}>
              <DialogContentText>מתאריך:</DialogContentText>
            </Grid>
            <Grid item xs={8}>
              <DialogContentText>
                {`${rowData.from_period ? moment(rowData.from_period).format('DD-MM-YYYY') : '-'}`}
              </DialogContentText>
            </Grid>
          </Grid>
          <Grid display="flex" alignItems="flex-end">
            <Grid item xs={4}>
              <DialogContentText>עד תאריך:</DialogContentText>
            </Grid>
            <Grid item xs={8}>
              <DialogContentText>
                {`${
                  rowData.to_period
                    ? moment(rowData.to_period).format('DD-MM-YYYY')
                    : '-'
                }`}
              </DialogContentText>
            </Grid>
          </Grid>
          <Grid display="flex" alignItems="flex-end">
            <Grid item xs={4}>
              <DialogContentText>ח.פ:</DialogContentText>
            </Grid>
            <Grid item xs={8}>
              <DialogContentText>
                {`${rowData.hp ? rowData.hp : '-'}`}
              </DialogContentText>
            </Grid>
          </Grid>
          <Grid display="flex" alignItems="flex-end">
            <Grid item xs={4}>
              <DialogContentText>שם תאגיד:</DialogContentText>
            </Grid>
            <Grid item xs={8}>
              <DialogContentText>
                {`${rowData.corporation_name ? rowData.corporation_name : '-'}`}
              </DialogContentText>
            </Grid>
          </Grid>
          <Grid display="flex" alignItems="flex-end">
            <Grid item xs={4}>
              <DialogContentText>סטטוס:</DialogContentText>
            </Grid>
            <Grid item xs={8}>
              <DialogContentText>
                {`${
                  rowData.active === 1
                    ? 'ממתין לאישור'
                    : rowData.active === 2
                      ? 'אושר'
                      : rowData.active === 3
                        ? 'מבוטל'
                        : '-'
                }`}
              </DialogContentText>
            </Grid>
          </Grid>
          <Grid display="flex" alignItems="flex-end">
            <Grid item xs={4}>
              <DialogContentText>סה״כ לתשלום ללא מע״מ:</DialogContentText>
            </Grid>
            <Grid item xs={8}>
              <DialogContentText>
                {`${
                  rowData.amount_paid_without_vat
                    ? rowData.amount_paid_without_vat
                    : '-'
                }`}
              </DialogContentText>
            </Grid>
          </Grid>
          <Grid display="flex" alignItems="flex-end">
            <Grid item xs={4}>
              <DialogContentText>סה״כ לתשלום כולל מע״מ:</DialogContentText>
            </Grid>
            <Grid item xs={8}>
              <DialogContentText>
                {`${
                  rowData.amount_paid_with_vat
                    ? rowData.amount_paid_with_vat
                    : '-'
                }`}
              </DialogContentText>
            </Grid>
          </Grid>
          <Grid display="flex" alignItems="flex-end">
            <Grid item xs={4}>
              <DialogContentText>חיובים נוספים:</DialogContentText>
            </Grid>
            <Grid item xs={8}>
              <DialogContentText>
                {`${rowData.other_charges ? rowData.other_charges : '-'}`}
              </DialogContentText>
            </Grid>
          </Grid>
          <Grid display="flex" alignItems="flex-end">
            <Grid item xs={4}>
              <DialogContentText>זיכויים:</DialogContentText>
            </Grid>
            <Grid item xs={8}>
              <DialogContentText>
                {`${rowData.refund ? rowData.refund : '-'}`}
              </DialogContentText>
            </Grid>
          </Grid>
          <Grid display="flex" alignItems="flex-end">
            <Grid item xs={4}>
              <DialogContentText>כמות צריכה:</DialogContentText>
            </Grid>
            <Grid item xs={8}>
              <DialogContentText>
                {`${
                  rowData.consumption_amount ? rowData.consumption_amount : '-'
                }`}
              </DialogContentText>
            </Grid>
          </Grid>
          <Grid display="flex" alignItems="flex-end">
            <Grid item xs={4}>
              <DialogContentText>מחיר ליחידה אחת:</DialogContentText>
            </Grid>
            <Grid item xs={8}>
              <DialogContentText>
                {`${
                  rowData.price_for_one_unity ? rowData.price_for_one_unity : '-'
                }`}
              </DialogContentText>
            </Grid>
          </Grid>
          <Grid display="flex" alignItems="flex-end">
            <Grid item xs={4}>
              <DialogContentText>תאריך הדפסה:</DialogContentText>
            </Grid>
            <Grid item xs={8}>
              <DialogContentText>
                {`${
                  rowData.print_date
                    ? moment(rowData.print_date).format('DD-MM-YYYY')
                    : '-'
                }`}
              </DialogContentText>
            </Grid>
          </Grid>
          <Grid display="flex" alignItems="flex-end">
            <Grid item xs={4}>
              <DialogContentText>צד ג׳:</DialogContentText>
            </Grid>
            <Grid item xs={8}>
              <DialogContentText>
                {`${rowData.side_c ? rowData.side_c : '-'}`}
              </DialogContentText>
            </Grid>
          </Grid>
          <Grid display="flex" alignItems="flex-end">
            <Grid item xs={4}>
              <DialogContentText>הערות:</DialogContentText>
            </Grid>
            <Grid item xs={8}>
              <DialogContentText>
                {`${rowData.remarks ? rowData.remarks : '-'}`}
              </DialogContentText>
            </Grid>
          </Grid>
          <Grid display="flex" alignItems="flex-end">
            <Grid item xs={4}>
              <DialogContentText>תאריך עדכון:</DialogContentText>
            </Grid>
            <Grid item xs={8}>
              <DialogContentText>
                {`${
                  rowData.time_stamp
                    ? moment(rowData.time_stamp).format('DD-MM-YYYY')
                    : '-'
                }`}
              </DialogContentText>
            </Grid>
          </Grid>
          <Grid display="flex" alignItems="flex-end">
            <Grid item xs={4}>
              <DialogContentText>מע״מ:</DialogContentText>
            </Grid>
            <Grid item xs={8}>
              <DialogContentText>
                {`${rowData.vat ? rowData.vat : '-'}`}
              </DialogContentText>
            </Grid>
          </Grid>
          <Grid display="flex" alignItems="flex-end">
            <Grid item xs={4}>
              <DialogContentText>מד מים:</DialogContentText>
            </Grid>
            <Grid item xs={8}>
              <DialogContentText>
                {`${rowData.water_meter ? rowData.water_meter : '-'}`}
              </DialogContentText>
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  </Box>
);

export default ViewInvoice;
