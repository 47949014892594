import TextField from '@mui/material/TextField';
import DialogContent from '@mui/material/DialogContent';
import { useState } from 'react';
import { Box, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import axios from 'axios';
import CircularLoading from 'src/components/helpers/Loading';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles(() => ({
  shrink: {
    transformOrigin: 'top right',
    right: 28
  }
}));

const EditOperator = ({
  rowData,
  accessToken,
  handelReturnLogin,
  open,
  handleClose,
  setSnackbarMss,
  setOpenSnackbar,
  setQuery,
  ...props
}) => {
  const classes = useStyles();
  const [isLoading, setLoading] = useState(false);
  const [errMsg, setErrMsg] = useState('');

  const [operatorName, setOperatorName] = useState(rowData.operator_name);
  const [operatorId, setOperatorId] = useState(rowData.operator_id);
  const [operatorNameError, setOperatorNameError] = useState('');
  const [operatorIdError, setOperatorIdError] = useState('');

  const handleSubmit = async () => {
    if (!operatorName) {
      setOperatorNameError('שדה חובה');
    }
    if (!operatorId) {
      setOperatorIdError('שדה חובה');
    }
    if (!operatorName || !operatorId) {
      return;
    }

    setLoading(true);

    const getOperatorUrl = `${process.env.REACT_APP_BASE_URL}/operator`;

    await axios
      .put(
        getOperatorUrl,
        JSON.stringify({
          operator_name: operatorName,
          operator_id: Number(rowData.operator_id),
          new_operator_id: Number(operatorId)
        }),
        {
          headers: {
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
            Authorization: `Bearer ${accessToken}`
          }
        }
      )
      .then((response) => {
        if (response?.status === 200 && response.data?.status_code === 1) {
          setSnackbarMss('נערך בהצלחה');
          setOpenSnackbar(true);
          setQuery([]);
          handleClose();
        }

        if (response?.data?.result && response.data?.status_code === 0) {
          setErrMsg(response?.data?.result);
        }
      })
      .catch((err) => {
        setErrMsg(err?.message);
        if (err?.response?.status === 403) {
          handelReturnLogin();
        }
        return false;
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Box {...props}>
      <Box
        component="form"
        sx={{
          display: 'flex',
          justifyContent: 'flex-start'
        }}
      >
        <Dialog open={open} onClose={handleClose}>
          {isLoading ? (
            <Box
              sx={{
                display: 'grid',
                paddingBottom: 6,
                paddingTop: 2,
                gridTemplateColumns: { sm: '2fr 1fr' },
                gap: 3
                // '& > :not(style)': { m: 1, width: '25ch' }
              }}
            >
              <CircularLoading />
            </Box>
          ) : (
            <>
              <DialogTitle
                sx={{
                  p: 2,
                  textAlign: 'center',
                  fontSize: 30
                }}
              >
                <Box display="flex" alignItems="center">
                  <Box>
                    <IconButton onClick={handleClose}>
                      <CloseIcon />
                    </IconButton>
                  </Box>
                  <Box flexGrow={1}>עריכת חברה</Box>
                </Box>
              </DialogTitle>
              <DialogContent>
                <Box
                  sx={{
                    display: 'grid',
                    paddingBottom: 3,
                    paddingTop: 2,
                    gridTemplateColumns: { sm: '2fr 1fr' },
                    gap: 3
                  }}
                >
                  <TextField
                    id="operatorName"
                    required
                    label="שם החברה"
                    variant="outlined"
                    value={operatorName}
                    onChange={(event) => {
                      setOperatorNameError('');
                      setOperatorName(event.target.value);
                    }}
                    helperText={operatorNameError}
                    error={!!operatorNameError}
                    InputLabelProps={{
                      shrink: true,
                      classes: {
                        root: classes.labelRoot,
                        shrink: classes.shrink
                      }
                    }}
                  />
                  <TextField
                    id="operatorId"
                    required
                    label="מספר חברה"
                    type="number"
                    variant="outlined"
                    value={operatorId}
                    onChange={(event) => {
                      setOperatorIdError('');
                      setOperatorId(event.target.value);
                    }}
                    helperText={operatorIdError}
                    error={!!operatorIdError}
                    InputLabelProps={{
                      shrink: true,
                      classes: {
                        root: classes.labelRoot,
                        shrink: classes.shrink
                      }
                    }}
                  />
                </Box>
              </DialogContent>
              <p className="errorMsg">{errMsg}</p>
              <DialogActions
                sx={{
                  justifyContent: 'center'
                }}
              >
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleSubmit}
                >
                  עדכן
                </Button>
              </DialogActions>
            </>
          )}
        </Dialog>
      </Box>
    </Box>
  );
};

export default EditOperator;
