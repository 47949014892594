import { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
// import { useRef, useState, useEffect, Link as RouterLink } from 'react';
// import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import * as Yup from 'yup';
import { Formik } from 'formik';
import {
  Box,
  Button,
  Container,
  TextField,
  Typography,
  Link
} from '@material-ui/core';
// import Cookies from 'universal-cookie';
import axios from 'axios';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
// import useAuth from '../hooks/useAuth';

const FORGOTPASS_URL = `${process.env.REACT_APP_BASE_URL_LOGIN}/signin/forgot_password`;

const ForgotPass = () => {
  //   const navigate = useNavigate();
  //   const { auth, setAuth } = useAuth();

  const [email, setEmail] = useState('');
  const [errMsg, setErrMsg] = useState('');

  const [snackbarMss, setSnackbarMss] = useState('');
  const [openSnackbar, setOpenSnackbar] = useState(false);

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnackbar(false);
  };

  return (
    <>
      <Helmet>
        <title>שכחתי סיסמא</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          justifyContent: 'center'
        }}
      >
        <Container maxWidth="sm">
          <Formik
            initialValues={{
              email: ''
            }}
            validationSchema={Yup.object().shape({
              email: Yup.string()
                .email('Must be a valid email')
                .max(255)
                .required('Email is required')
            })}
            onSubmit={async (values) => {
              setEmail(values.email);
              try {
                // todo change to foragt pss url
                const response = await axios.post(
                  `${FORGOTPASS_URL}?email=${email}`,
                  {
                    headers: {
                      'Content-Type': 'application/json',
                      'Access-Control-Allow-Origin': '*'
                    }
                  }
                );
                if (response?.data?.status_code === 0) {
                  setErrMsg(response?.data?.result);
                } else {
                  setSnackbarMss(response?.data?.result);
                  setOpenSnackbar(true);
                }
              } catch (err) {
                if (!err?.response) {
                  setErrMsg('No Server Response');
                } else if (err.response?.status === 400) {
                  setErrMsg('Missing Username or Password');
                } else if (err.response?.status === 401) {
                  setErrMsg('Unauthorized');
                } else if (err.response?.status === 404 && err.response?.data?.result) {
                  setErrMsg(err.response?.data?.result);
                } else {
                  setErrMsg('Failed');
                }
              }
            }}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              values
            }) => (
              <form onSubmit={handleSubmit}>
                <Box sx={{ mb: 3 }}>
                  <Typography
                    color="textPrimary"
                    variant="h2"
                    sx={{ textAlign: 'center', margin: '5%' }}
                  >
                    שכחתי סיסמא
                  </Typography>
                  <Typography
                    color="textSecondary"
                    gutterBottom
                    variant="body2"
                    style={{ color: 'red' }}
                    className={errMsg ? 'errmsg' : 'offscreen'}
                    aria-live="assertive"
                  >
                    {errMsg}
                  </Typography>
                </Box>
                <Snackbar
                  anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                  open={openSnackbar}
                  onClose={handleCloseSnackbar}
                  message={snackbarMss}
                  key={{ vertical: 'top', horizontal: 'center' }}
                >
                  <Stack sx={{ width: '100%' }} spacing={2}>
                    <Alert variant="filled" severity="success">
                      {snackbarMss}
                    </Alert>
                  </Stack>
                </Snackbar>
                <TextField
                  error={Boolean(touched.email && errors.email)}
                  fullWidth
                  helperText={touched.email && errors.email}
                  label="כתובת אימייל"
                  margin="normal"
                  name="email"
                  onBlur={handleBlur}
                  onChange={(e) => {
                    // call the built-in handleBur
                    handleChange(e);
                    // and do something about e
                    setEmail(e.currentTarget.value);
                  }}
                  type="email"
                  value={values.email}
                  variant="outlined"
                />
                <Box sx={{ py: 2 }}>
                  <Button
                    color="primary"
                    disabled={isSubmitting}
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                  >
                    שלח מייל לשחזור
                  </Button>
                </Box>
                <Typography color="textSecondary" variant="body1">
                  רוצה להתחבר?
                  <Link
                    component={RouterLink}
                    to="/login"
                    variant="h6"
                    underline="hover"
                  >
                    לחץ כאן
                  </Link>
                </Typography>
              </form>
            )}
          </Formik>
        </Container>
      </Box>
    </>
  );
};

export default ForgotPass;
