import * as React from 'react';
import Button from '@mui/material/Button';
import { Box } from '@material-ui/core';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
// import DialogContent from '@mui/material/DialogContent';
// import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import CircularLoading from 'src/components/helpers/Loading';

const AlertDialog = ({
  open,
  handleClose,
  dialogTitle,
  // dialogText,
  handleSubmit,
  errorMsg,
  isLoading
}) => (
  <div>
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      {isLoading ? (
        <Box
          sx={{
            display: 'grid',
            paddingBottom: 6,
            paddingTop: 2,
            gridTemplateColumns: { sm: '2fr 1fr' },
            gap: 3
            // '& > :not(style)': { m: 1, width: '25ch' }
          }}
        >
          <CircularLoading />
        </Box>
      ) : (
        <>
          <DialogTitle id="alert-dialog-title">{dialogTitle}</DialogTitle>
          {/* <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {dialogText}
            </DialogContentText>
          </DialogContent> */}
          <p className="errorMsg">{errorMsg}</p>
          <DialogActions>
            <Button onClick={handleClose}>ביטול</Button>
            <Button onClick={handleSubmit} autoFocus>
              אישור
            </Button>
          </DialogActions>
        </>
      )}
    </Dialog>
  </div>
);

export default AlertDialog;
