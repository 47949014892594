import TextField from '@mui/material/TextField';
import DialogContent from '@mui/material/DialogContent';
import { useState, useEffect } from 'react';
import { Box, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import Autocomplete from '@mui/material/Autocomplete';
import axios from 'axios';
import moment from 'moment';
import Cookies from 'universal-cookie';
import CircularLoading from 'src/components/helpers/Loading';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles(() => ({
  shrink: {
    transformOrigin: 'top right',
    right: 28
  }
}));

const EditInvoice = ({
  rowData,
  accessToken,
  handelReturnLogin,
  open,
  handleClose,
  setSnackbarMss,
  setOpenSnackbar,
  setQuery,
  corporations,
  ...props
}) => {
  const classes = useStyles();
  const [isLoading, setLoading] = useState(false);
  const [errMsg, setErrMsg] = useState('');
  const [invoicingId, setInvoicingId] = useState(rowData.invoicing_id); // מ.חשבונית
  const [hp, setHp] = useState(rowData.hp); // ח.פ
  const [waterMeter, setWaterMeter] = useState(rowData.water_meter); // מד מים
  const [amountPaidWithoutVat, setAmountPaidWithoutVat] = useState(
    rowData.amount_paid_without_vat
  ); // סגום לתשלום ללא מעמ
  const [amountPaidWithVat, setAmountPaidWithVat] = useState(
    rowData.amount_paid_with_vat
  ); // סגום לתשלום  מעמ
  const [toPeriod, setToPeriod] = useState(
    moment(rowData.to_period).format('DD-MM-YYYY')
  ); // עד תאריך
  const [fromPeriod, setFromPeriod] = useState(
    moment(rowData.from_period).format('DD-MM-YYYY')
  ); // מתאריך
  const [corporationName, setCorporationName] = useState(
    rowData.corporation_name
  ); // שם תאגיד
  const [sideC, setSideC] = useState(rowData.side_c); // צד ג׳
  const [remarks, setRemarks] = useState(rowData.remarks); // הערות
  const [refund, setRefund] = useState(rowData.refund); // זיכויים
  const [printDate, setPrintDate] = useState(
    moment(rowData.print_date).format('DD-MM-YYYY')
  ); // תאריך הדפסה
  const [priceForOneUnity, setPriceForOneUnity] = useState(
    rowData.price_for_one_unity
  ); // מחיר ליחידה
  const [consumptionAmount, setConsumptionAmount] = useState(
    rowData.consumption_amount
  ); // כמות צריכה
  const [otherCharges, setOtherCharges] = useState(rowData.other_charges); // חיובים נוספים

  const [invoicingIdError, setInvoicingIdError] = useState('');
  const [hpError, setHpError] = useState('');
  const [waterMeterError, setWaterMeterError] = useState('');
  const [amountPaidWithoutVatError, setAmountPaidWithoutVatError] = useState('');
  const [amountPaidWithVatError, setAmountPaidWithVatError] = useState('');
  const [toPeriodError, setToPeriodError] = useState('');
  const [fromPeriodError, setFromPeriodError] = useState('');
  const [corporationNameError, setCorporationNameError] = useState('');
  const [sideCError, setSideCError] = useState('');
  const [remarksError, setRemarksError] = useState('');
  const [refundError, setRefundError] = useState('');
  const [printDateError, setPrintDateError] = useState('');
  const [priceForOneUnityError, setPriceForOneUnityError] = useState('');
  const [consumptionAmountError, setConsumptionAmountError] = useState('');
  const [otherChargesError, setOtherChargesError] = useState('');

  const arrCorporations = Object.assign(
    {},
    ...corporations.map((x) => ({ [x.provider_name]: x.hp }))
  );

  const arrProviderIds = Object.assign(
    {},
    ...corporations.map((x) => ({ [x.provider_name]: x.provider_id }))
  );

  const arrProvider = Object.assign(
    {},
    ...corporations.map((x) => ({ [x.hp]: x.provider_id }))
  );

  const calculatePriceVat = (inputPrice, inputVat) => {
    const parsedPrice = parseInt(inputPrice, 10);
    const parsedVat = parseInt(inputVat, 10);

    if (parsedPrice && parsedVat >= 0 && parsedVat <= 100) {
      const vatAmount = (parsedPrice * parsedVat) / 100;
      const priceIncludeVat = parsedPrice + vatAmount;
      return priceIncludeVat.toFixed(2);
    }
    return false;
  };

  const [vat, setVat] = useState(rowData.vat); // מעמ
  const [vatError, setVatError] = useState(rowData.vat); // מעמ

  const setVatbyHp = (hpSelected) => {
    const cookies = new Cookies();
    const init = cookies.get('Init');
    if (hpSelected) {
      const providerId = arrProvider[hpSelected];
      let vatTmp = 17;
      const firstDigitStr = String(providerId)[0];
      if (String(183058709) === (String(waterMeter))) {
        vatTmp = 0;
      }
      if (firstDigitStr === init.do_not_calculate_vat) {
        vatTmp = 0;
      }
      setVat(vatTmp);

      const tmpprice = calculatePriceVat(amountPaidWithoutVat, vatTmp);
      if (tmpprice) {
        setAmountPaidWithVat(tmpprice);
      }
    }
  };

  const handleSubmit = async () => {
    if (!invoicingId) {
      setInvoicingIdError('שדה חובה');
    }
    if (!hp) {
      setHpError('שדה חובה');
    }
    if (!toPeriod) {
      setToPeriodError('שדה חובה');
    }
    if (!fromPeriod) {
      setFromPeriodError('שדה חובה');
    }
    if (!printDate) {
      setPrintDateError('שדה חובה');
    }
    if (!waterMeter) {
      setWaterMeterError('שדה חובה');
    }
    if (!amountPaidWithoutVat) {
      setAmountPaidWithoutVatError('שדה חובה');
    }
    if (!amountPaidWithVat) {
      setAmountPaidWithVatError('שדה חובה');
    }
    if (!consumptionAmount) {
      setConsumptionAmountError('שדה חובה');
    }

    if (!invoicingId
        || !hp
        || !toPeriod
        || !fromPeriod
        || !printDate
        || !waterMeter
        || !amountPaidWithoutVat
        || !amountPaidWithVat
        || !consumptionAmount
    ) {
      return;
    }
    setLoading(true);

    const isVatOk = Math.abs(calculatePriceVat(amountPaidWithoutVat, vat) - Number(amountPaidWithVat)) <= 2;

    if (!isVatOk) {
      setErrMsg('סכום ללא מעמ לא תקין');
      setLoading(false);
      return;
    }

    const getInvoicingUrl = `${process.env.REACT_APP_BASE_URL}/invoicing`;
    const providerId = arrProviderIds[corporationName];
    // if (waterMeter) return;
    await axios
      .put(
        getInvoicingUrl,
        JSON.stringify({
          invoicing_id: rowData.original_inv,
          hp,
          provider_id: providerId,
          to_period: moment(toPeriod).format('DD-MM-YYYY'),
          from_period: moment(fromPeriod).format('DD-MM-YYYY'),
          print_date: moment(printDate).format('DD-MM-YYYY'),
          water_meter: waterMeter,
          amount_paid_without_vat: Number(amountPaidWithoutVat),
          vat: Number(vat),
          amount_paid_with_vat: Number(amountPaidWithVat),
          consumption_amount: Number(consumptionAmount),
          side_c: sideC,
          remarks,
          other_charges: otherCharges,
          refund,
          price_for_one_unity: priceForOneUnity
        }),
        {
          headers: {
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
            Authorization: `Bearer ${accessToken}`
          }
        }
      )
      .then((response) => {
        if (response?.status === 200 && response.data?.status_code === 1) {
          setSnackbarMss('החשבונית נערכה בהצלחה');
          setErrMsg('');
          setOpenSnackbar(true);
          setQuery([]);
          handleClose();
        }

        if (response?.data?.result && response.data?.status_code === 0) {
          setErrMsg(response?.data?.result);
        }
      })
      .catch((err) => {
        setErrMsg(err?.message);
        if (err?.response?.status === 403) {
          handelReturnLogin();
        }
        return false;
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    setLoading(false);
    setErrMsg('');
    setInvoicingId(rowData.invoicing_id); // מ.חשבונית
    setHp(rowData.hp); // ח.פ
    setWaterMeter(rowData.water_meter); // מד מים
    setAmountPaidWithoutVat(rowData.amount_paid_without_vat); // סגום לתשלום ללא מעמ
    setAmountPaidWithVat(rowData.amount_paid_with_vat); // סגום לתשלום  מעמ
    setToPeriod(moment(rowData.to_period).format('YYYY-MM-DD')); // עד תאריך
    setFromPeriod(moment(rowData.from_period).format('YYYY-MM-DD')); // מתאריך
    setCorporationName(rowData.corporation_name); // שם תאגיד
    setSideC(rowData.side_c); // צד ג׳
    setRemarks(rowData.remarks); // הערות
    setRefund(rowData.refund); // זיכויים
    setPrintDate(moment(rowData.print_date).format('YYYY-MM-DD')); // תאריך הדפסה
    setPriceForOneUnity(rowData.price_for_one_unity); // מחיר ליחידה
    setConsumptionAmount(rowData.consumption_amount); // כמות צריכה
    setOtherCharges(rowData.other_charges); // חיובים נוספים
    setVat(rowData.vat);
    setInvoicingIdError('');
    setHpError('');
    setWaterMeterError('');
    setAmountPaidWithoutVatError('');
    setAmountPaidWithVatError('');
    setToPeriodError('');
    setFromPeriodError('');
    setCorporationNameError('');
    setSideCError('');
    setRemarksError('');
    setRefundError('');
    setPrintDateError('');
    setPriceForOneUnityError('');
    setConsumptionAmountError('');
    setOtherChargesError('');
  }, [rowData]);

  return (
    <Box {...props}>
      <Box
        component="form"
        sx={{
          display: 'flex',
          justifyContent: 'flex-start'
        }}
      >
        <Dialog open={open} onClose={handleClose} maxWidth="md">
          {isLoading ? (
            <Box
              sx={{
                display: 'grid',
                paddingBottom: 6,
                paddingTop: 2,
                gridTemplateColumns: { sm: '2fr 1fr' },
                gap: 3
                // '& > :not(style)': { m: 1, width: '25ch' }
              }}
            >
              <CircularLoading />
            </Box>
          ) : (
            <>
              <DialogTitle
                sx={{
                  p: 2,
                  textAlign: 'center',
                  fontSize: 30
                }}
              >
                <Box display="flex" alignItems="center">
                  <Box>
                    <IconButton onClick={handleClose}>
                      <CloseIcon />
                    </IconButton>
                  </Box>
                  <Box flexGrow={1}>עריכת חשבונית</Box>
                </Box>
              </DialogTitle>
              <DialogContent>
                <Box
                  sx={{
                    display: 'grid',
                    paddingBottom: 6,
                    paddingTop: 2,
                    gridTemplateColumns: { sm: '1fr 1fr 1fr 1fr' },
                    gap: 3
                  }}
                >
                  <TextField
                    id="invoicingId"
                    required
                    label="מספר חשבונית"
                    // type="number"
                    variant="outlined"
                    value={invoicingId}
                    onChange={(event) => {
                      setInvoicingIdError('');
                      setInvoicingId(event.target.value);
                    }}
                    helperText={invoicingIdError}
                    error={!!invoicingIdError}
                    InputProps={{
                      readOnly: true,
                      maxLength: 12
                    }}
                    InputLabelProps={{
                      shrink: true,
                      classes: {
                        root: classes.labelRoot,
                        shrink: classes.shrink
                      }
                    }}
                  />
                  <TextField
                    id="hp"
                    required
                    label="מספר ח.פ"
                    type="number"
                    variant="outlined"
                    value={hp}
                    onChange={(event) => {
                      setHpError('');
                      setHp(event.target.value);
                    }}
                    helperText={hpError}
                    error={!!hpError}
                    InputProps={{
                      readOnly: true
                    }}
                    InputLabelProps={{
                      shrink: true,
                      classes: {
                        root: classes.labelRoot,
                        shrink: classes.shrink
                      }
                    }}
                  />
                  <TextField
                    id="printDate"
                    required
                    label="תאריך הדפסה"
                    type="date"
                    variant="outlined"
                    value={printDate}
                    onChange={(event) => {
                      setPrintDateError('');
                      setPrintDate(event.target.value);
                    }}
                    helperText={printDateError}
                    error={!!printDateError}
                    InputLabelProps={{
                      shrink: true,
                      classes: {
                        root: classes.labelRoot,
                        shrink: classes.shrink
                      }
                    }}
                  />
                  <TextField
                    id="waterMeter"
                    required
                    label="מד מים"
                    type="number"
                    variant="outlined"
                    value={waterMeter}
                    onChange={(event) => {
                      setWaterMeterError('');
                      setWaterMeter(event.target.value);
                      if (String(183058709) === (String(event.target.value))) {
                        setVat(0);
                      }
                    }}
                    helperText={waterMeterError}
                    error={!!waterMeterError}
                    InputLabelProps={{
                      shrink: true,
                      classes: {
                        root: classes.labelRoot,
                        shrink: classes.shrink
                      }
                    }}
                  />
                </Box>
                <Box
                  sx={{
                    display: 'grid',
                    gridTemplateColumns: { sm: '1fr 1fr 1fr 1fr' },
                    gap: 3,
                    paddingBottom: 6
                  }}
                >
                  <TextField
                    id="amountPaidWithoutVat"
                    required
                    label="סכום לתשלום ללא מע״מ"
                    type="number"
                    variant="outlined"
                    value={amountPaidWithoutVat}
                    onChange={(event) => {
                      setAmountPaidWithoutVatError('');
                      setAmountPaidWithoutVat(event.target.value);
                      const tmpprice = calculatePriceVat(event.target.value, vat);
                      if (tmpprice) {
                        setAmountPaidWithVat(tmpprice);
                      }
                    }}
                    helperText={amountPaidWithoutVatError}
                    error={!!amountPaidWithoutVatError}
                    InputLabelProps={{
                      shrink: true,
                      classes: {
                        root: classes.labelRoot,
                        shrink: classes.shrink
                      }
                    }}
                  />
                  <TextField
                    id="vat"
                    label="מע״מ"
                    type="number"
                    variant="outlined"
                    value={vat}
                    helperText={vatError}
                    error={!!vatError}
                    onChange={(e) => {
                      const inputValue = e.target.value;
                      if (inputValue === '' || (Number.isInteger(parseFloat(inputValue)) && parseInt(inputValue, 10) >= 0 && parseInt(inputValue, 10) <= 100)) {
                        setVat(inputValue);
                        setVatError('');
                        const tmpprice = calculatePriceVat(amountPaidWithoutVat, e.target.value);
                        if (tmpprice) {
                          setAmountPaidWithVat(tmpprice);
                        }
                      } else {
                        setVatError('סכום המע״מ חייב להיות 0-100');
                      }
                    }}
                    inputProps={{
                      min: 0,
                      max: 100,
                    }}
                    InputLabelProps={{
                      shrink: true,
                      classes: {
                        root: classes.labelRoot,
                        shrink: classes.shrink
                      }
                    }}
                  />
                  <TextField
                    id="amountPaidWithVat"
                    required
                    label="סכום לתשלום כולל מע״מ"
                    type="number"
                    variant="outlined"
                    value={amountPaidWithVat}
                    onChange={(event) => {
                      setAmountPaidWithVatError('');
                      setAmountPaidWithVat(event.target.value);
                    }}
                    helperText={amountPaidWithVatError}
                    error={!!amountPaidWithVatError}
                    InputLabelProps={{
                      shrink: true,
                      classes: {
                        root: classes.labelRoot,
                        shrink: classes.shrink
                      }
                    }}
                  />
                  <TextField
                    id="priceForOneUnity"
                    label="מחיר ליחידה"
                    type="number"
                    variant="outlined"
                    value={priceForOneUnity}
                    onChange={(event) => {
                      setPriceForOneUnityError('');
                      setPriceForOneUnity(event.target.value);
                    }}
                    helperText={priceForOneUnityError}
                    error={!!priceForOneUnityError}
                    InputLabelProps={{
                      shrink: true,
                      classes: {
                        root: classes.labelRoot,
                        shrink: classes.shrink
                      }
                    }}
                  />
                </Box>
                <Box
                  sx={{
                    display: 'grid',
                    gridTemplateColumns: { sm: '1fr 1fr 1fr' },
                    gap: 3,
                    paddingBottom: 6
                  }}
                >
                  <TextField
                    id="refund"
                    label="זיכויים"
                    type="number"
                    variant="outlined"
                    value={refund}
                    onChange={(event) => {
                      setRefundError('');
                      setRefund(event.target.value);
                    }}
                    helperText={refundError}
                    error={!!refundError}
                    InputLabelProps={{
                      shrink: true,
                      classes: {
                        root: classes.labelRoot,
                        shrink: classes.shrink
                      }
                    }}
                  />
                  <TextField
                    id="otherCharges"
                    label="חיובים נוספים"
                    type="number"
                    variant="outlined"
                    value={otherCharges}
                    onChange={(event) => {
                      setOtherChargesError('');
                      setOtherCharges(event.target.value);
                    }}
                    helperText={otherChargesError}
                    error={!!otherChargesError}
                    InputLabelProps={{
                      shrink: true,
                      classes: {
                        root: classes.labelRoot,
                        shrink: classes.shrink
                      }
                    }}
                  />
                  <TextField
                    id="sideC"
                    label="צד ג׳"
                    variant="outlined"
                    value={sideC}
                    onChange={(event) => {
                      setSideCError('');
                      setSideC(event.target.value);
                    }}
                    helperText={sideCError}
                    error={!!sideCError}
                    InputLabelProps={{
                      shrink: true,
                      classes: {
                        root: classes.labelRoot,
                        shrink: classes.shrink
                      }
                    }}
                  />
                </Box>
                <Box
                  sx={{
                    display: 'grid',
                    gridTemplateColumns: { sm: '1fr' },
                    gap: 3,
                    paddingBottom: 6
                  }}
                >
                  <TextField
                    id="remarks"
                    label="הערות"
                    variant="outlined"
                    value={remarks}
                    onChange={(event) => {
                      setRemarksError('');
                      setRemarks(event.target.value);
                    }}
                    placeholder="ניתן לכתוב הערה עד 100 תווים."
                    inputProps={{ maxLength: 100 }}
                    multiline
                    rows={2}
                    helperText={remarksError}
                    error={!!remarksError}
                    InputLabelProps={{
                      shrink: true,
                      classes: {
                        root: classes.labelRoot,
                        shrink: classes.shrink
                      }
                    }}
                  />
                </Box>
                <Box
                  sx={{
                    display: 'grid',
                    gridTemplateColumns: { sm: '1fr 1fr 1fr 1fr' },
                    gap: 3,
                    paddingBottom: 3
                  }}
                >
                  <Autocomplete
                    id="corporationName"
                    options={corporations.map(
                      (corporation) => corporation.provider_name
                    )}
                    disablePortal
                    isOptionEqualToValue={(option, value) => { option.id === value.id; }}
                    label="שם תאגיד"
                    required
                    variant="outlined"
                    value={corporationName}
                    onSelect={(event) => {
                      setCorporationNameError('');
                      setHpError('');
                      if (event.target.value && corporationName !== event.target.value) {
                        setCorporationName(event.target.value);
                        setHp(arrCorporations[event.target.value]);
                        setVatError('');
                        setVatbyHp(arrCorporations[event.target.value]);
                      }
                    }}
                    renderInput={(params) => (
                      <TextField
                        variant="outlined"
                        helperText={corporationNameError}
                        error={!!corporationNameError}
                        {...params}
                        label="שם תאגיד"
                      />
                    )}
                  />
                  <TextField
                    id="consumptionAmount"
                    required
                    label="כמות צריכה"
                    type="number"
                    variant="outlined"
                    value={consumptionAmount}
                    onChange={(event) => {
                      setConsumptionAmountError('');
                      setConsumptionAmount(event.target.value);
                    }}
                    helperText={consumptionAmountError}
                    error={!!consumptionAmountError}
                    InputLabelProps={{
                      shrink: true,
                      classes: {
                        root: classes.labelRoot,
                        shrink: classes.shrink
                      }
                    }}
                  />
                  <TextField
                    id="fromPeriod"
                    required
                    label="מתאריך"
                    type="date"
                    variant="outlined"
                    value={fromPeriod}
                    onChange={(event) => {
                      setFromPeriodError('');
                      setFromPeriod(event.target.value);
                    }}
                    helperText={fromPeriodError}
                    error={!!fromPeriodError}
                    InputLabelProps={{
                      shrink: true,
                      classes: {
                        root: classes.labelRoot,
                        shrink: classes.shrink
                      }
                    }}
                  />
                  <TextField
                    id="toPeriod"
                    required
                    label="עד תאריך"
                    type="date"
                    variant="outlined"
                    value={toPeriod}
                    onChange={(event) => {
                      setToPeriodError('');
                      setToPeriod(event.target.value);
                    }}
                    helperText={toPeriodError}
                    error={!!toPeriodError}
                    InputLabelProps={{
                      shrink: true,
                      classes: {
                        root: classes.labelRoot,
                        shrink: classes.shrink
                      }
                    }}
                  />
                </Box>
              </DialogContent>
              <p className="errorMsg">{errMsg}</p>
              <DialogActions
                sx={{
                  justifyContent: 'center'
                }}
              >
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleSubmit}
                >
                  עדכן
                </Button>
              </DialogActions>
            </>
          )}
        </Dialog>
      </Box>
    </Box>
  );
};

export default EditInvoice;
