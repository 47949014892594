import * as React from 'react';
import { Button, Box } from '@material-ui/core';
import moment from 'moment';
import TextField from '@mui/material/TextField';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { useState } from 'react';

const FilterByDate = ({
  fromDate,
  setFromDate,
  toDate,
  setToDate,
  setRows,
  rowsFull
}) => {
  const [errorDate, setErrorDate] = useState(false);
  const [errorDateFrom, setErrorDateFrom] = useState(false);

  return (
    <Box sx={{ display: 'flex' }}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DatePicker
          inputFormat="DD/MM/YYYY"
          label="מתאריך"
          value={fromDate}
          onChange={(newValue) => {
            let newFrom = new Date(newValue);
            let selectedTo = new Date(toDate);
            if (toDate && newFrom > selectedTo) {
              setErrorDateFrom('תאריך לא בטווח התקין');
            } else {
              setFromDate(newValue);
              setErrorDateFrom('');
            }
          }}
          renderInput={(params) => (
            <TextField
              id="toSelect"
              size="small"
              {...params}
              error={!!errorDateFrom}
              helperText={errorDateFrom ?? errorDateFrom}
            />
          )}
        />
        <DatePicker
          inputFormat="DD/MM/YYYY"
          label="עד תאריך"
          value={toDate}
          onChange={(newValue) => {
            let newTo = new Date(newValue);
            let selectedFrom = new Date(fromDate);
            if (fromDate && newTo < selectedFrom) {
              setErrorDate('תאריך לא בטווח התקין');
            } else {
              setToDate(newValue);
              setErrorDate('');
            }
          }}
          renderInput={(params) => (
            <TextField
              id="fromSelect"
              size="small"
              {...params}
              error={!!errorDate}
              helperText={errorDate ?? errorDate}
            />
          )}
        />
      </LocalizationProvider>
      <Button
        variant="contained"
        color="primary"
        onClick={() => {
          if (!fromDate) {
            setErrorDateFrom('חובה להזין תאריך');
            return;
          }
          if (!toDate) {
            setErrorDate('חובה להזין תאריך');
            return;
          }
          setRows(
            rowsFull.filter(
              (item) => moment(item.print_date, 'YYYY-MM-DD').isSameOrAfter(
                fromDate.format('YYYY-MM-DD')
              ) && moment(item.print_date, 'YYYY-MM-DD').isSameOrBefore(
                toDate.format('YYYY-MM-DD')
              )
            )
          );
        }}
      >
        הצג
      </Button>
    </Box>
  );
};

export default FilterByDate;
