import { Helmet } from 'react-helmet';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Container } from '@material-ui/core';
import { GridColDef } from '@mui/x-data-grid';
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';

import Cookies from 'universal-cookie';
import CircularLoading from 'src/components/helpers/Loading';
import Tooltip from '@mui/material/Tooltip';
import BasicTable from '../components/helpers/BasicTable';
import useAuth from '../hooks/useAuth';

const MisiingInvoicing = () => {
  const navigate = useNavigate();
  const { auth, setAuth } = useAuth();

  const handelReturnLogin = () => {
    const cookies = new Cookies();
    cookies.set('Auth', {}, { path: '/' });
    setAuth(cookies.get('Auth'));
    navigate('/login', { replace: true });
  };

  const [isLoading, setLoading] = useState(true);

  const [rows, setRows] = useState([]);

  const columns: GridColDef[] = [
    {
      field: 'id',
      headerName: 'מספר מזהה',
      sortable: false,
      hide: true,
      filterable: false
    },
    {
      field: 'count',
      headerName: 'כמות',
      filterable: false,
      flex: 0.2,
      renderCell: (params) => (
        <Tooltip title={params.row.count}>
          <span className="table-cell-trucate">{params.row.count}</span>
        </Tooltip>
      )
    },
    {
      field: 'missing_inv',
      headerName: 'חשבוניות חסרות',
      filterable: false,
      flex: 0.2,
      renderCell: (params) => (
        <Tooltip title={params.row.missing_inv}>
          <span className="table-cell-trucate">
            {params.row.missing_inv}
          </span>
        </Tooltip>
      )
    },
    {
      field: 'water_meter',
      headerName: 'מד מים',
      filterable: true,
      flex: 0.2,
      renderCell: (params) => (
        <Tooltip title={params.row.water_meter}>
          <span className="table-cell-trucate">{params.row.water_meter}</span>
        </Tooltip>
      )
    },
    {
      field: 'station_name',
      headerName: 'שם תחנה',
      flex: 0.2,
      renderCell: (params) => (
        <Tooltip title={params.row.station_name}>
          <span className="table-cell-trucate">{params.row.station_name}</span>
        </Tooltip>
      )
    }
  ];

  const getInvoices = async () => {
    const getUrl = `${process.env.REACT_APP_BASE_URL}/dashboard`;

    await axios
      .get(getUrl, {
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
          Authorization: `Bearer ${auth.accessToken}`
        }
      })
      .then((response) => {
        if (response?.data?.result && response.data?.status_code === 1) {
          if (response?.data?.result?.table?.missing_inv) {
            setRows(
              response?.data?.result?.table?.missing_inv.map((invoice) => ({
                id: uuidv4(), // Generate a random id using uuidv4()
                station_name: invoice?.station_name,
                water_meter: invoice?.water_meter,
                missing_inv: invoice?.missing_inv,
                count: invoice?.count
              }))
            );
          }
          return response?.data?.result;
        }
        return [];
      })
      .catch((err) => {
        if (err?.response?.status === 403) {
          handelReturnLogin();
        }
        return false;
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getInvoices(auth.accessToken);
  }, []);

  if (isLoading) {
    return (
      <div>
        <CircularLoading />
      </div>
    );
  }

  return (
    <>
      <Helmet>
        <title> חשבוניות חסרות </title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 3
        }}
      >
        <Container maxWidth={false}>
          <Box sx={{ pt: 3 }} className="invoive">
            <BasicTable
              rows={rows}
              columns={columns}
              title="חשבוניות חסרות"
            />
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default MisiingInvoicing;
