import { Helmet } from 'react-helmet';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Container } from '@material-ui/core';
import { GridRowsProp, GridColDef } from '@mui/x-data-grid';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import axios from 'axios';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import Cookies from 'universal-cookie';
import CircularLoading from 'src/components/helpers/Loading';
import BasicTable from '../components/helpers/BasicTable';
import useAuth from '../hooks/useAuth';
import AddUser from '../components/users/AddUser';
import EditUser from '../components/users/EditUser';
import AlertDialog from '../components/helpers/AlertPopup';

const CustomerList = () => {
  const navigate = useNavigate();
  const { auth, setAuth } = useAuth();
  const [snackbarMss, setSnackbarMss] = useState('');
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [query, setQuery] = useState([]);
  const [rowData, setRowData] = useState();

  const [openEdit, setOpenEdit] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };
  const handelReturnLogin = () => {
    const cookies = new Cookies();
    cookies.set('Auth', {}, { path: '/' });
    setAuth(cookies.get('Auth'));
    navigate('/login', { replace: true });
  };
  const handleCloseEdit = () => {
    setOpenEdit(false);
  };

  const [email, setEmail] = useState('');
  const [errorMsg, setErrMsg] = useState(false);
  const [isLoadingDelete, setLoadingDelete] = useState(false);

  const handleCloseDelete = () => {
    setOpenDelete(false);
  };
  const deleteUser = async () => {
    const getUsersUrl = `${process.env.REACT_APP_BASE_URL}/user?email=${email}`;
    setLoadingDelete(true);
    await axios
      .delete(getUsersUrl, {
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
          Authorization: `Bearer ${auth.accessToken}`
        }
      })
      .then((response) => {
        if (response?.status === 200 && response.data?.status_code === 1) {
          setSnackbarMss('המשתמש נמחק בהצלחה');
          setQuery([]);
          setOpenSnackbar(true);
          handleCloseDelete();
          return true;
        }
        if (response?.data?.result && response.data?.status_code === 0) {
          setErrMsg(response?.data?.result);
          return false;
        }
        setErrMsg(response?.data?.result);
        return false;
      })
      .catch((err) => {
        if (err?.response?.status === 403) {
          handelReturnLogin();
        }
        setErrMsg(err?.message);
        return false;
      })
      .finally(() => {
        setLoadingDelete(false);
        return false;
      });
  };

  const columns: GridColDef[] = [
    {
      field: 'id',
      headerName: 'מספר מזהה',
      sortable: false,
      hide: true
    },
    {
      field: 'delete',
      headerName: '',
      flex: 0.2,
      sortable: false,
      renderCell: (params) => {
        const handleClickOpen = () => {
          setEmail(params.row.email);
          setOpenDelete(true);
        };

        return (
          <>
            <DeleteIcon onClick={handleClickOpen} />
          </>
        );
      }
    },
    {
      field: 'edit',
      headerName: '',
      flex: 0.2,
      sortable: false,
      renderCell: (params) => {
        const handleClickOpenEdit = () => {
          setRowData(params.row);
          setOpenEdit(true);
        };
        return (
          <>
            <EditIcon onClick={handleClickOpenEdit} />
          </>
        );
      }
    },
    { field: 'initiate_ts', flex: 1, headerName: 'כניסה אחרונה' },
    { field: 'signup_ts', flex: 1, headerName: 'תאריך הרשמה' },
    {
      field: 'role',
      flex: 1,
      headerName: 'תפקיד',
      renderCell: (role) => <p>{role.value === 'a' ? 'מנהל' : 'עובד'}</p>
    },
    { field: 'phone', flex: 1, headerName: 'מספר נייד' },
    { field: 'last_name', flex: 1, headerName: 'שם משפחה' },
    { field: 'first_name', flex: 1, headerName: 'שם פרטי' },
    { field: 'email', flex: 1, headerName: 'אימייל' }
  ];
  const [isLoading, setLoading] = useState(true);
  const [rows, setRows] = useState([]);

  const getUsers = async (accessToken) => {
    const getUsersUrl = `${process.env.REACT_APP_BASE_URL}/customer?fetch_all=true`;

    await axios
      .get(getUsersUrl, {
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
          Authorization: `Bearer ${accessToken}`
        }
      })
      .then((response) => {
        if (response?.data?.result && response.data?.status_code === 1) {
          const tmpRows: GridRowsProp = response?.data?.result;
          setRows(tmpRows);
          return tmpRows;
        }
        const tmpRows: GridRowsProp = [];
        setRows(tmpRows);
        return tmpRows;
      })
      .catch((err) => {
        if (err?.response?.status === 403) {
          handelReturnLogin();
        }
        return false;
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getUsers(auth.accessToken);
  }, [query]);

  if (isLoading) {
    return (
      <div>
        <CircularLoading />
      </div>
    );
  }

  return (
    <>
      <Helmet>
        <title>משתמשים</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 3
        }}
      >
        <Container maxWidth={false}>
          <AddUser
            buttonText="הוספת משתמש"
            accessToken={auth.accessToken}
            handelReturnLogin={handelReturnLogin}
            setQuery={setQuery}
          />
          <Snackbar
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            open={openSnackbar}
            onClose={handleCloseSnackbar}
            message={snackbarMss}
            key={{ vertical: 'top', horizontal: 'center' }}
          >
            <Stack sx={{ width: '100%' }} spacing={2}>
              <Alert variant="filled" severity="success">
                {snackbarMss}
              </Alert>
            </Stack>
          </Snackbar>
          <EditUser
            rowData={rowData}
            handleClose={handleCloseEdit}
            open={openEdit}
            handelReturnLogin={handelReturnLogin}
            accessToken={auth.accessToken}
            setOpenSnackbar={setOpenSnackbar}
            setSnackbarMss={setSnackbarMss}
            setQuery={setQuery}
          />
          <AlertDialog
            open={openDelete}
            handleClose={handleCloseDelete}
            dialogTitle={`האם אתה בטוח שברצונך למחוק את המשתמש ${email}?`}
            handleSubmit={deleteUser}
            errorMsg={errorMsg}
            isLoading={isLoadingDelete}
          />
          <Box sx={{ pt: 3 }}>
            <BasicTable rows={rows} columns={columns} title="משתמשים" />
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default CustomerList;
