// import { useState } from 'react';
import { useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  AppBar,
  Button,
  Box,
  Hidden,
  IconButton,
  Toolbar,
  Typography
} from '@material-ui/core';
import Cookies from 'universal-cookie';
import Dialog from '@mui/material/Dialog';
import TextField from '@mui/material/TextField';
import axios from 'axios';
import CircularLoading from 'src/components/helpers/Loading';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
// import NotificationsIcon from '@material-ui/icons/NotificationsOutlined';
import InputIcon from '@material-ui/icons/Input';
import MenuIcon from '@material-ui/icons/Menu';
import moment from 'moment';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import Logo from './Logo';
import useAuth from '../hooks/useAuth';

const DashboardNavbar = ({ onMobileNavOpen, ...rest }) => {
  const { auth, setAuth } = useAuth();
  const navigate = useNavigate();
  const cookies = new Cookies();
  const init = cookies.get('Init');

  const [open, setOpen] = useState(init.forgot_password === 1);
  const [isLoading, setLoading] = useState(false);
  const [password, setPassword] = useState();
  const [passwordError, setPasswordError] = useState('');

  const [snackbarMss, setSnackbarMss] = useState('');
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [errMsg, setErrMsg] = useState('');

  const handleClose = () => {
    setOpen(init.forgot_password === 1);
    setTimeout(() => {
      // After 3 seconds set the show value to false
      setSnackbarMss('');
      setOpenSnackbar(false);
    }, 3000);
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnackbar(false);
  };

  const handleSubmit = async () => {
    if (!password) {
      setPasswordError('שדה חובה');
      return;
    }
    setLoading(true);

    const getuserUrl = `${process.env.REACT_APP_BASE_URL}/user`;

    await axios
      .put(
        getuserUrl,
        JSON.stringify({
          password
        }),
        {
          headers: {
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
            Authorization: `Bearer ${auth.accessToken}`
          }
        }
      )
      .then((response) => {
        if (response?.status === 200 && response.data?.status_code === 1) {
          setSnackbarMss('הסיסמא התעדכנה בהצלחה');
          init.forgot_password = 0;
          cookies.set('Init', init, { path: '/' });
          setOpenSnackbar(true);
          handleClose();
        }

        if (response?.data?.result && response.data?.status_code === 0) {
          setErrMsg(response?.data?.result);
        }
      })
      .catch((err) => {
        setErrMsg(err?.message);
        return false;
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <AppBar elevation={0} {...rest}>
      <Toolbar>
        <RouterLink to="/" style={{ marginRight: '20px', display: 'flex' }}>
          <Logo />
        </RouterLink>
        <Box sx={{ flexGrow: 1, marginRight: '100px' }}><Typography sx={{ textAlign: 'center' }}>מערכת חשבוניות</Typography></Box>
        <Box sx={{ fontFamily: ['sans-serif'] }}>
          {moment.utc(init.last_signin).format('HH:mm:ss DD-MM-YYYY')}
        </Box>
        <Hidden lgDown>
          {/* <IconButton color="inherit" size="large">
            <Badge
              badgeContent={notifications.length}
              color="primary"
              variant="dot"
            >
              <NotificationsIcon />
            </Badge>
          </IconButton> */}
          <IconButton
            color="inherit"
            size="large"
            onClick={() => {
              cookies.set('Auth', {}, { path: '/' });
              cookies.set('Init', {}, { path: '/' });
              cookies.set('User', {}, { path: '/' });
              setAuth(cookies.get('Auth'));
              navigate('/login', { replace: true });
            }}
          >
            <InputIcon />
          </IconButton>
        </Hidden>
        <Hidden lgUp>
          <IconButton color="inherit" onClick={onMobileNavOpen} size="large">
            <MenuIcon />
          </IconButton>
        </Hidden>
      </Toolbar>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={openSnackbar}
        onClose={handleCloseSnackbar}
        message={snackbarMss}
        key={{ vertical: 'top', horizontal: 'center' }}
      >
        <Stack sx={{ width: '100%' }} spacing={2}>
          <Alert variant="filled" severity="success">
            {snackbarMss}
          </Alert>
        </Stack>
      </Snackbar>
      <Dialog open={open} onClose={handleClose}>
        {isLoading ? (
          <Box
            sx={{
              display: 'grid',
              paddingBottom: 6,
              paddingTop: 2,
              gridTemplateColumns: { sm: '2fr 1fr' },
              gap: 3
              // '& > :not(style)': { m: 1, width: '25ch' }
            }}
          >
            <CircularLoading />
          </Box>
        ) : (
          <>
            <DialogTitle
              sx={{
                p: 2,
                textAlign: 'center',
                fontSize: 30
              }}
            >
              החלפת סיסמא
            </DialogTitle>
            <DialogContent>
              <Box
                sx={{
                  display: 'grid',
                  paddingTop: 1
                }}
              >
                <TextField
                  id="password"
                  required
                  label="סיסמא חדשה"
                  type="password"
                  variant="outlined"
                  onChange={(event) => {
                    setPassword(event.target.value);
                  }}
                  helperText={passwordError}
                  error={!!passwordError}
                />
              </Box>
              <p className="errorMsg">{errMsg}</p>
            </DialogContent>
            <DialogActions
              sx={{
                justifyContent: 'center'
              }}
            >
              <Button
                variant="contained"
                color="primary"
                onClick={handleSubmit}
              >
                עדכן
              </Button>
            </DialogActions>
          </>
        )}
      </Dialog>
    </AppBar>
  );
};

DashboardNavbar.propTypes = {
  onMobileNavOpen: PropTypes.func
};

export default DashboardNavbar;
