import TextField from '@mui/material/TextField';
import DialogContent from '@mui/material/DialogContent';
import { useState, useEffect } from 'react';
import { Box, Button } from '@material-ui/core';
import Autocomplete from '@mui/material/Autocomplete';
import { makeStyles } from '@material-ui/styles';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import axios from 'axios';
import CircularLoading from 'src/components/helpers/Loading';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles(() => ({
  shrink: {
    transformOrigin: 'top right',
    right: 28
  }
}));

const EditUser = ({
  rowData,
  accessToken,
  handelReturnLogin,
  open,
  handleClose,
  setSnackbarMss,
  setOpenSnackbar,
  setQuery,
  ...props
}) => {
  if (!rowData) {
    return <></>;
  }
  const classes = useStyles();
  const [isLoading, setLoading] = useState(false);
  const [errMsg, setErrMsg] = useState('');
  const [firstName, setFirstName] = useState(rowData.first_name);
  const [lastName, setLastName] = useState(rowData.last_name);
  const [phone, setPhone] = useState(rowData.phone);
  const [password, setPassword] = useState(rowData.password);
  const [role, setRole] = useState(rowData.role);
  const [email, setEmail] = useState(rowData.email);

  const [firstNameError, setFirstNameError] = useState('');
  const [lastNameError, setLastNameError] = useState('');
  const [phoneError, setPhoneError] = useState('');
  const [roleError, setRoleError] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [emailError, setEmailError] = useState('');

  useEffect(() => {
    setFirstName(rowData.first_name);
    setLastName(rowData.last_name);
    setPhone(rowData.phone);
    setPassword(rowData.password);
    setRole(rowData.role);
    setEmail(rowData.email);

    setFirstNameError('');
    setLastNameError('');
    setPhoneError('');
    setRoleError('');
    setPasswordError('');
    setEmailError('');
  }, [rowData]);

  const handleSubmit = async () => {
    if (!email) {
      setEmailError('שדה חובה');
    }
    if (!firstName) {
      setFirstNameError('שדה חובה');
    }
    if (!lastName) {
      setLastNameError('שדה חובה');
    }
    if (!phone) {
      setPhoneError('שדה חובה');
    }
    if (!role) {
      setRoleError('שדה חובה');
    }
    if (!firstName || !lastName || !phone || !role || !email) {
      return;
    }
    setLoading(true);

    const getuserUrl = `${process.env.REACT_APP_BASE_URL}/user`;

    let jsonlet = {
      email: rowData.email,
      first_name: firstName,
      last_name: lastName,
      phone,
      role,
    };
    if (password) {
      jsonlet = {
        ...jsonlet,
        password
      };
    }
    if (rowData.email !== email) {
      jsonlet = {
        ...jsonlet,
        new_email: email
      };
    }
    await axios
      .put(
        getuserUrl,
        JSON.stringify(jsonlet),
        {
          headers: {
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
            Authorization: `Bearer ${accessToken}`
          }
        }
      )
      .then((response) => {
        if (response?.status === 200 && response.data?.status_code === 1) {
          setSnackbarMss('נערך בהצלחה');
          setOpenSnackbar(true);
          setQuery([]);
          handleClose();
        }

        if (response?.data?.result && response.data?.status_code === 0) {
          setErrMsg(response?.data?.result);
        }
      })
      .catch((err) => {
        setErrMsg(err?.message);
        if (err?.response?.status === 403) {
          handelReturnLogin();
        }
        return false;
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Box {...props}>
      <Box
        component="form"
        sx={{
          display: 'flex',
          justifyContent: 'flex-start'
        }}
      >
        <Dialog open={open} onClose={handleClose}>
          {isLoading ? (
            <Box
              sx={{
                display: 'grid',
                paddingBottom: 6,
                paddingTop: 2,
                gridTemplateColumns: { sm: '2fr 1fr' },
                gap: 3
                // '& > :not(style)': { m: 1, width: '25ch' }
              }}
            >
              <CircularLoading />
            </Box>
          ) : (
            <>
              <DialogTitle
                sx={{
                  p: 2,
                  textAlign: 'center',
                  fontSize: 30
                }}
              >
                <Box display="flex" alignItems="center">
                  <Box>
                    <IconButton onClick={handleClose}>
                      <CloseIcon />
                    </IconButton>
                  </Box>
                  <Box flexGrow={1}>עריכת משתמש</Box>
                </Box>
              </DialogTitle>

              <DialogContent>
                <Box
                  sx={{
                    display: 'grid',
                    paddingBottom: 2,
                    paddingTop: 2,
                    gridTemplateColumns: { sm: '1fr 1fr' },
                    gap: 3
                  }}
                >
                  <TextField
                    id="firstName"
                    required
                    label="שם פרטי"
                    variant="outlined"
                    value={firstName}
                    onChange={(event) => {
                      setFirstNameError('');
                      setFirstName(event.target.value);
                    }}
                    helperText={firstNameError}
                    error={!!firstNameError}
                    InputLabelProps={{
                      shrink: true,
                      classes: {
                        root: classes.labelRoot,
                        shrink: classes.shrink
                      }
                    }}
                  />
                  <TextField
                    id="lastName"
                    required
                    label="שם משפחה"
                    variant="outlined"
                    value={lastName}
                    onChange={(event) => {
                      setLastNameError('');
                      setLastName(event.target.value);
                    }}
                    helperText={lastNameError}
                    error={!!lastNameError}
                    InputLabelProps={{
                      shrink: true,
                      classes: {
                        root: classes.labelRoot,
                        shrink: classes.shrink
                      }
                    }}
                  />
                </Box>
                <Box
                  sx={{
                    display: 'grid',
                    paddingBottom: 2,
                    paddingTop: 2,
                    gridTemplateColumns: { sm: '1fr 1fr' },
                    gap: 3
                  }}
                >
                  <TextField
                    id="email"
                    required
                    label="אימייל"
                    variant="outlined"
                    value={email}
                    onChange={(event) => {
                      setEmailError('');
                      setEmail(event.target.value);
                    }}
                    helperText={emailError}
                    error={!!emailError}
                    InputLabelProps={{
                      shrink: true,
                      classes: {
                        root: classes.labelRoot,
                        shrink: classes.shrink
                      }
                    }}
                  />
                  <TextField
                    id="password"
                    label="סיסמא"
                    type="password"
                    variant="outlined"
                    value={password}
                    onChange={(event) => {
                      setPasswordError('');
                      setPassword(event.target.value);
                    }}
                    helperText={passwordError}
                    error={!!passwordError}
                    InputLabelProps={{
                      shrink: true,
                      classes: {
                        root: classes.labelRoot,
                        shrink: classes.shrink
                      }
                    }}
                  />
                </Box>
                <Box
                  sx={{
                    display: 'grid',
                    paddingBottom: 2,
                    paddingTop: 2,
                    gridTemplateColumns: { sm: '1fr 1fr' },
                    gap: 3
                  }}
                >
                  <TextField
                    id="phone"
                    required
                    label="נייד"
                    variant="outlined"
                    value={phone}
                    onChange={(event) => {
                      setPhoneError('');
                      setPhone(event.target.value);
                    }}
                    helperText={phoneError}
                    error={!!phoneError}
                    InputLabelProps={{
                      shrink: true,
                      classes: {
                        root: classes.labelRoot,
                        shrink: classes.shrink
                      }
                    }}
                  />
                  <Autocomplete
                    disablePortal
                    id="role"
                    value={role === 'u' ? 'עובד' : 'מנהל'}
                    options={['מנהל', 'עובד']}
                    onSelect={(event) => {
                      setRoleError('');
                      setRole(event.target.value === 'מנהל' ? 'a' : 'u');
                    }}
                    renderInput={(params) => (
                      <TextField
                        helperText={roleError}
                        error={!!roleError}
                        {...params}
                        label="תפקיד"
                      />
                    )}
                  />
                </Box>
              </DialogContent>
              <p className="errorMsg">{errMsg}</p>
              <DialogActions
                sx={{
                  justifyContent: 'center'
                }}
              >
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleSubmit}
                >
                  עדכן
                </Button>
              </DialogActions>
            </>
          )}
        </Dialog>
      </Box>
    </Box>
  );
};

export default EditUser;
