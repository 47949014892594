const Logo = (props) => (
  <img
    alt="Logo"
    src="/static/PAZ_LOGO_WEB.png"
    width="70px"
    height="50px"
    {...props}
  />
);

export default Logo;
