import { useLocation, Navigate, Outlet } from 'react-router-dom';
import useAuth from '../hooks/useAuth';

const RequireAuth = ({ allowedRoles }) => {
  const { auth } = useAuth();
  const location = useLocation();
  return allowedRoles?.includes(auth?.role) ? (
    <Outlet />
  ) : auth?.accessToken ? (
    location.pathname === '/' || location.pathname === '/dashboard' ? (
      <Navigate to="/stations" state={{ from: location }} replace />
    ) : (
      <Navigate to="/dashboard" state={{ from: location }} replace />
    )
  ) : location.pathname === '/forgotPass' ? (
    <Outlet />
  ) : (
    // <Outlet />
    <Navigate to="/login" state={{ from: location }} replace />
  );
};

export default RequireAuth;
