import * as React from 'react';
import Button from '@mui/material/Button';
import { Box, Typography } from '@material-ui/core';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
// import DialogContent from '@mui/material/DialogContent';
// import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import CircularLoading from 'src/components/helpers/Loading';
import axios from 'axios';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

const ChangeStatusDialog = ({
  open,
  handleClose,
  dialogTitle,
  curStatus,
  invoicingIdOriginal,
  auth,
  setQuery,
  handelReturnLogin,
  setOpenSnackbar,
  setSnackbarMss,
  approveName,
  updateDate
}) => {
  const [errorMsg, setErrMsg] = React.useState(false);
  const [isLoading, setLoadingApprove] = React.useState(false);
  const [showConfirmation, setShowConfirmation] = React.useState(false);

  const changeStatus = async (newStatus) => {
    const getInvoicesUrl = `${process.env.REACT_APP_BASE_URL}/invoicing`;
    setLoadingApprove(true);
    await axios
      .put(
        getInvoicesUrl,
        JSON.stringify({ invoicing_id: invoicingIdOriginal, active: newStatus }),
        {
          headers: {
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
            Authorization: `Bearer ${auth.accessToken}`
          }
        }
      )
      .then((response) => {
        if (response?.status === 200 && response.data?.status_code === 1) {
          setSnackbarMss('סטטוס שונה בהצלחה');
          setOpenSnackbar(true);
          setQuery([]);
          handleClose();
          return true;
        }
        if (response?.data?.result && response.data?.status_code === 0) {
          setErrMsg(response?.data?.result);
          return false;
        }
        setErrMsg(response?.data?.result);
        return false;
      })
      .catch((err) => {
        if (err?.response?.status === 403) {
          handelReturnLogin();
        }
        setErrMsg(err?.message);
        return false;
      })
      .finally(() => {
        setLoadingApprove(false);
        return false;
      });
  };
  const handleCancelClick = () => {
    setShowConfirmation(false);
  };

  const handleConfirmClick = async () => {
    setShowConfirmation(false);
    changeStatus(3);
  };
  return (
    <div>
      <Dialog
        open={open}
        fullWidth
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle
          sx={{
            p: 2,
            textAlign: 'center',
            fontSize: 30
          }}
          id="alert-dialog-title"
        >
          <Box display="flex" alignItems="center">
            <Box>
              <IconButton onClick={handleClose}>
                <CloseIcon />
              </IconButton>
            </Box>
            <Box flexGrow={1}>{dialogTitle}</Box>
          </Box>
        </DialogTitle>
        <p className="errorMsg">{errorMsg}</p>
        {isLoading ? (
          <Box
            sx={{
              display: 'grid',
              paddingBottom: 6,
              paddingTop: 2,
              gridTemplateColumns: { sm: '1fr' },
              gap: 3
            }}
          >
            <CircularLoading />
          </Box>
        ) : showConfirmation ? (
          <Box>
            <Typography sx={{ textAlign: 'center', padding: '1%' }}>
              האם אתה בטוח שברצונך לבטל?
            </Typography>
            <DialogActions sx={{ justifyContent: 'center', paddingTop: '8%' }}>
              <Button
                variant="contained"
                color="primary"
                onClick={() => handleConfirmClick()}
                autoFocus
              >
                אשר ביטול
              </Button>
              <Button
                onClick={() => handleCancelClick()}
              >
                דחה
              </Button>
            </DialogActions>
          </Box>
        )
          : curStatus === 3 ? (
            <>
              <Typography sx={{ textAlign: 'center', padding: '1%' }}>
                סטטוס: מבוטל
              </Typography>
              <Typography sx={{ textAlign: 'center', padding: '1%' }}>
                {updateDate}
              </Typography>
              <DialogActions
                sx={{
                  justifyContent: 'center',
                  paddingTop: '8%'
                }}
              >
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => changeStatus('1')}
                  autoFocus
                >
                  עדכן לממתין לאישור
                </Button>
              </DialogActions>
            </>
          ) : (
            <>
              <Typography sx={{ textAlign: 'center', padding: '1%' }}>
                {curStatus === 1 ? 'סטטוס: ממתין לאישור' : (curStatus === 2 ? 'סטטוס: מאושר' : 'סטטוס: הועבר ')}
              </Typography>
              <Typography sx={{ textAlign: 'center', padding: '1%' }}>
                {approveName}
              </Typography>
              <Typography sx={{ textAlign: 'center', padding: '1%' }}>
                {updateDate}
              </Typography>
              <DialogActions
                sx={{
                  justifyContent: 'center',
                  paddingTop: '8%'
                }}
              >
                <Button onClick={() => setShowConfirmation(true)}>ביטול חשבונית</Button>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => changeStatus(curStatus === 1 ? '2' : '1')}
                  autoFocus
                >
                  {curStatus === 1 ? 'אישור חשבונית' : 'עדכן לממתין לאישור'}
                </Button>
              </DialogActions>
            </>
          )}
      </Dialog>
    </div>
  );
};

export default ChangeStatusDialog;
