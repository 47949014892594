import TextField from '@mui/material/TextField';
import DialogContent from '@mui/material/DialogContent';
// import DialogContentText from '@mui/material/DialogContentText';
import { useState } from 'react';
import { Box, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { Plus as PlusIcon } from 'react-feather';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import axios from 'axios';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import CircularLoading from 'src/components/helpers/Loading';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles(() => ({
  shrink: {
    transformOrigin: 'top right',
    right: 28
  }
}));

const AddProvider = ({
  buttonText,
  accessToken,
  handelReturnLogin,
  setQuery,
  ...props
}) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [isLoading, setLoading] = useState(false);

  const [snackbarMss, setSnackbarMss] = useState('');
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [errMsg, setErrMsg] = useState('');

  const [providerId, setProviderId] = useState(0);
  const [providerName, setProviderName] = useState('');
  const [hp, setHp] = useState('');
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  const [remarks, setRemarks] = useState('');

  const [providerIdError, setProviderIdError] = useState('');
  const [providerNameError, setProviderNameError] = useState('');
  const [hpError, setHpError] = useState('');
  const [phoneError, setPhoneError] = useState('');
  const [emailError, setEmailError] = useState('');
  const [remarksError, setRemarksError] = useState('');

  const handleClickOpen = () => {
    setOpen(true);
    setOpenSnackbar(false);
  };

  const handleClose = () => {
    setOpen(false);
    setTimeout(() => {
      // After 3 seconds set the show value to false
      setSnackbarMss('');
      setOpenSnackbar(false);
    }, 3000);
  };

  const handleSubmit = async () => {
    if (!providerId) {
      setProviderIdError('שדה חובה');
    }
    if (!providerName) {
      setProviderNameError('שדה חובה');
    }
    if (!hp) {
      setHpError('שדה חובה');
    }
    if (!providerName
      || !hp
      || !providerId
    ) {
      return;
    }
    setLoading(true);

    const getProviderUrl = `${process.env.REACT_APP_BASE_URL}/provider`;
    let jsonlet = {
      provider_id: providerId,
      provider_name: providerName,
      hp,
      remarks,
    };
    if (phone !== '') {
      jsonlet = {
        ...jsonlet,
        phone
      };
    }
    if (email !== '') {
      jsonlet = {
        ...jsonlet,
        email
      };
    }
    await axios
      .post(
        getProviderUrl,
        JSON.stringify(jsonlet),
        {
          headers: {
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
            Authorization: `Bearer ${accessToken}`
          }
        }
      )
      .then((response) => {
        if (response?.status === 201) {
          setSnackbarMss('התאגיד נוסף בהצלחה');
          setOpenSnackbar(true);
          setQuery([]);
          handleClose();

          setProviderId('');
          setProviderName('');
          setHp('');
          setPhone('');
          setEmail('');
          setRemarks('');
        }

        if (response?.data?.result && response.data?.status_code === 0) {
          setErrMsg(response?.data?.result);
        }
      })
      .catch((err) => {
        setErrMsg(err?.message);
        if (err?.response?.status === 403) {
          handelReturnLogin();
        }
        return false;
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnackbar(false);
  };

  return (
    <Box {...props}>
      <Box
        component="form"
        sx={{
          display: 'flex',
          justifyContent: 'flex-start'
        }}
      >
        <Button variant="contained" color="primary" onClick={handleClickOpen}>
          <PlusIcon />
          {buttonText}
        </Button>
        <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          open={openSnackbar}
          onClose={handleCloseSnackbar}
          message={snackbarMss}
          key={{ vertical: 'top', horizontal: 'center' }}
        >
          <Stack sx={{ width: '100%' }} spacing={2}>
            <Alert variant="filled" severity="success">
              {snackbarMss}
            </Alert>
          </Stack>
        </Snackbar>
        <Dialog open={open} onClose={handleClose}>
          {isLoading ? (
            <Box
              sx={{
                display: 'grid',
                paddingBottom: 6,
                paddingTop: 2,
                gridTemplateColumns: { sm: '2fr 1fr' },
                gap: 3
              }}
            >
              <CircularLoading />
            </Box>
          ) : (
            <>
              <DialogTitle
                sx={{
                  p: 2,
                  textAlign: 'center',
                  fontSize: 30
                }}
              >
                <Box display="flex" alignItems="center">
                  <Box>
                    <IconButton onClick={handleClose}>
                      <CloseIcon />
                    </IconButton>
                  </Box>
                  <Box flexGrow={1}>יצירת תאגיד חדש</Box>
                </Box>
              </DialogTitle>
              <DialogContent>
                <Box
                  sx={{
                    display: 'grid',
                    paddingBottom: 6,
                    paddingTop: 2,
                    gridTemplateColumns: { sm: '2fr 1fr' },
                    gap: 3
                  }}
                >
                  <TextField
                    id="providerName"
                    required
                    label="שם תאגיד"
                    variant="outlined"
                    value={providerName}
                    onChange={(event) => {
                      setProviderNameError('');
                      setProviderName(event.target.value);
                    }}
                    helperText={providerNameError}
                    error={!!providerNameError}
                    InputLabelProps={{
                      shrink: true,
                      classes: {
                        root: classes.labelRoot,
                        shrink: classes.shrink
                      }
                    }}
                  />
                  <TextField
                    id="providerId"
                    required
                    label="מספר תאגיד"
                    type="number"
                    variant="outlined"
                    value={providerId}
                    onChange={(event) => {
                      setProviderIdError('');
                      setProviderId(event.target.value);
                    }}
                    helperText={providerIdError}
                    error={!!providerIdError}
                    InputLabelProps={{
                      shrink: true,
                      classes: {
                        root: classes.labelRoot,
                        shrink: classes.shrink
                      }
                    }}
                  />
                </Box>
                <Box
                  sx={{
                    display: 'grid',
                    gridTemplateColumns: { sm: '1fr 1fr 1fr' },
                    gap: 3,
                    paddingBottom: 6
                  }}
                >
                  <TextField
                    id="hp"
                    label="ח.פ"
                    variant="outlined"
                    value={hp}
                    onChange={(event) => {
                      setHpError('');
                      setHp(event.target.value);
                    }}
                    helperText={hpError}
                    error={!!hpError}
                    InputLabelProps={{
                      shrink: true,
                      classes: {
                        root: classes.labelRoot,
                        shrink: classes.shrink
                      }
                    }}
                  />
                  <TextField
                    id="phone"
                    label="נייד"
                    variant="outlined"
                    value={phone}
                    onChange={(event) => {
                      setPhoneError('');
                      setPhone(event.target.value);
                    }}
                    helperText={phoneError}
                    error={!!phoneError}
                    InputLabelProps={{
                      shrink: true,
                      classes: {
                        root: classes.labelRoot,
                        shrink: classes.shrink
                      }
                    }}
                  />
                  <TextField
                    id="email"
                    label="email"
                    type="email"
                    variant="outlined"
                    value={email}
                    onChange={(event) => {
                      setEmailError('');
                      setEmail(event.target.value);
                    }}
                    helperText={emailError}
                    error={!!emailError}
                    onBlur={(event) => {
                      if (
                        /^[a-zA-Z0-9.]+@(?:[a-zA-Z0-9]+\.)+[A-Za-z]+$/.test(
                          event.target.value
                        )
                      ) {
                        setEmail(event.target.value);
                        setEmailError('');
                      } else {
                        setEmailError('פורמט תקין: aaa@aa.com');
                      }
                    }}
                    InputLabelProps={{
                      shrink: true,
                      classes: {
                        root: classes.labelRoot,
                        shrink: classes.shrink
                      }
                    }}
                  />
                </Box>
                <Box
                  sx={{
                    display: 'grid',
                    gridTemplateColumns: { sm: '1fr' },
                    gap: 3
                  }}
                >
                  <TextField
                    id="remarks"
                    label="הערות"
                    variant="outlined"
                    value={remarks}
                    onChange={(event) => {
                      setRemarksError('');
                      setRemarks(event.target.value);
                    }}
                    multiline
                    rows={2}
                    helperText={remarksError}
                    error={!!remarksError}
                    InputLabelProps={{
                      shrink: true,
                      classes: {
                        root: classes.labelRoot,
                        shrink: classes.shrink
                      }
                    }}
                  />
                </Box>
              </DialogContent>
              <p className="errorMsg">{errMsg}</p>
              <DialogActions
                sx={{
                  justifyContent: 'center'
                }}
              >
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleSubmit}
                >
                  הוסף
                </Button>
              </DialogActions>
            </>
          )}
        </Dialog>
      </Box>
    </Box>
  );
};

export default AddProvider;
