import { Doughnut } from 'react-chartjs-2';
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Typography,
  colors,
  useTheme
} from '@material-ui/core';

const TrafficByDevice = ({ dataValue, ...props }) => {
  const theme = useTheme();

  const data = {
    datasets: [
      {
        data: ['מרכז', 'צפון', 'דרום', 'ירושלים והסביבה', '']?.map(
          (key) => dataValue[key]
        ),
        backgroundColor: [
          colors.indigo[500],
          colors.red[600],
          colors.orange[600],
          colors.green[600],
          colors.grey[600]
        ],
        borderWidth: 4,
        borderColor: colors.common.white,
        hoverBorderColor: colors.common.white
      }
    ],
    labels: ['מרכז', 'צפון', 'דרום', 'ירושלים והסביבה', 'אחר']
  };

  const options = {
    animation: false,
    cutoutPercentage: 50,
    layout: { padding: 0 },
    legend: {
      display: false
    },
    maintainAspectRatio: false,
    responsive: true,
    tooltips: {
      backgroundColor: theme.palette.background.paper,
      bodyFontColor: theme.palette.text.secondary,
      borderColor: theme.palette.divider,
      borderWidth: 1,
      enabled: true,
      footerFontColor: theme.palette.text.secondary,
      intersect: false,
      mode: 'index',
      titleFontColor: theme.palette.text.primary
    }
  };

  const devices = [
    {
      title: 'מרכז',
      value: dataValue['מרכז'],
      color: colors.indigo[500]
    },
    {
      title: 'צפון',
      value: dataValue['צפון'],
      color: colors.red[600]
    },
    {
      title: 'דרום',
      value: dataValue['דרום'],
      color: colors.orange[600]
    },
    {
      title: 'ירושלים והסביבה',
      value: dataValue['ירושלים והסביבה'],
      color: colors.green[600]
    },
    {
      title: 'אחר',
      value: dataValue[''],
      color: colors.grey[600]
    }
  ];

  return (
    <Card {...props}>
      <CardHeader title="מספר תחנות לפי אזורים" sx={{ textAlign: 'center' }} />
      <Divider />
      <CardContent>
        <Box
          sx={{
            height: 230,
            position: 'relative'
          }}
        >
          <Doughnut data={data} options={options} />
        </Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center'
            // gridTemplateColumns: { sm: '1fr 1fr 1fr' },
          }}
        >
          {devices.map(({ color, title, value }) => (
            <Box
              key={title}
              sx={{
                p: 2,
                textAlign: 'center'
              }}
            >
              <Typography color="textPrimary" variant="body1">
                {title}
              </Typography>
              <Typography style={{ color }} variant="h6">
                {value}
              </Typography>
            </Box>
          ))}
        </Box>
      </CardContent>
    </Card>
  );
};

export default TrafficByDevice;
